import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import searchCalendar from '../../../../assets/img/icons/searchCalendar.svg';
import { appCustomSelectNew } from '../../../../components/Layouts/react-select-custom';
import { isEmptyOrUndefined } from '../../../../helpers';
import styles from '../styles.module.scss';

export const SearchAppointment = ({
  listServices,
  listLocations,
  listDoctors,
  onChangeSelectOfService,
  onChangeSelectOfLocation,
  onChangeSelectOfSite,
  onChangeSelectOfDoctor,
  onSearchSchedules,
  service,
  location,
  site,
  doctor,
  data,
  order,
}) => {
  const validationSearch =
    !isEmptyOrUndefined(service?.value) && !isEmptyOrUndefined(location?.value);

  const [listSitesReal, setListSitesReal] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [isLocationDisabled, setIsLocationDisabled] = useState(true);

  useEffect(() => {
    if (order.serviceId) {
      setSelectedValue(order.serviceId);
      setIsLocationDisabled(false);
      onChangeSelectOfService({
        serviceName: listServices.find(service => service.srvId === order.serviceId)?.srvName,
        serviceId: order.serviceId,
        sorId: order.sorId,
      });
    } else {
      setIsLocationDisabled(isEmptyOrUndefined(service?.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.serviceId, service?.value]);

  const selectedService = listServices.find(service => service.value === selectedValue) || {
    label: 'Seleccionar...',
    value: '',
  };

  const handleSelectChange = ({ value, label }) => {
    setSelectedValue(value);
    setIsLocationDisabled(isEmptyOrUndefined(value));
    if (value !== order.serviceId) {
      onChangeSelectOfService({ serviceName: label, serviceId: value, sorId: '' });
    } else {
      onChangeSelectOfService({
        serviceName: label,
        serviceId: value,
        sorId: order.sorId,
      });
    }
  };

  return (
    <Box display={'grid'} columnGap='20px' gridTemplateColumns='1fr 1fr 1fr 1fr 40px'>
      <div>
        <Form.Group controlId='servicio'>
          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
            <b className={`${styles.app_title} px-1`}>Servicio</b>
          </Form.Label>

          <Select
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            menuPortalTarget={data?.length === 1 ? document.body : undefined}
            styles={appCustomSelectNew}
            value={selectedService}
            options={[{ label: 'Seleccionar...', value: '' }, ...listServices]}
            onChange={handleSelectChange}
          />
        </Form.Group>
      </div>

      <div>
        <Form.Group controlId='Ubicación'>
          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
            <b className={`${styles.app_title} px-1`}>Ubicación</b>
          </Form.Label>
          <Select
            value={location?.label ? location : ''}
            isDisabled={isLocationDisabled}
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            menuPortalTarget={data?.length === 1 ? document.body : undefined}
            styles={appCustomSelectNew}
            options={[{ label: 'Seleccionar...', value: '' }, ...listLocations]}
            onChange={({ value, label }) => {
              const selectedLocation = listLocations?.find(x => x.value === value);
              if (selectedLocation) {
                const newTemp = selectedLocation.sites;
                const newSites = newTemp?.map(site => ({ label: site.siteName, value: site.id }));
                newSites.unshift({ label: 'Seleccionar...', value: '' });
                setListSitesReal(newSites);
              }
              onChangeSelectOfLocation({ locationId: value, locationName: label });
            }}
          />
        </Form.Group>
      </div>

      <div>
        <Form.Group controlId='Sede'>
          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
            <b className={`${styles.app_title} px-1`}>Sede</b>
          </Form.Label>
          <Select
            value={site?.label ? site : ''}
            isDisabled={isEmptyOrUndefined(location?.value)}
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            menuPortalTarget={data?.length === 1 ? document.body : undefined}
            styles={appCustomSelectNew}
            options={listSitesReal}
            onChange={({ value, label }) => {
              onChangeSelectOfSite({ siteId: value, siteName: label });
            }}
          />
        </Form.Group>
      </div>

      <div>
        <Form.Group controlId='medicoSearch'>
          <Form.Label className={`m-0 ${styles.ordDarkBlueText}`}>
            <b className={`${styles.app_title} px-1`}>Médico</b>
          </Form.Label>
          <Select
            value={doctor?.label ? doctor : ''}
            isDisabled={isEmptyOrUndefined(site?.value)}
            noOptionsMessage={() => 'No hay datos'}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            menuPortalTarget={data?.length === 1 ? document.body : undefined}
            styles={appCustomSelectNew}
            options={listDoctors}
            onChange={({ value, label }) => {
              onChangeSelectOfDoctor({ doctorId: value, doctorName: label });
            }}
          />
        </Form.Group>
      </div>

      <div
        className={`col-1 d-flex justify-content-center align-items-center p-0 w-100 ${validationSearch ? 'styleSearchCite' : 'styleSearchCiteInactive'}`}
        onClick={() => {
          if (validationSearch) {
            onSearchSchedules();
          }
        }}
      >
        <img src={searchCalendar} alt='search-icon' />
      </div>
    </Box>
  );
};
