import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Select from 'react-select';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import closeBlue from '../../assets/img/icons/close-blue.svg';
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';
import orderIcon from '../../assets/img/icons/orderIcon.svg';
import reemplazar from '../../assets/img/icons/reemplazar.svg';
import threeDots from '../../assets/img/icons/threeDots.svg';
import styles from '../../components/Layouts/tableStyle.module.scss';
import CustomPopupExtend from '../../components/Popup/customPopUpExtends';
import CustomPopupStyles from '../../components/Popup/customPopup.module.scss';
import { formatNumberWithoutDecimals } from '../../helpers/numberFormatting';
import { formatteDocument, isEmptyOrUndefined, loader } from '../../helpers';
import { ordCustomSelect, tlnCustomSelect } from './../../components/Layouts/react-select-custom';

// request
import { useGetMethod, usePostMethod } from './../../Hooks/useFetch';

// components
import { Box } from '@mui/material';
import { useFormatData } from '../../Hooks';
import { ServiceOrderReplace } from './';
import { customSwaltAlertAsistencial } from './../../helpers/customSwaltAlertAsistencial';
import { RenderRow } from './components/';

const ServicesOrders = () => {
  // states
  const history = useHistory();
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const idUser = store.loginReducer.user_data.id;
  const idAccount = store.loginReducer.currentAccount.id;
  const isCoordAdmin = store?.loginReducer?.currentAccount?.profile?.prefijo === 'COORADMISIONIST';
  const site = store.userReducer.site;
  const todayDate = moment().format('YYYY-MM-DD');

  const [dataPostCancellations, setDataPostCancellations] = useState({
    userId: idUser,
    appStatus: 'pending_cancelled',
    appId: undefined,
    cancelId: undefined,
    cancelObservation: undefined,
  });

  const [, setDataPostReplace] = useState({
    userId: idUser,
    appStatus: 'replaced',
    replacementData: {
      approveReplacement: 1,
      currentAppId: undefined,
      newAppId: undefined,
    },
  });

  const [listPatientFilter, setListPatientFilter] = useState({
    eaccount: idAccount,
    date: undefined,
    admitted: 1,
    site,
    page: 1,
    perpage: 10,
    createdBy: idUser,
    cluId: undefined,
  });

  const [triggerListPatientFilter, setTriggerListPatientFilter] = useState(0);

  const [data, setData] = useState({
    trigger: 0,
    filters: {
      pendingAmountStatus: '',
      status: '',
      idUser,
      idAccount,
      isCoordinator: isCoordAdmin === true ? 1 : 0,
      isAdmisionist: isCoordAdmin === false ? 1 : 0,
      initialDate: todayDate,
      finalDate: todayDate,
      search: '',
      page: 1,
      perpage: 10,
    },
    amount: '',
    company: {
      id: '',
      name: '',
      nit: '',
    },
    contract: {
      id: '',
      name: '',
      sequence: '',
    },
    date: '',
    donation: 0,
    hour: '',
    id: '',
    patient: {
      docType: '',
      document: '',
      id: '',
      name: '',
    },
    pending_amount: '',
    service: {
      description: '',
      id: '',
      name: '',
    },
    status: {
      background: '',
      fontColor: '',
      id: '',
      name: '',
    },
    modalAmount: {
      donation: 0,
      amountToDeliver: undefined,
      donationToDeliver: '',
    },
  });

  const { filters, trigger, modalAmount } = data;

  const [search, setSearch] = useState('');

  const [citeSelected, setCiteSelected] = useState();
  const [modalOnCancellation, setModalOnCancellation] = useState(false);

  // state modal replace
  const [modalOrderReplace, setModalOrderReplace] = useState(false);
  const [replaceSection, setReplaceSection] = useState(1);

  //request

  const {
    load: loadListServiceOrder,
    results: listServiceOrder,
    trigger: getListServiceOrder,
  } = useGetMethod();

  const {
    results: listCancellationReason,
    load: loaderListCancellationReason,
    trigger: getListCancellationReason,
  } = useGetMethod();

  const {
    results: listPatientAdmission,
    load: loaderListPatientAdmission,
    trigger: getListPatientAdmission,
  } = useGetMethod();

  const {
    load: loadServiceOrderStatus,
    results: serviceOrderStatus,
    trigger: getServiceOrderStatus,
  } = useGetMethod();

  const { load: loadPostModalAmount } = usePostMethod();

  const { load: loaderPostReplace, trigger: postReplace } = usePostMethod();

  const { load: loaderPostCancellations, trigger: postCancellations } = usePostMethod();

  //change state modal replace
  const onChangeStateModalOrderReplace = state => setModalOrderReplace(state);
  const onChangeTrigger = () => setData(state => ({ ...state, trigger: state.trigger + 1 }));

  const clearState = () => {
    setReplaceSection(1);
    setCiteSelected(undefined);
    setModalOrderReplace(false);

    setListPatientFilter(state => ({
      ...state,
      page: 1,
    }));
  };

  const onClearState = isIncrementTrigger => {
    setListPatientFilter(state => ({
      ...state,
      page: 1,
    }));

    setData(state => ({
      ...state,
      trigger: state.trigger + isIncrementTrigger ? 1 : 0,

      filters: {
        status: '',
        idUser: idUser,
        idAccount: idAccount,
        isCoordinator: isCoordAdmin === true ? 1 : 0,
        isAdmisionist: isCoordAdmin === false ? 1 : 0,
        initialDate: '',
        finalDate: '',
        search: '',
        page: 1,
        perpage: 10,
      },
      amount: '',
      company: {
        id: '',
        name: '',
        nit: '',
      },
      contract: {
        id: '',
        name: '',
        sequence: '',
      },
      date: '',
      donation: '',
      hour: '',
      id: '',
      patient: {
        docType: '',
        document: '',
        id: '',
        name: '',
      },
      pendingAmountStatus: '',
      pending_amount: '',
      service: {
        description: '',
        id: '',
        name: '',
      },
      status: {
        background: '',
        fontColor: '',
        id: '',
        name: '',
      },
      modalAmount: {
        donation: 0,
        amountToDeliver: 0,
      },
    }));
  };

  const renderTooltip = props => (
    <Tooltip {...props}>
      <div className=' '>
        <span>Servicios</span>
      </div>
    </Tooltip>
  );

  const headers = [
    {
      title: 'No. Orden',
      className: `px-2 text-center`,
    },
    {
      title: 'Fecha',
      className: `px-2 text-center`,
    },
    {
      title: 'Paciente',
      className: `px-2 text-start col-5`,
    },
    {
      title: 'Empresa',
      className: `px-2 text-start col-2`,
    },
    {
      title: `Contrato`,
      className: `px-2 col-1 text-start col-2`,
    },
    {
      title: `Servicio`,
      className: `px-2 text-start col-3`,
    },
    {
      title: `Admisionista`,
      className: `px-2 text-start`,
    },
    {
      title: 'Total',
      className: `px-2 text-end col-1`,
    },
    {
      title: 'Estado',
      className: `px-2 text-center`,
    },
    {
      className: ' text-center',
    },
  ];

  const formatData = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(item => {
        const status = ['pending_cancelled', 'pending_replaced', 'cancelled', 'auth_cancelled'];
        const showReplaceOrCanceled = status.includes(item?.status?.value);

        tempList.push(
          <tr key={item?.id} className={`hover-table-row `}>
            <td className={`text-center px-2`}>
              <div style={{ width: '65px' }}>{item?.orderNo || '-'}</div>
            </td>

            <td className={`text-center px-2`}>
              <div style={{ width: '80px' }}>
                <span>{item?.date || '-'}</span>
                <br />
                <span>{item?.hour || '-'}</span>
              </div>
            </td>

            <td className={`text-start px-2 `}>
              <span>{item?.patient?.name || '-'}</span>
              <br />
              <span style={{ color: '#A2A3AB' }}>
                {`${item?.patient?.docType || '-'} ${formatteDocument(item?.patient?.document).format || '-'}`}
              </span>
            </td>

            <td className={`text-start px-2`}>{item?.company?.name || '-'}</td>

            <td className={`text-start px-2`}>{item?.contract?.name || '-'}</td>

            <td className={`text-start px-2`}>{item?.service?.name || '-'}</td>

            <td className={`text-start px-2`}>
              {item?.admissionist?.firstName + ' ' + item?.admissionist?.lastName || '-'}
            </td>

            <td className={`text-end px-2`}>
              <div style={{ width: '78px' }}>
                {item?.service?.price
                  ? formatNumberWithoutDecimals(item?.service?.price, item?.currency?.shortName)
                  : formatNumberWithoutDecimals(0, item?.currency?.shortName)}
              </div>
            </td>

            <td className={`text-center`}>
              <div
                style={{
                  color: `${item?.status?.fontColor}`,
                  backgroundColor: `${item?.status?.background}`,
                  borderRadius: '10px',
                  padding: '4px 10px',
                  width: '105px',
                }}
              >
                {item?.status?.name || '-'}
              </div>
            </td>

            <td className={`text-start`}>
              <CustomPopupExtend
                position='right center'
                className={`align-self-end`}
                triggerClass={`${styles.hoverPopup} `}
                triggerSrc={threeDots}
                extraButtons={[
                  {
                    text: 'Ver detalle',
                    class: CustomPopupStyles.popUpGeneric,
                    event: () => {
                      history.push({
                        pathname: `ordenesServicios/${item?.id}`,
                        state: { ...item },
                      });
                    },
                  },

                  !showReplaceOrCanceled
                    ? {
                        text: 'Anular',
                        class: CustomPopupStyles.popUpGeneric,
                        event: () => {
                          setModalOnCancellation(true);
                          setData(state => ({
                            ...state,
                            ...item,
                          }));
                        },
                      }
                    : undefined,
                  !showReplaceOrCanceled
                    ? {
                        text: 'Reemplazar',
                        class: CustomPopupStyles.popUpGeneric,
                        event: () => {
                          onChangeStateModalOrderReplace(true);
                          setData(state => ({
                            ...state,
                            ...item,
                          }));
                        },
                      }
                    : undefined,
                ]}
              ></CustomPopupExtend>
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  //  ------------ SECTION MODAL CHANGE --------------------

  const [modalChange] = useState({
    show: false,
    check: 0,
  });

  const printDataInsuranceCancellation = () => {
    return (
      <>
        <RenderRow title={'Aseguradora'} subTitle={data?.company?.name} />
        <RenderRow title={'Contrato'} subTitle={data?.contract?.name} />
        <RenderRow title={'Grupo de ingreso'} subTitle={data?.service?.incomeGroup} />
        <RenderRow title={'Origen'} subTitle={data?.service?.population} />
      </>
    );
  };

  const printDataCiteCancellation = () => {
    return (
      <>
        <RenderRow title={'Sede'} subTitle={data?.service?.siteName} />
        <RenderRow title={'Médico'} subTitle={data?.service?.doctor} />
        <RenderRow title={'Servicio'} subTitle={data?.service?.name} />
        <RenderRow title={'Resolución de facturación'} subTitle={data?.resolutionType} />
        <RenderRow title={'Fecha'} subTitle={data?.date} />
        <RenderRow title={'Hora'} subTitle={data?.hour} />
        <RenderRow
          title={'No. de historia clínica'}
          subTitle={data?.service?.medHistory || 'N/A'}
        />
        <RenderRow
          title={'Diagnóstico'}
          subTitleList={data?.service?.diagnostics}
          subTitle={data?.service?.diagnostics?.length ? '' : '-'}
        />
        <RenderRow
          title={'Total'}
          subTitle={data?.amount ? formatNumberWithoutDecimals(data?.amount) : '-'}
        />
      </>
    );
  };

  // TABLE
  const tableHeader = [
    {
      title: '',
      className: 'px-2',
    },
    {
      title: 'Hora',
      className: 'px-2 text-center',
    },
    {
      title: 'Servicio',
      className: 'text-start px-2 col-4',
    },
    {
      title: 'Total',
      className: ' text-end px-2',
    },
  ];

  const tableBodyStructure = cite => [
    {
      text: (
        <div className={`${styles.formCheckInputRadioAsistencial} ${styles.radioClass}`}>
          <input
            style={{ width: '16px', height: '16px' }}
            type='radio'
            name='citeReplace'
            className={`pointer`}
            onChange={() => {
              setCiteSelected(cite);
            }}
          />
        </div>
      ),
      className: `px-2 ${cite?.id === citeSelected?.id ? styles.bgTabOrderReplaceRowSelected : ''}`,
    },
    {
      text: <>{moment(cite?.hour, 'h:mm').format('h:mm a') || '-'}</>,
      className: `${styles.f12} text-center px-2 ${cite?.id === citeSelected?.id ? styles.bgTabOrderReplaceRowSelected : ''
        }`,
    },
    {
      text: <>{cite?.service?.name || '-'}</>,
      className: `${styles.f12} text-start px-2 col-6 ${cite?.id === citeSelected?.id ? styles.bgTabOrderReplaceRowSelected : ''
        }`,
    },
    {
      text: <>{cite?.service?.amount ? formatNumberWithoutDecimals(cite?.service?.amount) : '-'}</>,
      className: `${styles.f12} text-end px-2 ${cite?.id === citeSelected?.id ? styles.bgTabOrderReplaceRowSelected : ''
        }`,
    },
  ];

  const { formatData: formatDataReplace } = useFormatData({
    tableBodyStructure,
    listTableBody: listPatientAdmission?.results?.filter(el => el?.id !== data?.id) || [],
    changeRender: [citeSelected?.id, listPatientAdmission?.results],
  });

  const serviceOrderStatusList = serviceOrderStatus?.data?.length
    ? serviceOrderStatus.data
      .map((status) => ({ value: status.id, label: status.description, tag: status.value }))
    : []

  serviceOrderStatusList.unshift({ value: "", label: "Seleccionar...", tag: "" });

  const handleCancellations = ({ id, patientId }) => {
    postCancellations({
      url: '/medical/admissions/changeServiceOrderStatus/',
      token: token,
      method: 'POST',
      body: {
        cancelId: dataPostCancellations?.cancelId,
        cancelObservation: dataPostCancellations?.cancelObservation,
        appStatus: 'cancelled',
        patientId,
        eaccount: idAccount,
        userId: idUser,
        cancelationData: {
          appId: id,
          approveCancelation: 1,
        },
      },
      succesAction: () => {
        customSwaltAlertAsistencial({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: `Se ha anulado la orden ${data?.id}`,
          showCancelButton: false,
        });
        setModalOnCancellation(false);
        onChangeTrigger();
        onClearState();
        setDataPostCancellations({
          userId: idUser,
          appStatus: 'pending_cancelled',
          appId: undefined,
          cancelId: undefined,
          cancelObservation: undefined,
        });
      },
      doAfterException: error => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: error?.message,
          showCancelButton: false,
        });
      },
    });
  };

  const handleCancellationsWitchAuthorization = () => {
    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se anulará la orden ${data?.id}`,
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        if (data?.needsAuthorization === 0) {
          handleCancellations({ id: data?.id, patientId: data?.patient?.id });
        } else {
          postCancellations({
            url: '/medical/admissions/changeServiceOrderStatus/',
            token: token,
            method: 'PUT',
            body: {
              ...dataPostCancellations,
              appId: data?.id,
              patientId: data?.patient?.id,
              userId: idUser,
            },
            succesAction: () => {
              customSwaltAlertAsistencial({
                icon: 'success',
                title: 'Actualizado exitosamente',
                text: `Se ha anulado la orden ${data?.id}`,
                showCancelButton: false,
              }).finally(() => {
                setModalOnCancellation(false);
                onChangeTrigger();
                onClearState();
                setDataPostCancellations({
                  userId: idUser,
                  appStatus: 'pending_cancelled',
                  appId: undefined,
                  cancelId: undefined,
                  cancelObservation: undefined,
                });
              });
            },
            doAfterException: error => {
              customSwaltAlertAsistencial({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: error?.message,
                showCancelButton: false,
              });
            },
          });
        }
      }
    });
  };

  const handleReplace = () => {
    postReplace({
      url: '/medical/admissions/changeServiceOrderStatus/',
      token: token,
      method: 'POST',
      body: {
        cancelId: dataPostCancellations?.cancelId,
        cancelObservation: dataPostCancellations?.cancelObservation,
        patientId: data?.patient?.id,
        userId: idUser,
        appStatus: 'replaced',
        replacementData: {
          approveReplacement: 1,
          currentAppId: data?.id,
          newAppId: citeSelected?.id,
        },
      },

      succesAction: () => {
        onChangeStateModalOrderReplace(false);
        clearState();
        setModalOnCancellation(false);
        onChangeTrigger();
        onClearState();
        setDataPostReplace({
          userId: idUser,
          appStatus: 'replaced',
        });

        customSwaltAlertAsistencial({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: `Se ha anulado la orden ${data?.id} del paciente: ${data?.patient?.name}`,
          showCancelButton: false,
        });
      },
      doAfterException: error => {
        customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: error?.message,
          showCancelButton: false,
        });
      },
    });
  };

  const handleReplaceWitchAuthorization = () => {
    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se remplazará la cita ${data?.id} asignada al paciente: ${data?.patient?.name}`,
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        if (data?.needsAuthorization === 1 || data?.amount > citeSelected?.service?.amount) {
          // 'PUT'

          return postReplace({
            url: '/medical/admissions/changeServiceOrderStatus/',
            token: token,
            method: 'PUT',
            body: {
              ...dataPostCancellations,
              appId: data?.id,
              appStatus: 'pending_replaced',
              patientId: data?.patient?.id,
              userId: idUser,
              newAppId: citeSelected?.id,
            },

            succesAction: () => {
              onChangeStateModalOrderReplace(false);
              clearState();
              setModalOnCancellation(false);
              onChangeTrigger();
              onClearState();
              setDataPostReplace({
                userId: idUser,
                appStatus: 'replaced',
              });

              customSwaltAlertAsistencial({
                icon: 'success',
                title: 'Actualizado exitosamente',
                text: `Se ha anulado la orden ${data?.id} del paciente: ${data?.patient?.name}`,
                showCancelButton: false,
              });
            },
            doAfterException: error => {
              customSwaltAlertAsistencial({
                icon: 'warning',
                title: 'Intenta de nuevo',
                text: error?.message,
                showCancelButton: false,
              });
            },
          });
        }

        return handleReplace();
      }
    });
  };

  useEffect(() => {
    getListServiceOrder({
      objFilters: { ...filters },
      token: token,
      url: '/medical/service-orders',
    });
  }, [trigger, getListServiceOrder, filters, token]);

  useEffect(() => {
    setData(state => ({
      ...state,
      filters: {
        ...state.filters,
        initialDate: state.filters.initialDate || todayDate,
        finalDate: state.filters.finalDate || todayDate,
      },
    }));
  }, [todayDate]);

  useEffect(() => {
    getServiceOrderStatus({
      objFilters: { statusGroup: "service_order_lifecycle" },
      token: token,
      url: '/surgery/consult/detail',
    });
  }, [trigger, getServiceOrderStatus, token]);

  useEffect(() => {
    if (modalOrderReplace && !isEmptyOrUndefined(data?.patient?.id)) {
      getListPatientAdmission({
        url: '/medical/patientAdmission/',
        objFilters: {
          ...listPatientFilter,
          cluId: data?.patient?.id,
          date: moment(data?.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        },
        token: token,
      });
    }
  }, [
    modalOrderReplace,
    triggerListPatientFilter,
    data.patient.id,
    data.date,
    getListPatientAdmission,
    listPatientFilter,
    token,
  ]);

  useEffect(() => {
    if (modalAmount?.amountToDeliver <= 0) {
      setData(state => ({
        ...state,
        modalAmount: {
          ...state.modalAmount,
          donation: 0,
        },
      }));
    }
  }, [modalAmount?.amountToDeliver]);

  useEffect(() => {
    setData(state => ({
      ...state,
      modalAmount: {
        ...state.modalAmount,
        donation: 0,
      },
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalChange?.check]);

  useEffect(() => {
    getListCancellationReason({
      url: '/medical/generals/cancellationReason/',
      token: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //  ------------- !SECTION MODAL CHANGE --------------------
  return (
    <>
      {(loadListServiceOrder ||
        loadPostModalAmount ||
        loaderPostCancellations ||
        loaderPostReplace ||
        loaderListPatientAdmission ||
        loadServiceOrderStatus ||
        loaderListCancellationReason) &&
        loader}

      {/* action replace service order */}
      <ServiceOrderReplace
        data={[]}
        title={`${data?.needsAuthorization === 0 ? 'Reemplazo de orden de servicio' : 'Solicitud reemplazo de orden de servicio'}`}
        subTitle={`${data?.patient?.docType} ${formatteDocument(data?.patient?.document).format} - ${data?.patient?.name}`}
        show={modalOrderReplace}
        size={940}
        onHide={() => {
          onChangeStateModalOrderReplace(false);
          clearState();
        }}
        dataInsurance={{
          data: printDataInsuranceCancellation,
          title: 'Cita original',
          subTitle: 'Datos de aseguradora',
        }}
        dataCite={{
          data: printDataCiteCancellation,
          subTitle: 'Datos de la cita',
        }}
      >
        {replaceSection === 1 && (
          <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box mb={3} className={`${styles.ordAquaMarineText} fw-bold`}>
              Cita de reemplazo
            </Box>

            <OrdTable
              hoverYellow
              lastBorderNone
              body={formatDataReplace}
              headers={tableHeader}
              className='mt-0  w-100'
              paginate={{
                activePage: listPatientFilter?.page,
                totalPages: listPatientAdmission?.results?.filter(el => el?.id === data?.id)?.length
                  ? listPatientAdmission?.rowTotal - 1
                  : listPatientAdmission?.rowTotal,
                perPage: listPatientFilter?.perpage,
                pageRangeDisplayed: 3,
                onChangePage: async e => {
                  setListPatientFilter(state => ({
                    ...state,
                    page: e,
                  }));

                  setTriggerListPatientFilter(state => state + 1);
                },
                showTextDetails: true,
              }}
            />

            <Box
              mb={3}
              display={'grid'}
              gridTemplateColumns={'1fr 3fr'}
              columnGap={2}
              style={{ marginTop: 'auto' }}
            >
              <button
                className={`${styles.btnReplaceCancel}`}
                onClick={() => {
                  onChangeStateModalOrderReplace(false);
                  clearState();
                }}
              >
                Cancelar
              </button>

              <button
                disabled={citeSelected === undefined}
                className={`${styles.btnReplace} ${citeSelected === undefined ? '' : styles.btnReplaceHover
                  }`}
                onClick={() => {
                  setReplaceSection(2);
                }}
              >
                Reemplazar
                <img className='px-2' src={reemplazar} alt='reemplazar' />
              </button>
            </Box>
          </Box>
        )}

        {replaceSection === 2 && (
          <Box>
            <Box>
              <Box>
                <Box className={`m-0 ${styles.darkerBlueText} fw-bold`}>
                  &nbsp;Motivo
                  <span className={`${styles.ordOrangeText}`}>*</span>
                </Box>

                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={listCancellationReason?.results?.map(el => ({
                    label: el?.canDescription,
                    value: el?.canId,
                  }))}
                  className='text-secondary '
                  placeholder={'Seleccionar...'}
                  styles={ordCustomSelect}
                  onChange={({ value }) => {
                    setDataPostCancellations(state => ({
                      ...state,
                      cancelId: value,
                    }));
                  }}
                />
              </Box>

              <Box my={2}>
                <Box className={`m-0 ${styles.darkerBlueText} fw-bold`}>
                  &nbsp;Observaciones
                  <span className={`${styles.ordOrangeText}`}>*</span>
                </Box>

                <textarea
                  placeholder='Escribir...'
                  style={{ minHeight: '265px', maxHeight: '265px' }}
                  className={`text-secondary ordInputAdmission w-100 p-2`}
                  onChange={({ target }) => {
                    setDataPostCancellations(state => ({
                      ...state,
                      cancelObservation: target?.value,
                    }));
                  }}
                />
              </Box>
            </Box>

            <Box display={'grid'} gridTemplateColumns={'1fr 3fr'} columnGap={2} mb={3}>
              <button
                className={`${styles.btnReplaceCancel}`}
                onClick={() => {
                  onChangeStateModalOrderReplace(false);
                  clearState();
                }}
              >
                Cancelar
              </button>

              <button
                disabled={
                  citeSelected === undefined ||
                  !dataPostCancellations?.cancelObservation?.trim() ||
                  isEmptyOrUndefined(dataPostCancellations?.cancelId)
                }
                className={`${styles.btnReplace} ${citeSelected === undefined ? '' : styles.btnReplaceHover
                  }`}
                onClick={handleReplaceWitchAuthorization}
              >
                Reemplazar
                <img className='px-2' src={reemplazar} alt='reemplazar' />
              </button>
            </Box>
          </Box>
        )}
      </ServiceOrderReplace>

      <ServiceOrderReplace
        title={!data?.needsAuthorization ? 'Anulación' : 'Solicitud de anulación'}
        subTitle={`${data?.patient?.docType} ${formatteDocument(data?.patient?.document).format} - ${data?.patient?.name}`}
        show={modalOnCancellation}
        size={940}
        onHide={() => {
          setModalOnCancellation(false);
          clearState();
          setDataPostCancellations({
            userId: idUser,
            appStatus: 'pending_cancelled',
            appId: undefined,
            cancelId: undefined,
            cancelObservation: undefined,
          });
        }}
        btnNoEvent={() => {
          setModalOnCancellation(false);
          clearState();
          setDataPostCancellations({
            userId: idUser,
            appStatus: 'pending_cancelled',
            appId: undefined,
            cancelId: undefined,
            cancelObservation: undefined,
          });
        }}
        dataInsurance={{
          data: printDataInsuranceCancellation,
          subTitle: 'Datos de aseguradora',
        }}
        dataCite={{
          data: printDataCiteCancellation,
          subTitle: 'Datos de la cita',
        }}
      >
        <Box>
          <Box>
            <Box>
              <Box className={`m-0 ${styles.darkerBlueText} fw-bold`}>
                &nbsp;Motivo
                <span className={`${styles.ordOrangeText}`}>*</span>
              </Box>

              <Select
                noOptionsMessage={() => 'No hay datos'}
                options={listCancellationReason?.results?.map(el => ({
                  label: el?.canDescription,
                  value: el?.canId,
                }))}
                className='text-secondary '
                placeholder={'Seleccionar...'}
                styles={ordCustomSelect}
                onChange={({ value }) => {
                  setDataPostCancellations(state => ({
                    ...state,
                    cancelId: value,
                  }));
                }}
              />
            </Box>

            <Box my={2}>
              <Box className={`m-0 ${styles.darkerBlueText} fw-bold`}>
                &nbsp;Observaciones
                <span className={`${styles.ordOrangeText}`}>*</span>
              </Box>

              <textarea
                placeholder='Escribir...'
                style={{ minHeight: '265px', maxHeight: '265px' }}
                className={`text-secondary ordInputAdmission w-100 p-2`}
                onChange={({ target }) => {
                  setDataPostCancellations(state => ({
                    ...state,
                    cancelObservation: target?.value,
                  }));
                }}
              />
            </Box>
          </Box>

          <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} columnGap={3} mb={3}>
            <button
              className={`${styles.btnReplaceCancel}`}
              onClick={() => {
                setModalOnCancellation(false);
                clearState();
                setDataPostCancellations({
                  userId: idUser,
                  appStatus: 'pending_cancelled',
                  appId: undefined,
                  cancelId: undefined,
                  cancelObservation: undefined,
                });
              }}
            >
              Cancelar
            </button>

            {(data?.resolutionTypeTag ?? '-') !== 'fcfiscal' && (
              <button
                disabled={
                  !dataPostCancellations?.cancelObservation?.trim() ||
                  isEmptyOrUndefined(dataPostCancellations?.cancelId)
                }
                className={`${styles.btnReplace} ${styles.btnReplaceHover}`}
                onClick={handleCancellationsWitchAuthorization}
              >
                Anular
                <img className='px-2' height={12} src={closeBlue} alt='close-blue' />
              </button>
            )}
          </Box>
        </Box>
      </ServiceOrderReplace>

      <OrdGenericTemplate
        className='w-95 my-4'
        showBottomBorder={false}
        title={'Órdenes de servicios'}
        titleSize={12}
        titleIcon={{
          icon: (
            <>
              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <img
                  alt='goal'
                  src={orderIcon}
                  height='30px'
                  width='30px'
                  className='align-self-center mx-2'
                />
              </OverlayTrigger>
            </>
          ),
        }}
      >
        <div className='me-4 my-2 asistencialDateColorIcon'>
          <Row className='d-flex px-1'>
            <Col xs={2} className={`px-0 align-self-end`}>
              <Form.Group className='px-2' controlId='ordType'>
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                  <span>&nbsp;Fecha desde</span>
                </Form.Label>
                <input
                  className={`ord-roundInput2`}
                  style={data.filters.initialDate ? { color: '#1a538d' } : {}}
                  type='date'
                  id='date-start'
                  value={data.filters.initialDate}
                  onChange={e => {
                    setData(state => ({
                      ...state,
                      filters: { ...state.filters, initialDate: e.target.value },
                    }));
                  }}
                  max={data.filters.finalDate || todayDate}
                />
              </Form.Group>
            </Col>

            <Col xs={2} className={`px-0 align-self-end`}>
              <Form.Group className=' px-2' controlId='ordType'>
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                  <span>&nbsp;Fecha hasta</span>
                </Form.Label>
                <input
                  className={`ord-roundInput2`}
                  style={data.filters.initialDate ? { color: '#1a538d' } : {}}
                  id='date-end'
                  type='date'
                  value={data.filters.finalDate}
                  min={data.filters.initialDate}
                  max={todayDate}
                  onChange={e => {
                    setData(state => ({
                      ...state,
                      filters: { ...state.filters, finalDate: e.target.value },
                    }));
                  }}
                />
              </Form.Group>
            </Col>

            <Col xs={2} className={`px-0 align-self-end`}>
              <Form.Group className=' px-2' controlId='ordType'>
                <Form.Label className={`m-0 ${styles.ordDarkBlueText} fw-bold`}>
                  <span>&nbsp;Estado</span>
                </Form.Label>
                <Select
                  noOptionsMessage={() => 'No hay datos'}
                  options={serviceOrderStatusList}
                  className='text-secondary'
                  placeholder={'Seleccione'}
                  styles={tlnCustomSelect}
                  onChange={(target) => {
                    setData(state => ({ ...state, filters: { ...state.filters, status: target?.tag } }))
                  }
                  }
                />
              </Form.Group>
            </Col>

            <Col xs={4} className='align-self-end'>
              <Form.Group className=' text-start' controlId='fName'>
                <div className={`d-flex ${styles.appGrayInputSearch} ${styles.f12} align-self-end`}>
                  <input
                    className={`ord-roundInput ${styles.appInputDate} w-100`}
                    type='text'
                    placeholder='Escribe aquí para buscar'
                    autoComplete='off'
                    onChange={e => {
                      setSearch(e.target.value)
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        onChangeTrigger();
                        setData(state => ({
                          ...state,
                          filters: { ...state.filters, page: 1, search: search },
                        }));
                      }
                    }}
                  />
                  <img
                    src={ordBlueSearch}
                    className='pointer'
                    alt='buscar'
                    onClick={() => {
                      onChangeTrigger();
                      setData(state => ({
                        ...state,
                        filters: {
                          ...state.filters,
                          page: 1,
                        },
                      }));
                    }}
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>

          {/* SECTION TABLE */}
          <Row>
            <div>
              <OrdTable
                shadow
                headers={headers}
                hasChildren={true}
                oneHeadDarkBlue={false}
                lowerCase={false}
                paginate={{
                  activePage: filters.page,
                  totalPages: listServiceOrder?.rowTotal,
                  perPage: filters.perpage,
                  pageRangeDisplayed: 3,
                  showTextDetails: true,
                  onChangePage: async e => {
                    setData(state => ({
                      ...state,
                      filters: { ...state?.filters, page: e },
                      trigger: trigger + 1,
                    }));
                  },
                }}
              >
                {formatData(listServiceOrder?.results || [])}
              </OrdTable>
            </div>
          </Row>
          {/* !SECTION */}
        </div>
      </OrdGenericTemplate>
    </>
  );
};

export default ServicesOrders;
