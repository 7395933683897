// react && react select && react router dom
import { useHistory, useLocation } from 'react-router';
import Select from 'react-select';

// material ui, styles
import { Box } from '@mui/material';
import styles from '../../components/Layouts/tableStyle.module.scss';

// icons
import backIcon from '../../assets/img/icons/iconBack.svg';

// Componentes
import {
  customSelectAdmin,
  customSelectMultiBig,
  customSelectMultiHorizontal,
} from './../../components/Layouts/react-select-custom';

// import GenericTable from "../../components/Layouts/GenericTableNew";
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { loader } from '../../helpers';
import { useGetMethod, usePostMethod } from '../../Hooks';
import { BoxInputSelectText } from './../../components/UI/molecules/';
import { customSwaltAlert } from './../../helpers/customSwaltAlert';

export const SettingSupportDetails = () => {
  // states
  const history = useHistory();
  const { state: locationState } = useLocation();
  const { action: locationAction, data } = locationState || null;
  const store = useSelector(state => state);
  const userId = store.loginReducer.user_data.id;
  const token = store.loginReducer.Authorization;
  // const eaccount = 13
  const eaccount = store.loginReducer.currentAccount.id;
  const createdBy = store.loginReducer.user_data.id;
  const [dataSetting, setDataSetting] = useState({
    id: data?.id,
    company: data?.company,
    advisorTime: data?.advisorTime,
    contract: data?.contract,
    documents: data?.documents,
    group: data?.group,
    invoicing: data?.invoicing,
    invoicingTime: data?.invoicingTime,
    isGroupal: data?.isGroupal,
    isPhysical: data?.isPhysical,
    isVirtual: data?.isVirtual,
    modality: data?.modality,
    services: data?.services,
    dataFormat: {
      companyClient: {
        companyClientValue: {},
        companyClientOptions: [],
        contractValue: {},
        contractOptions: [],
        nitValue: {},
        nitOptions: [],
        servicesValue: {},
        servicesOptions: [],
      },
      billing: {
        invoicingTime: {
          name: '',
          id: '',
          quantity: '',
        },
        withContract: {
          options: [],
          value: {},
        },
        invoicing_docs: [],
        isGroupal: {},
        withGroupal: {},
      },
      adviser: {
        advisorTime: {
          name: '',
          id: '',
          quantity: '',
        },
        admisionist_docs: {},
      },
    },
  });

  const {
    company,
    advisorTime,
    contract,
    documents,
    group,
    invoicingTime,
    isGroupal,
    isPhysical,
    isVirtual,
    dataFormat: { companyClient, billing, adviser },
  } = dataSetting;

  const withContractOptions = [
    { label: 'Físico', value: 'isPhysical' },
    { label: 'Virtual', value: 'isVirtual' },
  ];
  const modalityBilling = [
    { label: 'Grupal', value: 'isGroup' },
    { label: 'Individual', value: 'isIndividual' },
  ];

  const { results: listCompany, load: loadListCompany, trigger: getListCompany } = useGetMethod();

  const {
    results: listDocuments,
    load: loadListDocuments,
    trigger: getListDocuments,
  } = useGetMethod();

  const {
    results: listConfigSupportGroup,
    load: loadListConfigSupportGroup,
    trigger: getListConfigSupportGroup,
  } = useGetMethod();

  const {
    results: listServices,
    load: loadListServices,
    trigger: getListServices,
  } = useGetMethod();

  const {
    results: listContract,
    load: loadListContract,
    trigger: getListContract,
  } = useGetMethod();

  const { results: listTimes, load: loadListTimes, trigger: getListTimes } = useGetMethod();

  const { load: loadPostSettingSupport, trigger: postSettingSupport } = usePostMethod();

  const createSetting = () => {
    postSettingSupport({
      url: `/medical/configSupport/`,
      token: token,
      noAlert: true,
      method: 'POST',
      body: {
        eaccount,
        createdBy,
        userId,
        id: dataSetting?.id,
        advisorTime: dataSetting?.advisorTime,
        company: dataSetting?.company,
        contract: dataSetting?.contract,
        documents: dataSetting?.documents,
        group: isGroupal ? dataSetting?.group : undefined,
        invoicing: dataSetting?.invoicing,
        invoicingTime: dataSetting?.invoicingTime,
        isGroupal: dataSetting?.isGroupal,
        isPhysical: dataSetting?.isPhysical,
        isVirtual: dataSetting?.isVirtual,
        modality: dataSetting?.modality,
        services: dataSetting?.services,
      },
      succesAction: () => {
        customSwaltAlert({
          icon: 'success',
          title: locationAction === 'create' ? 'Creado exitosamente' : 'Duplicado exitosamente',
          text: `Se ha ${locationAction === 'create' ? 'creado' : 'duplicado'}  la parametrización: ${company?.name}`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        }).then(() => {
          history.goBack();
        });
      },
      doAfterException: error => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: error?.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  const editSetting = () => {
    postSettingSupport({
      url: `/medical/configSupport/`,
      token: token,
      noAlert: true,
      method: 'PUT',
      body: {
        eaccount,
        createdBy,
        userId,
        id: dataSetting?.id,
        advisorTime: dataSetting?.advisorTime,
        company: dataSetting?.company,
        contract: dataSetting?.contract,
        documents: dataSetting?.documents,
        group: isGroupal ? dataSetting?.group : undefined,
        invoicing: dataSetting?.invoicing,
        invoicingTime: dataSetting?.invoicingTime,
        isGroupal: dataSetting?.isGroupal,
        isPhysical: dataSetting?.isPhysical,
        isVirtual: dataSetting?.isVirtual,
        modality: dataSetting?.modality,
        services: dataSetting?.services,
      },
      succesAction: () => {
        customSwaltAlert({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: 'Parametrización actualizada',
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        }).then(() => {
          history.goBack();
        });
      },
      doAfterException: error => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: error?.message,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
      },
    });
  };

  const onValidFields = () => {
    const { advisorTime, company, contract, documents, group, invoicingTime, isGroupal, services } =
      dataSetting;

    if (company === undefined)
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `El campo empresa cliente es obligatorio`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });

    if (contract === undefined)
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `El campo contrato o plan es obligatorio`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });

    if (!services?.length)
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `El campo servicios es obligatorio`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });

    if (invoicingTime?.quantity === undefined)
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `El campo respuesta facturación es obligatorio`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });

    if (invoicingTime?.name === undefined)
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `El campo tiempo de respuesta facturación es obligatorio`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });

    if (Array.isArray(billing?.withContract?.value)) {
      if (!billing?.withContract?.value?.length)
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `El campo facturación por contrato es obligatorio`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
    } else {
      if (billing?.withContract?.value?.label === undefined)
        return customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: `El campo facturación por contrato es obligatorio`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
    }

    if (!documents?.filter(el => el?.type === 'invoicing_docs')?.length)
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `El campo documentos facturación es obligatorio`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });

    if (isGroupal === undefined)
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `El campo modalidad de facturación es obligatorio`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });

    if (isGroupal && group === undefined)
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `El campo agrupar por es obligatorio`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });

    if (advisorTime?.quantity === undefined)
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `El campo respuesta asesor es obligatorio`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });

    if (advisorTime?.name === undefined)
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `El campo tiempo de respuesta asesor es obligatorio`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });

    if (!documents?.filter(el => el?.type === 'admisionist_docs')?.length)
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `El campo documentos generales asesor es obligatorio`,
        confirmButtonText: 'Aceptar',
        showCancelButton: false,
      });
  };

  const validateSetting = () => {
    if (locationAction === 'detail') return history.goBack();

    if (!onValidFields()) {
      if (locationAction === 'edit') return editSetting();

      if (locationAction === 'duplicate') return createSetting();

      if (locationAction === 'create') return createSetting();
    }
  };

  const onFormatDataActionDetail = () => {
    setDataSetting(state => ({
      ...state,
      dataFormat: {
        companyClient: {
          companyClientValue: { label: company?.name, value: company?.id },
          companyClientOptions: [{ label: company?.name, value: company?.id }],
          contractValue: { label: contract?.name, value: contract?.id },
          contractOptions: [{ label: contract?.name, value: contract?.id }],
          nitValue: { label: company?.nit, value: company?.nit },
          nitOptions: [{ label: company?.nit, value: company?.nit }],
          servicesValue: data?.services?.map(service => ({
            label: service?.name,
            value: service?.id,
          })),
          servicesOptions: [{ label: contract?.srvName, value: contract?.contractServiceId }],
        },
        billing: {
          invoicingTime: {
            name: invoicingTime?.name,
            id: invoicingTime?.id,
            quantity: invoicingTime?.quantity,
          },
          withContract: {
            options: withContractOptions,
            value: isPhysical
              ? { label: 'Físico', value: 'isPhysical' }
              : isVirtual
                ? { label: 'Virtual', value: 'isVirtual' }
                : '',
          },
          invoicing_docs: documents
            ?.filter(el => el?.type === 'invoicing_docs')
            ?.map(el => ({ label: el.name, value: el.id })),
          isGroupal: isGroupal
            ? { label: 'Grupal', value: 'isGroup' }
            : { label: 'Individual', value: 'isIndividual' },
          withGroupal: { label: group?.name, value: group?.id },
        },
        adviser: {
          advisorTime: {
            name: data?.advisorTime?.name,
            id: data?.advisorTime?.id,
            quantity: data?.advisorTime?.quantity,
          },
          admisionist_docs: documents
            ?.filter(el => el?.type === 'admisionist_docs')
            ?.map(el => ({ label: el.name, value: el.id })),
        },
      },
    }));
  };

  const onFormatDataActionDuplicate = () => {
    onFormatDataActionDetail();
  };

  const onFormatDataActionEdit = () => {
    onFormatDataActionDetail();
  };

  const onFormatDataActionCreate = () => {
    setDataSetting(state => ({
      ...state,
      dataFormat: {
        companyClient: {
          companyClientValue: '',
          companyClientOptions: listCompany?.results?.map(el => ({
            label: el?.copyName,
            value: el?.id,
            nit: el?.Nit,
          })),
          contractValue: '',
          contractOptions: listContract?.results?.map(el => ({ label: el?.name, value: el?.id })),
          nitValue: '',
          nitOptions: [{ label: company?.nit, value: company?.nit }],
          servicesValue: '',
          servicesOptions: listServices?.results?.map(el => ({
            label: el?.srvName,
            value: el?.contractServiceId,
          })),
        },
        billing: {
          invoicingTime: {
            name: invoicingTime?.name,
            id: invoicingTime?.id,
            quantity: invoicingTime?.quantity,
          },
          withContract: {
            options: withContractOptions,
            value: '',
          },
          invoicing_docs: documents
            ?.filter(el => el?.type === 'invoicing_docs')
            ?.map(el => ({ label: el.name, value: el.id })),
          isGroupal: '',
          withGroupal: '',
        },
        adviser: {
          advisorTime: {
            name: data?.advisorTime?.name,
            id: data?.advisorTime?.id,
            quantity: data?.advisorTime?.quantity,
          },
          admisionist_docs: documents
            ?.filter(el => el?.type === 'admisionist_docs')
            ?.map(el => ({ label: el.name, value: el.id })),
        },
      },
    }));
  };

  useEffect(() => {
    if (locationAction === 'detail') onFormatDataActionDetail();
    if (locationAction === 'duplicate') onFormatDataActionDuplicate();
    if (locationAction === 'edit') onFormatDataActionEdit();
    if (locationAction === 'create') onFormatDataActionCreate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationAction]);

  useEffect(() => {
    if (locationAction !== 'detail') {
      setDataSetting(state => ({
        ...state,
        dataFormat: {
          ...state?.dataFormat,
          companyClient: {
            ...state?.dataFormat?.companyClient,
            companyClientOptions: listCompany?.results?.map(el => ({
              label: el?.copyName,
              value: el?.id,
              nit: el?.Nit,
            })),
          },
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCompany]);

  useEffect(() => {
    if (locationAction !== 'detail') {
      setDataSetting(state => ({
        ...state,
        dataFormat: {
          ...state?.dataFormat,
          companyClient: {
            ...state?.dataFormat?.companyClient,
            contractOptions: listContract?.results?.map(el => ({ label: el?.name, value: el?.id })),
          },
        },
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listContract]);

  useEffect(() => {
    if (locationAction !== 'detail') {
      setDataSetting(state => ({
        ...state,
        dataFormat: {
          ...state?.dataFormat,
          companyClient: {
            ...state?.dataFormat?.companyClient,
            servicesOptions: listServices?.results?.map(el => ({
              label: el?.srvName,
              value: el?.contractServiceId,
            })),
          },
        },
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listServices]);

  useEffect(() => {
    if (companyClient?.companyClientValue?.value && locationAction !== 'detail') {
      getListContract({
        url: '/medical/client/contract/',
        token: token,
        objFilters: {
          client: companyClient?.companyClientValue?.value,
          status: 'enabled',
          eaccount,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyClient?.companyClientValue?.value]);

  useEffect(() => {
    if (companyClient?.contractValue?.value && locationAction !== 'detail') {
      getListServices({
        url: '/medical/generals/serviceByContract/',
        token: token,
        objFilters: {
          idAccount: eaccount,
          contractId: companyClient?.contractValue?.value,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyClient?.contractValue?.value]);

  useEffect(() => {
    if (locationAction !== 'detail') {
      getListCompany({
        url: '/coporateClients/',
        objFilters: { id_account: eaccount },
        token: token,
      });

      getListTimes({
        url: '/medical/time/',
        objFilters: { id_account: eaccount },
        token: token,
      });

      getListDocuments({
        url: '/medical/supportFiles',
        objFilters: { id_account: eaccount, status: 1 },
        token: token,
      });

      getListConfigSupportGroup({
        url: '/medical/configSupportGroup',
        objFilters: { id_account: eaccount },
        token: token,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(loadListCompany ||
        loadListContract ||
        loadListServices ||
        loadListTimes ||
        loadListConfigSupportGroup ||
        loadListDocuments ||
        loadPostSettingSupport) &&
        loader}

      <Box
        display={'grid'}
        className='w-100'
        // gridTemplateColumns={'320px 1fr'}
      >
        <Box className={styles.borderRightSettingDetail} padding={1} paddingTop={6}>
          <Box display={'flex'} alignItems='center' mb={3} gap={1}>
            <img
              src={backIcon}
              alt='return'
              className='pointer ms-1'
              style={{ height: '25px' }}
              onClick={() => history.goBack()}
            />

            <Box className={`${styles.f32} ${styles.darkBlueText} fw-bold`}>Empresa cliente</Box>
          </Box>

          <Box className={`${styles.boxBorderColorSettingDetail}`} borderRadius={2}>
            <Box mb={1}>
              <span className={`${styles.darkBlueText} ${styles.f14}`}>
                &nbsp;Empresa cliente
                <span className={`${styles.ordOrangeText}`}>*</span>
              </span>
              <Select
                isDisabled={locationAction === 'detail'}
                value={companyClient?.companyClientValue || ''}
                options={companyClient?.companyClientOptions}
                placeholder={'Seleccionar...'}
                styles={customSelectAdmin}
                noOptionsMessage={() => 'No hay datos'}
                onChange={({ label, value, nit }) => {
                  setDataSetting(state => ({
                    ...state,
                    contract: undefined,
                    services: undefined,
                    company: { name: label, id: value, nit: nit },
                    dataFormat: {
                      ...state?.dataFormat,
                      companyClient: {
                        ...state?.dataFormat?.companyClient,
                        companyClientValue: {
                          label,
                          value,
                          nit,
                        },
                        nitValue: { label: nit, value: nit },
                        contractValue: '',
                        servicesValue: '',
                        servicesOptions: '',
                      },
                    },
                  }));
                }}
              />
            </Box>

            <Box mb={1}>
              <span className={`${styles.darkBlueText} ${styles.f14}`}>
                &nbsp;Nit
                <span className={`${styles.ordOrangeText}`}>*</span>
              </span>

              <Select
                isDisabled={true}
                value={companyClient?.nitValue || ''}
                placeholder={'Seleccionar...'}
                styles={customSelectAdmin}
                noOptionsMessage={() => 'No hay datos'}
              />
            </Box>

            <Box mb={1}>
              <span className={`${styles.darkBlueText} ${styles.f14}`}>
                &nbsp;Contrato o plan
                <span className={`${styles.ordOrangeText}`}>*</span>
              </span>
              <Select
                isDisabled={
                  locationAction === 'detail' || !companyClient?.companyClientValue?.value
                }
                value={companyClient?.contractValue || ''}
                options={companyClient?.contractOptions}
                placeholder={'Seleccionar...'}
                styles={customSelectAdmin}
                onChange={({ label, value }) => {
                  setDataSetting(state => ({
                    ...state,
                    contract: { name: label, id: value },
                    dataFormat: {
                      ...state?.dataFormat,
                      companyClient: {
                        ...state?.dataFormat?.companyClient,
                        contractValue: {
                          label,
                          value,
                        },
                        servicesValue: '',
                        servicesOptions: '',
                      },
                    },
                  }));
                }}
                noOptionsMessage={() => 'No hay datos'}
              />
            </Box>

            <Box mb={1}>
              <span className={`${styles.darkBlueText} ${styles.f14}`}>
                &nbsp;Servicios
                <span className={`${styles.ordOrangeText}`}>*</span>
              </span>
              <Select
                isMulti
                isDisabled={locationAction === 'detail' || !companyClient?.contractValue?.value}
                value={companyClient?.servicesValue}
                options={companyClient?.servicesOptions}
                placeholder={'Seleccionar...'}
                styles={{
                  ...customSelectMultiHorizontal,
                  valueContainer: provided => ({
                    ...provided,
                    padding: '0 6px',
                    overflow: 'auto',
                    // height: '30px',
                    maxHeight: '150px',
                    minHeight: '30px',
                  }),
                }}
                noOptionsMessage={() => 'No hay datos'}
                onChange={services => {
                  setDataSetting(state => ({
                    ...state,
                    services: services?.map(el => ({ id: el?.value, name: el?.label })),
                    dataFormat: {
                      ...state?.dataFormat,
                      companyClient: {
                        ...state?.dataFormat?.companyClient,
                        servicesValue: services,
                      },
                    },
                  }));
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box
          paddingTop={6}
          className={`${styles.bgColorSettingDetail} ${styles.styleBoxSettingDetail}`}
        >
          <Box className={`${styles.f32} ${styles.darkBlueText} fw-bold w-75 m-auto`} mb={1}>
            {`${
              locationAction === 'edit' ? 'Editar' : locationAction === 'detail' ? 'Detalle' : ''
            } ${
              ['duplicate', 'create'].includes(locationAction) ? 'P' : 'p'
            }arametrización soportes`}
          </Box>

          <Box>
            <Box className={'w-75 m-auto'}>
              <Box mb={3}>
                <Box display={'flex'} gap={1} alignItems={'center'}>
                  <Box className={styles.radioCircle} />
                  <Box className={`${styles.f18} ${styles.darkBlueText} fw-bold`}>Facturación</Box>
                </Box>

                <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={2} mb={1}>
                  <Box>
                    <span className={`${styles.darkBlueText} ${styles.f14}`}>
                      &nbsp;Respuesta facturación
                      <span className={`${styles.ordOrangeText}`}>*</span>
                    </span>

                    <BoxInputSelectText
                      valueInput={billing?.invoicingTime?.quantity || ''}
                      valueSelect={
                        invoicingTime?.name
                          ? { label: invoicingTime?.name, value: invoicingTime?.id }
                          : ''
                      }
                      textActive={billing?.invoicingTime?.name}
                      hideTextActive={locationAction !== 'detail'}
                      arrayOptions={listTimes?.results?.map(el => ({
                        value: el?.id,
                        label: el?.name,
                      }))}
                      handleChangeInput={({ target }) => {
                        setDataSetting(state => ({
                          ...state,
                          invoicingTime: { ...state?.invoicingTime, quantity: target?.value },
                          dataFormat: {
                            ...state?.dataFormat,
                            billing: {
                              ...state?.dataFormat?.billing,
                              invoicingTime: {
                                ...state?.dataFormat?.billing?.invoicingTime,
                                quantity: target?.value,
                              },
                            },
                          },
                        }));
                      }}
                      handleChangeSelect={({ label, value }) => {
                        setDataSetting(state => ({
                          ...state,
                          invoicingTime: { ...state?.invoicingTime, id: value, name: label },
                          dataFormat: {
                            ...state?.dataFormat,
                            billing: {
                              ...state?.dataFormat?.billing,
                              invoicingTime: {
                                ...state?.dataFormat?.billing?.invoicingTime,
                                id: value,
                                name: label,
                              },
                            },
                          },
                        }));
                      }}
                      placeholderSelect='Tiempo'
                      classNameInput={styles.blockFieldSettingSupport}
                      disabled={locationAction === 'detail'}
                      width={'auto'}
                      nameInput={''}
                      placeholderInput={'Escribir...'}
                      typeInput='number'
                      gridTemplateColumns='1fr 120px'
                      classNameTextBlock={`${
                        locationAction === 'detail' ? '' : styles.hoverTextBlockSettingSupport
                      } ${styles.cursorDefault}`}
                    />
                  </Box>

                  <Box mb={1}>
                    <span className={`${styles.darkBlueText} ${styles.f14}`}>
                      &nbsp;Facturación por contrato
                      <span className={`${styles.ordOrangeText}`}>*</span>
                    </span>
                    <Select
                      isMulti
                      isDisabled={locationAction === 'detail'}
                      value={billing?.withContract?.value || ''}
                      options={billing?.withContract?.options}
                      placeholder={'Seleccionar...'}
                      styles={customSelectMultiBig}
                      onChange={listWithContract => {
                        const isPhysical = listWithContract?.filter(
                          el => el?.value === 'isPhysical',
                        )?.length;
                        const isVirtual = listWithContract?.filter(
                          el => el?.value === 'isVirtual',
                        )?.length;

                        setDataSetting(state => ({
                          ...state,
                          isPhysical,
                          isVirtual,
                          dataFormat: {
                            ...state?.dataFormat,
                            billing: {
                              ...state?.dataFormat?.billing,
                              withContract: {
                                ...state?.dataFormat?.billing?.withContract,
                                value: listWithContract,
                              },
                            },
                          },
                        }));
                      }}
                      noOptionsMessage={() => 'No hay datos'}
                    />
                  </Box>
                </Box>

                <Box mb={1}>
                  <span className={`${styles.darkBlueText} ${styles.f14}`}>
                    &nbsp;Documentos facturación
                    <span className={`${styles.ordOrangeText}`}>*</span>
                  </span>
                  <Select
                    isMulti
                    isDisabled={locationAction === 'detail'}
                    value={billing?.invoicing_docs || ''}
                    options={listDocuments?.results?.map(el => ({
                      label: el?.name,
                      value: el?.id,
                      type: 'invoicing_docs',
                    }))}
                    placeholder={'Seleccionar...'}
                    styles={customSelectMultiBig}
                    noOptionsMessage={() => 'No hay datos'}
                    onChange={listData => {
                      const formatDocuments = listData?.map(el => ({
                        id: el?.value,
                        name: el?.label,
                        type: 'invoicing_docs',
                      }));
                      const stateListDocuments =
                        dataSetting?.documents?.filter(el => el?.type === 'admisionist_docs') || [];
                      const listDocuments = stateListDocuments?.concat(formatDocuments);

                      setDataSetting(state => ({
                        ...state,
                        documents: listDocuments,
                        dataFormat: {
                          ...state?.dataFormat,
                          billing: {
                            ...state?.dataFormat?.billing,
                            invoicing_docs: listData,
                          },
                        },
                      }));
                    }}
                  />
                </Box>

                <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={2} mb={1}>
                  <Box>
                    <span className={`${styles.darkBlueText} ${styles.f14}`}>
                      &nbsp;Modalidad de facturación
                      <span className={`${styles.ordOrangeText}`}>*</span>
                    </span>

                    <Select
                      value={billing?.isGroupal}
                      isDisabled={locationAction === 'detail'}
                      options={modalityBilling}
                      placeholder={'Seleccionar...'}
                      styles={customSelectAdmin}
                      noOptionsMessage={() => 'No hay datos'}
                      onChange={({ value }) => {
                        setDataSetting(state => ({
                          ...state,
                          isGroupal: value === 'isGroup' ? 1 : 0,
                          dataFormat: {
                            ...state?.dataFormat,
                            billing: {
                              ...state?.dataFormat?.billing,
                              isGroupal:
                                value === 'isGroup'
                                  ? { label: 'Grupal', value: 'isGroup' }
                                  : { label: 'Individual', value: 'isIndividual' },
                            },
                          },
                        }));
                      }}
                    />
                  </Box>

                  <Box>
                    {billing?.isGroupal?.value === 'isGroup' && (
                      <Box>
                        <span className={`${styles.darkBlueText} ${styles.f14}`}>
                          &nbsp;Agrupar por
                          <span className={`${styles.ordOrangeText}`}>*</span>
                        </span>

                        <Select
                          value={billing?.withGroupal}
                          isDisabled={locationAction === 'detail'}
                          options={listConfigSupportGroup?.results?.map(el => ({
                            label: el?.name,
                            value: el?.id,
                            tag: el?.tag,
                          }))}
                          placeholder={'Seleccionar...'}
                          styles={customSelectAdmin}
                          noOptionsMessage={() => 'No hay datos'}
                          onChange={({ value: id, label: name, tag }) => {
                            setDataSetting(state => ({
                              ...state,
                              group: { id, name, tag },
                              dataFormat: {
                                ...state?.dataFormat,
                                billing: {
                                  ...state?.dataFormat?.billing,
                                  withGroupal: { label: name, value: id, tag },
                                },
                              },
                            }));
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>

              <Box>
                <Box display={'flex'} gap={1} alignItems={'center'}>
                  <Box className={styles.radioCircle} />
                  <Box className={`${styles.f18} ${styles.darkBlueText} fw-bold`}>Asesores</Box>
                </Box>

                <Box mb={1}>
                  <Box>
                    <span className={`${styles.darkBlueText} ${styles.f14}`}>
                      &nbsp;Respuesta asesor
                      <span className={`${styles.ordOrangeText}`}>*</span>
                    </span>

                    <BoxInputSelectText
                      valueInput={adviser?.advisorTime?.quantity || ''}
                      valueSelect={
                        advisorTime?.name
                          ? { label: advisorTime?.name, value: advisorTime?.id }
                          : ''
                      }
                      textActive={adviser?.advisorTime?.name}
                      hideTextActive={locationAction !== 'detail'}
                      arrayOptions={listTimes?.results?.map(el => ({
                        value: el?.id,
                        label: el?.name,
                      }))}
                      handleChangeInput={({ target }) => {
                        setDataSetting(state => ({
                          ...state,
                          advisorTime: { ...state?.advisorTime, quantity: target?.value },
                          dataFormat: {
                            ...state?.dataFormat,
                            adviser: {
                              ...state?.dataFormat?.adviser,
                              advisorTime: {
                                ...state?.dataFormat?.adviser?.advisorTime,
                                quantity: target?.value,
                              },
                            },
                          },
                        }));
                      }}
                      handleChangeSelect={({ label, value }) => {
                        setDataSetting(state => ({
                          ...state,
                          advisorTime: { ...state?.advisorTime, id: value, name: label },
                          dataFormat: {
                            ...state?.dataFormat,
                            adviser: {
                              ...state?.dataFormat?.adviser,
                              advisorTime: {
                                ...state?.dataFormat?.adviser?.advisorTime,
                                id: value,
                                name: label,
                              },
                            },
                          },
                        }));
                      }}
                      placeholderSelect='Tiempo'
                      classNameInput={styles.blockFieldSettingSupport}
                      disabled={locationAction === 'detail'}
                      width={'auto'}
                      nameInput={''}
                      placeholderInput={'Escribir...'}
                      typeInput='number'
                      gridTemplateColumns='1fr 120px'
                      classNameTextBlock={`${
                        locationAction === 'detail' ? '' : styles.hoverTextBlockSettingSupport
                      } ${styles.cursorDefault}`}
                    />
                  </Box>
                </Box>

                <Box mb={2}>
                  <span className={`${styles.darkBlueText} ${styles.f14}`}>
                    &nbsp;Documentos generales asesor
                    <span className={`${styles.ordOrangeText}`}>*</span>
                  </span>

                  <Select
                    isMulti
                    isDisabled={locationAction === 'detail'}
                    value={adviser?.admisionist_docs || ''}
                    options={listDocuments?.results?.map(el => ({
                      label: el?.name,
                      value: el?.id,
                      type: 'admisionist_docs',
                    }))}
                    placeholder={'Seleccionar...'}
                    styles={customSelectMultiBig}
                    noOptionsMessage={() => 'No hay datos'}
                    onChange={listData => {
                      const formatDocuments = listData?.map(el => ({
                        id: el?.value,
                        name: el?.label,
                        type: 'admisionist_docs',
                      }));
                      const stateListDocuments =
                        dataSetting?.documents?.filter(el => el?.type === 'invoicing_docs') || [];
                      const listDocuments = stateListDocuments?.concat(formatDocuments);

                      setDataSetting(state => ({
                        ...state,
                        documents: listDocuments,
                        dataFormat: {
                          ...state?.dataFormat,
                          adviser: {
                            ...state?.dataFormat?.adviser,
                            admisionist_docs: listData,
                          },
                        },
                      }));
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box display={'flex'} my={8}>
            <Box className={'w-75 m-auto'} display={'flex'} gap={2} justifyContent='end'>
              {locationAction === 'detail' ? (
                ''
              ) : (
                <button
                  className='btnCancelAdmin'
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancelar
                </button>
              )}

              <button className='btnSaveAdmin' onClick={() => validateSetting()}>
                {locationAction === 'detail' ? 'Regresar' : 'Guardar'}
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
