//components
import { OverlayTrigger } from 'react-bootstrap';

//helpers
import { renderTooltip } from '../../../../helpers';

//styles
import tableStyles from '../../../../components/Layouts/tableStyle.module.scss';
import styles from '../styles.module.scss';

//icons or images
import userImg from '../../../../assets/img/header/user-img.png';

export const SearchCardDoctor = ({
  scheduleName,
  doctorName,
  doctorImage,
  doctorId,
  onToggleDoctor,
  active,
  onSearchExtraQuota,
}) => {
  return (
    <div
      className={active ? styles.app_card_doctor_active : styles.app_card_doctor}
      style={{ flexDirection: 'column', color: '#6e6f7c' }}
      onClick={() => {
        if(onSearchExtraQuota){
          onSearchExtraQuota();
        }
        onToggleDoctor({ doctorName, doctorId });
      }}
    >
      <div className='d-flex gap-2'>
        <img
          className={`${styles.app_container_img}`}
          src={doctorImage || userImg}
          alt='doctor-img'
          style={doctorImage ? { filter: 'none' } : {}}
        />

        <div className={styles.container_name_doctor}>
          <span className={styles.app_title_card} style={{ color: active ? '#FFFFFF' : '#6e6f7c' }}>
            {doctorName}
          </span>
          <span
            className={styles.app_site_card}
            style={{ color: active ? '#FFFFFF' : '#6e6f7c' }}
          ></span>
        </div>
      </div>

      <OverlayTrigger
        placement='top'
        delay={{ show: 250, hide: 250 }}
        overlay={renderTooltip(scheduleName || '-')}
      >
        <div
          className='d-flex gap-2 align-items-center'
          style={{ color: active ? '#FFFFFF' : '#6e6f7c' }}
        >
          <div style={{ fontWeight: 'bold', fontSize: '12px' }}>Agenda:</div>
          <div
            className={tableStyles.elipsis}
            style={{
              fontSize: '10px',
            }}
          >
            {scheduleName || '-'}
          </div>
        </div>
      </OverlayTrigger>
    </div>
  );
};
