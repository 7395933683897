import { Component } from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router, Switch, useHistory } from 'react-router-dom';
import { GeneralsAuthorizedRequests } from '../AdmissionModule/Admissions/Authorize/GeneralsAuthorizedRequests';
import DailyActivity from '../AdmissionModule/Admissions/DailyActivity';
import { DetailPatient as DetailPatientAdmision } from '../AdmissionModule/Admissions/DetailPatient';
import Franchises from '../AdmissionModule/Admissions/Franchises';
import PatientAdmission from '../AdmissionModule/Admissions/PatientAdmission';
import PatientList from '../AdmissionModule/Admissions/PatientList';
import PaymentOrderAdmision from '../AdmissionModule/Admissions/PaymentOrderAdmision';
import SupplierOrders from '../AdmissionModule/Admissions/SupplierOrders';
import { PatientBasicInfo } from '../AdmissionModule/Admissions/patientAdmissionTabs/PatientBasicInfo';
import { PatientInfoAdditional } from '../AdmissionModule/Admissions/patientAdmissionTabs/PatientInfoAdditional';
import { PatientInfoCollected } from '../AdmissionModule/Admissions/patientAdmissionTabs/PatientInfoCollected';
import ServicesOrders from '../AdmissionModule/Orders/ServicesOrders';
import AdditionalQuota from '../AppointmentModule/AdditionalQuota';
import AnulationReason from '../AppointmentModule/AnulationReason';
import RenderAppPdf from '../AppointmentModule/AppointmentReminder';
import BillingForm from '../AppointmentModule/BillingForm';
import ConsultApp from '../AppointmentModule/ConsultApp';
import DiscountAuthorisation from '../AppointmentModule/DiscountAuthorisation';
import { ReportMenuTabs } from '../AppointmentModule/PerformanceReport/ReportMenuTabs';
import { RegisterAppointment } from '../AppointmentModule/RegisterAppointment/index';
import RequestDiscount from '../AppointmentModule/RequestDiscount';
import ResourceAssignment from '../AppointmentModule/ResourceAssignment/ResourceAssignment';
import { ServicesCombos } from '../AppointmentModule/ServicesCombos';
import { DetailPatient } from '../AsistanceModule/AdminAppointment/DetailPatient';
import { ImportancePatient } from '../AsistanceModule/AdminAppointment/ImportancePatient';
import { ListPatient } from '../AsistanceModule/AdminAppointment/ListPatient';
import {
  AnulationReasons,
  AssignmentLocations,
  Consents,
} from '../AsistanceModule/AdmissionsMasterTables/';
import { CancellationOrderReason } from '../AsistanceModule/CancellationOrderReason';
import { CareGroups } from '../AsistanceModule/CareGroups';
import DilatationRequirements from '../AsistanceModule/DilatationRequirements';
import { MedicalServices } from '../AsistanceModule/MedicalServices';
import { Medicines } from '../AsistanceModule/Medicines';
import { NewCombo } from '../AsistanceModule/NewCombo';
import { NewMedicine } from '../AsistanceModule/NewMedicine';
import { NonPosDrugsJust } from '../AsistanceModule/NonPosDrugsJust';
import ParameterCommets from '../AsistanceModule/ParameterCommets';
import PredefinedText from '../AsistanceModule/PredefinedTexts';
import PredefinedTextNoIns from '../AsistanceModule/PredefinedTextsNoIns';
import ScheduleSettings from '../AsistanceModule/ScheduleSettings/ScheduleSettings';
import { ServicesParam } from '../AsistanceModule/ServicesParam';
import SettingComments from '../AsistanceModule/SettingComments';
import Specialties from '../AsistanceModule/Specialties';
import { SurgeryCombos } from '../AsistanceModule/SurgeryCombos';
import Symptom from '../AsistanceModule/Symptom';
import TimeParam from '../AsistanceModule/TimeParam';
import ParameterizationOfTimes from '../MedicalAccounts/ParameterizationOfTimes';
import { AssignFees } from '../MedicalAccounts/Recruitment/AssignFees';
import MedicalContracts from '../MedicalAccounts/Recruitment/MedicalContracts';
import { AuthInhabilities } from '../OrderingModule/MedicalManagement/AuthInhabilities';
import { AuthMedicine } from '../OrderingModule/MedicalManagement/AuthMedicine';
import { AuthServices } from '../OrderingModule/MedicalManagement/AuthServices';
import { Consult } from '../OrderingModule/Orders/Consult';
import { DisabilityHistory } from '../OrderingModule/Orders/DisabilityHistory';
import FormulatedMedicationReports from '../OrderingModule/Orders/FormulatedMedicationReports';
import FormulatedServiceReports from '../OrderingModule/Orders/FormulatedServiceReports';
import { MedicationHistory } from '../OrderingModule/Orders/MedicHistory';
import { Order } from '../OrderingModule/Orders/Order';
import { ReferenceHistory } from '../OrderingModule/Orders/ReferenceHistory';
import ReferencesReports from '../OrderingModule/Orders/ReferencesReports';
import { ServiceHistory } from '../OrderingModule/Orders/ServiceHistory';
import { RedirectionPage } from '../RedirectionPage';
import DoctorsDiaries from '../TelemedicineModule/Schedule/DoctorsDiaries';
import ScheduleDetail from '../TelemedicineModule/Schedule/ScheduleDetail';
import TelemedicineSetup from '../TelemedicineModule/Setting/TelemedicineSetup';
import ConsultAppointments from '../TelemedicineModule/VirtualDating/ConsultAppointments';
import DetailAppointment from '../TelemedicineModule/VirtualDating/DetailAppointment';
import PayHistory from '../TelemedicineModule/VirtualDating/PayHistory';
import CitationReport from '../TelemedicineModule/report/CitationReport';
import AccountsPayCollect from '../TreasuryModule/ConfiMovement/AccountsPayCollect';
import ActiveBankNoteTypes from '../TreasuryModule/ConfiMovement/ActiveBankNoteTypes';
import CheckReconsignment from '../TreasuryModule/ConfiMovement/CheckReconsignment';
import { ConfigMovesNew } from '../TreasuryModule/ConfiMovement/ConfigMovesNew';
import ConsignmentsToDate from '../TreasuryModule/ConfiMovement/ConsignmentsToDate';
import DefiMovement from '../TreasuryModule/ConfiMovement/DefiMovement';
import MoviBankNotes from '../TreasuryModule/ConfiMovement/MoviBankNotes';
import MoviTransferFunds from '../TreasuryModule/ConfiMovement/MoviTransferFunds';
import PaymentsInvoicesCaused from '../TreasuryModule/ConfiMovement/PaymentsInvoicesCaused';
import MoviReceiptsBox from '../TreasuryModule/ConfiMovement/ReceiptsBox';
import ReturnChecks from '../TreasuryModule/ConfiMovement/ReturnChecks';
import BankNote from '../TreasuryModule/Consultation/BankNote';
import ConsultDocuments from '../TreasuryModule/Consultation/ConsultDocuments';
import Egress from '../TreasuryModule/Consultation/Egress';
import QueryType from '../TreasuryModule/Consultation/QueryType';
import ReceiptBox from '../TreasuryModule/Consultation/ReceiptBox';
import CreateInvoice from '../TreasuryModule/InvoiceReceipt/CreateInvoice';
import ListInvoices from '../TreasuryModule/InvoiceReceipt/ListInvoices';
import RegisterInvoice from '../TreasuryModule/InvoiceReceipt/RegisterInvoice';
import BankMoveForm from '../TreasuryModule/MovimientosBancarios/BankMoveForm';
import BankNotes from '../TreasuryModule/MovimientosBancarios/BankNotes';
import CheckInventory from '../TreasuryModule/MovimientosBancarios/CheckInventory';
import TransferFunds from '../TreasuryModule/MovimientosBancarios/TransferFunds';
import BillPayment from '../TreasuryModule/Payments/BillPayment';
import CrossingAccounts from '../TreasuryModule/Payments/CrossingAccounts';
import PaymentOrders from '../TreasuryModule/Payments/PaymentOrders';
import Payroll from '../TreasuryModule/Payments/Payroll';
import RecaudoDeCartera from '../TreasuryModule/RecaudoCartera/BankDeposit';
import RecibosDeCaja from '../TreasuryModule/RecibosDeCaja/CashReceipt';
import ClientContracts from '../billing/administration/ClientContracs';
import Services from '../billing/administration/Services';
import CurrentGlosses from '../billing/glosses/CurrentGlosses';
import GlossHistory from '../billing/glosses/GlossHistory';
import GlossHistoryDetail from '../billing/glosses/GlossHistoryDetail';
import GlossRegister from '../billing/glosses/GlossRegister';
import RecordNotes from '../billing/glosses/RecordNotes';
import BillingCertificate from '../billing/invoice/BillingCertificate';
import CapitationInvoice from '../billing/invoice/CapitationInvoice';
import FreeInvoice from '../billing/invoice/FreeInvoice';
import { IncomeGroups } from '../billing/invoice/IncomeGroups';
import BillingHistory from '../billing/invoice/InvoiceHistory';
import SendingInvoices from '../billing/invoice/SendingInvoices';
import AccountSelect from '../components/AccountSelect/AccountSelect';
import AccountsPayable from '../components/AccountingConsultations/AccountsPayable';
import CashFlow from '../components/AccountingConsultations/CashFlow';
import DeferralHistory from '../components/AccountingConsultations/DeferralHistory';
import TaxCalendar from '../components/AccountingConsultations/TaxCalendar';
import VoucherHistory from '../components/AccountingConsultations/VoucherHistory';
import ViewCalendar from '../components/AccountingConsultations/viewCalendar';
import AccountingProviders from '../components/AccountingProviders/AccountingProviders';
import AccountingProviderDetail from '../components/AccountingProviders/accountingProviderDetail';
import AccountingProviderLegal from '../components/AccountingProviders/accountingProviderLegal';
import BalanceReport from '../components/AccountingReports/BalanceReport';
import GeneralBalance from '../components/AccountingReports/GeneralBalance';
import GeneralJournal from '../components/AccountingReports/GeneralJournal';
import IncomeStatement from '../components/AccountingReports/IncomeStatement';
import MajorYBalance from '../components/AccountingReports/MajorYBalance';
import SubsidiaryLedger from '../components/AccountingReports/SubsidiaryLedger';
import { AccountingSetup } from '../components/AccountingSetup/AccountingSetup';
import { NewProvision } from '../components/AccountingSetup/NewProvision';
import { NewReceivAge } from '../components/AccountingSetup/NewReceivAge';
import { ReceivableAges } from '../components/AccountingSetup/ReceivableAges';
import { BillingResolution } from '../components/Administration/BillingResolution';
import { NewResolution } from '../components/Administration/NewResolution';
import { Inhabilities } from '../components/ApprovedNews/Inhabilities';
import { OverTimeList } from '../components/ApprovedNews/OverTimeList';
import { Permissions } from '../components/ApprovedNews/Permissions';
import { Vacations } from '../components/ApprovedNews/Vacations';
import NewsSheet from '../components/ApprovedNews/newsSheet';
import ParameterRETEICA from '../components/ArchivesMasters/ParameterRETEICA';
import ThirdPartyAccounting from '../components/ArchivesMasters/ThirdPartyAccounting';
import { AuthPermissions } from '../components/AreaManagement/AuthPermissions';
import { MyPayrollOverTimeList } from '../components/AreaManagement/MyPayrollOverTimeList';
import { VacationsMyPayRoll } from '../components/AreaManagement/VacationsMyPayRoll';
import CategorySelect from '../components/CategorySelect/CategorySelect';
import CollectDetails from '../components/CollectLetter/CollectDetails';
import CollectionCreate from '../components/CollectLetter/CollectionCreate';
import CollectionLetter from '../components/CollectLetter/CollectionLetter';
import { Cancellations } from '../components/Containers/admision/authorizeRequest';
import {
  DailyClosing,
  DailyClosingDetails,
  HistoryClosing,
} from '../components/Containers/admision/dailyClosing';
import {
  QueriesOccupation,
  QueriesOpportunity,
  QueriesOrder,
} from '../components/Containers/admision/queries';
import { TmAdmissionSettings } from '../components/Containers/asistencial/tmAdmission/settings';
import GeneratePayrollList from '../components/GeneratePayroll/generatePayrollList';
import GestionPermisos from '../components/GestionUsuarios/GestionPermisos';
import GestionRoles from '../components/GestionUsuarios/GestionRoles';
import Home from '../components/Home/Home';
import { ArticlesInStorage } from '../components/Inventory/ArticlesInStorage';
import { Donation } from '../components/Inventory/Donation';
import { EntryAdjusment } from '../components/Inventory/EntryAdjusment';
import { EntryByConsign } from '../components/Inventory/EntryByConsign';
import { EntryByDonation } from '../components/Inventory/EntryByDonation';
import { EntryByPurchase } from '../components/Inventory/EntryByPurchase';
import { InventoryInStorage } from '../components/Inventory/InventoryInStorage';
import { InventoryTable } from '../components/Inventory/InventoryTable';
import { OutputAdjusment } from '../components/Inventory/OutputAdjusment';
import { OutputByConsum } from '../components/Inventory/OutputByConsum';
import Legalization from '../components/Inventory/Reports/Legalization';
import { ReportEntryByConsig } from '../components/Inventory/Reports/ReportEntryByConsig';
import { ReportEntryByDonation } from '../components/Inventory/Reports/ReportEntryByDonation';
import { ReportEntryByPurchase } from '../components/Inventory/Reports/ReportEntryByPurchase';
import { ReportInventoryMovement } from '../components/Inventory/Reports/ReportInventoryMovement';
import { TransferWarehouse } from '../components/Inventory/TransferWarehouse';
import Login from '../components/Login/Login';
import ForgotPassword from '../components/Login/forgotPassword';
import { Accounting } from '../components/ManagementModule/Accounting';
import { Billing } from '../components/ManagementModule/Billing';
import { Dashboard } from '../components/ManagementModule/Dashboard';
import { PayrollReport } from '../components/ManagementModule/PayrollReport';
import { Purchases } from '../components/ManagementModule/Purchases';
import { TreasuryReport } from '../components/ManagementModule/TreasuryReport';
import { Wallet } from '../components/ManagementModule/Wallet';
import AuthorizePaymentsTC from '../components/Movements/AuthorizePaymentsTC';
import Causation from '../components/Movements/Causation';
import ClosingVoucher from '../components/Movements/ClosingVoucher';
import CodeReplace from '../components/Movements/CodeReplace';
import ConfigurationCodeReplace from '../components/Movements/ConfigurationCodeReplace';
import DetailPayrollProvision from '../components/Movements/DetailPayrollProvision';
import DetaliPayrollAuthorization from '../components/Movements/DetaliPayrollAuthorizatio';
import PaymentsTC from '../components/Movements/PaymentsTC';
import PayrollAuthorization from '../components/Movements/PayrollAuthorization';
import PayrollProvision from '../components/Movements/PayrollProvision';
import ReplacementHistory from '../components/Movements/ReplacementHistory';
import VoucherRegistration from '../components/Movements/VoucherRegistration';
import Vauchers from '../components/Movements/Vouchers';
import CreatePaymentsTC from '../components/Movements/createPaymentsTC';
import { MyCv } from '../components/MyPayroll/MyCv';
import { Requests } from '../components/MyPayroll/Requests';
import {
  PremiumAuthorization,
  PremiumSchedule,
  Provisions,
  SeveranceAuthorization,
  SocialSecuritySchedule,
} from '../components/PayRollModule/';
import ListContracts from '../components/PayRollModule/Hiring/Contract';
import CreateContract from '../components/PayRollModule/Hiring/CreateContract';
import Dependencies from '../components/PayRollModule/Hiring/Dependencies';
import { NewPosition } from '../components/PayRollModule/Hiring/NewPosition';
import { OtrosiHistory } from '../components/PayRollModule/Hiring/OtrosiHistory';
import { PositionTable } from '../components/PayRollModule/Hiring/PositionTable';
import { Settlement } from '../components/PayRollModule/Hiring/Settlement';
import { VacationsAuthorizations } from '../components/PayRollModule/VacationsAuthorizations/VacationsAuthorizations';
import { AccordionForm } from '../components/PayRollModule/partners/AccordionForm';
import { CurriculumDetail } from '../components/PayRollModule/partners/CurriculumDetail';
import EmployeeDetailAuth from '../components/PayRollModule/partners/EmployeeDetailAuth';
import { NewPartner } from '../components/PayRollModule/partners/NewPartner';
import { PeopleMasterTable } from '../components/PayRollModule/partners/PeopleMasterTable';
import DetailAnswerSurvey from '../components/PayRollModule/partners/PerformanceAppraisal/DetailAnswerSurvey';
import DetailSurveyPerf from '../components/PayRollModule/partners/PerformanceAppraisal/DetailSurveyPerf';
import PerformanceAppraisal from '../components/PayRollModule/partners/PerformanceAppraisal/PerformanceAppraisal';
import SurveyPerf from '../components/PayRollModule/partners/PerformanceAppraisal/SurveyPerf';
import PaymentsTemplate from '../components/PaymentsTemplate/PaymentsTemplate';
import { Endowments } from '../components/Payroll/Endowments';
import MedicalExam from '../components/Payroll/Medical/MedicalExam';
import MedicalExamDetail from '../components/Payroll/Medical/MedicalExamDetail';
import PlanningMedicalExams from '../components/Payroll/Medical/PlanningMedicalExams';
import MiNomina from '../components/Payroll/MiNomina/MiNomina';
import AnswerSurvey from '../components/Payroll/MiNomina/performanceSurvey/AnswerSurvey';
import SurveyDetail from '../components/Payroll/MiNomina/performanceSurvey/SurveyDetail';
import NewRol from '../components/Payroll/Settings/NewRol';
import { PermissionManagement } from '../components/Payroll/Settings/PermissionManagement';
import RolDetail from '../components/Payroll/Settings/RolDetail';
import RolSettings from '../components/Payroll/Settings/RolSettings';
import CostTypes from '../components/PettyCash/CostTypes';
import EgressInAccounting from '../components/PettyCash/Egress';
import Repayment from '../components/PettyCash/Repayment';
import RepaymentHistory from '../components/PettyCash/RepaymentHistory';
import TablePettyCash from '../components/PettyCash/TablePettyCash';
import UserPermissions from '../components/PettyCash/UserPermissions';
import { NoteQuery } from '../components/ProvidersTable/NoteQuery';
import { NoteRecords } from '../components/ProvidersTable/NoteRecords';
import { NoteTypes } from '../components/ProvidersTable/NoteTypes';
import PurchaseOrder from '../components/PurchaseOrder/purchaseOrder';
import RequisitionForm from '../components/Requisitions/RequisitionForm';
import RequisitionsPending from '../components/Requisitions/RequisitionsPending';
import SalidaConsumo from '../components/SalidaConsumo/SalidaConsumo';
import { CreateSurvey } from '../components/SecAndHealth/CreateSurvey';
import { NewSurvey } from '../components/SecAndHealth/NewSurvey';
import { WorkEnv } from '../components/SecAndHealth/WorkEnv';
import PayrollSeveranceGenerate from '../components/SeverancePayroll/PayrollSeveranceGenerate';
import { SeverancePayroll } from '../components/SeverancePayroll/SeverancePayroll';
import TableAccAccounts from '../components/TableAccAccounts/TableAccAccounts';
import AuthAgreement from '../components/TableAgreement/AuthAgreement';
import TableAgreement from '../components/TableAgreement/TableAgreement';
import ApprovePurchaseOrder from '../components/TableApproveAuthorizations/ApprovePurchaseOrder';
import DetailPurchaseOrder from '../components/TableApproveAuthorizations/DetailPurchaseOrder';
import TableArticles from '../components/TableArticles/TableArticles';
import TableAuthorizeRequisitions from '../components/TableAuthorizeRequisitions/TableAuthorizeRequisitions';
import TableCieten from '../components/TableCieTen/TableCieten';
import TableClass from '../components/TableClassification/TableClass';
import TableCollections from '../components/TableCollections/TableCollections';
import ReportComplaintDetail from '../components/TableComplaints/ReportComplaintDetail';
import Complaint from '../components/TableComplaints/TableComplaints';
import TableConsumptionCenter from '../components/TableConsumptionCenter/TableConsumptionCenter';
import TableCorporateClients from '../components/TableCorporateClients/TableCorporateClients';
import TableCostCenter from '../components/TableCostCenter/TableCostCenter';
import TableCuentas from '../components/TableCuentas/TableCuentas';
import TableCups from '../components/TableCups/TableCups';
import DisciplinaryProcesses from '../components/TableDisciplinaryProcesses/DisciplinaryProcesses';
import { NewDisciplinaryProcess } from '../components/TableDisciplinaryProcesses/NewDisciplinaryProcess';
import { ProcessDetail } from '../components/TableDisciplinaryProcesses/ProcessDetail';
import TableFunctional from '../components/TableFunctionalUnit/TableFunctional';
import CurrentAuction from '../components/TableIndividualAuction/CurrentAuction';
import HistorialSubastas from '../components/TableIndividualAuction/HistorialSubastas';
import TableIndividualAuction from '../components/TableIndividualAuction/TableIndividualAuction';
import TablePendingAuctions from '../components/TableIndividualAuction/TablePendingAuctions';
import TableInventoryFamily from '../components/TableInventoryFamily/TableInventoryFamily';
import TableMeasures from '../components/TableMeasures/TableMeasures';
import TablePopulation from '../components/TablePopulation/TablePopulation';
import FormLegalPerson from '../components/TableProviders/FormLegalPerson';
import FormNaturalPerson from '../components/TableProviders/FormNaturalPerson';
import ProvidersSelectorForm from '../components/TableProviders/ProvidersSelectorForm';
import TableProviders from '../components/TableProviders/TableProviders';
import TablePuc from '../components/TablePuc/TablePuc';
import TableServices from '../components/TableServices/TableServices';
import TableSites from '../components/TableSites/TableSites';
import TableSpecialities from '../components/TableSpecialities/TableSpecialities';
import StatusPurchaseOrder from '../components/TableStatusPurchaseOrder/StatusPurchaseOrder';
import TableStorage from '../components/TableStorage/TableStorage';
import TableUsers from '../components/TableUsers/TableUsers';
import UsersCreation from '../components/TableUsers/UsersCreation';
import AuthEntryWarehouse from '../components/TableWarehouseEntry/AuthEntryWarehouse';
import TableWarehouseEntry from '../components/TableWarehouseEntry/TableWarehouseEntry';
import DetailPurchaseOrderinWarehouse from '../components/Warehouse/DetailPurchaseOrder';
import RegisterEntryByWarehouse from '../components/Warehouse/RegisterEntryByWarehouse';
import AccidentDetails from '../components/WorkAccidents/AccidentDetails';
import RegisterAccident from '../components/WorkAccidents/RegisterAccident';
import { WorkAccidents } from '../components/WorkAccidents/WorkAccidents';
import WorkCertificate from '../components/WorkCertificate/WorkCertificate';
import WorkCertificateRequest from '../components/WorkCertificate/WorkCertificateRequest';
import PatientReceived from '../components/patientReceived/patientReceived';
import { ContracTypes } from '../components/payrollTm/ContracTypes';
import UserProfile from '../components/userProfile/UserProfile';
import DetReceiptInvoices from '../purse/Invoice/DetReceiptInvoices';
import FilingInvoices from '../purse/Invoice/FilingInvoices';
import InvoiceHistory from '../purse/Invoice/InvoiceHistory';
import InvoiceReceipt from '../purse/Invoice/InvoiceReceipt';
import RecordsFiling from '../purse/Invoice/RecordsFiling';
import DocSignature from '../purse/Invoice/docSignature';
import BalanceInquiry from '../purse/balance/BalanceInquiry';
import CompanyBalance from '../purse/balance/CompanyBalance';
import PrelegalCollection from '../purse/balance/PrelegalCollection';
import ReportPurse from '../purse/balance/ReportPurse';
import ImpairmentPurse from '../purse/management/ImpairmentPurse';
import SituationPurse from '../purse/management/SituationPurse';
import PreDownload from '../purse/preDownload/PreDownload';
import GenerateRips from '../purse/rips/GenerateRips';
import store from '../store';
import LensOrder from './../AdmissionModule/Admissions/LensOrder';
import { GeneralsOrdersCanceled } from './../components/Containers/admision/orders';
import FullLayout from './FullLayout';
import PrivateRoute from './PrivateRoute';

// Routes SettingSupportModule
import { SettingSupport, SettingSupportDetails } from '../billing/settingSupportModule';

// Routes masterTableDocuments
import { Documents } from '../billing/masterTableDocuments/tableDocuments';

//* Routes AdmissionModule
import { ServiceOrderDetail } from '../AdmissionModule';
import { AdmissionGeneralBalance } from '../AdmissionModule/Admissions/AdmissionGeneralBalance';
import { AdmissionGeneralBalanceDetail } from '../AdmissionModule/Admissions/AdmissionGeneralBalanceDetail';
import { ValidationRouteAdminPatient } from '../AdmissionModule/Admissions/components';

import CompanyParams from '../AdministrationModule/Receivable/CompanyParams/CompanyParams';
import CreateCompanyParams from '../AdministrationModule/Receivable/CompanyParams/Create/CreateCompanyParams';
import CompanyParamDetail from '../AdministrationModule/Receivable/CompanyParams/Detail/Detail';
import InterestPayment from '../AdministrationModule/Receivable/InterestPayment/InterestPayment';
import PaymentTypes from '../AdministrationModule/Receivable/PaymentTypes/PaymentTypes';
import PortfolioDeterioration from '../AdministrationModule/Receivable/PortfolioDeterioration/PortfolioDeterioration';
import PortfolioProvision from '../AdministrationModule/Receivable/PortfolioProvision/PortfolioProvision';
import CreateRips from '../AdministrationModule/Receivable/Rips/Create/CreateRips';
import RipsDetail from '../AdministrationModule/Receivable/Rips/Details/RipsDetail';
import Rips from '../AdministrationModule/Receivable/Rips/Rips';
import RipsData from '../AdministrationModule/Receivable/RipsData/RipsData';
import SurgeryPayment from '../AdmissionModule/Payment/SurgeryPayment/SurgeryPayment';
import SurgeryPaymentCancellation from "../AdmissionModule/Payment/SurgeryPaymentCancellation/SurgeryPaymentCancellation";
import { NewOrderProvider } from '../AdmissionModule/TabsProviders';
import { ConsentsActions } from '../AsistanceModule/AdmissionsMasterTables/actionsConsents';
import DiagnosticAids from '../AsistanceModule/DiagnosticAids/DiagnosticAids';
import ServiceReport from '../AsistanceModule/DiagnosticAids/ServiceReport';
import MedicalConcept from '../AsistanceModule/MedicalConcept/MedicalConcept';
import PatientSafety from '../AsistanceModule/PatientSafety/PatientSafety';
import ReassignmentReading from '../AsistanceModule/ReassigmentReading/ReassigmentReading';
import UnexpectedSituations from '../AsistanceModule/UnexpectedSituations/UnexpectedSituations';
import { AproveInhabilities } from '../components/AreaManagement/AproveInhabilities';
import Holidays from '../components/Holidays/Holidays';
import { Logout } from '../components/Logout/Logout';
import GeneralParameters from '../components/SurgeryModule/GeneralParameters/GeneralParameters';
import SuppliesTypes from '../components/SurgeryModule/SuppliesTypes/SuppliesTypes';
import SurgeryBooks from '../components/SurgeryModule/SurgeryBook/SurgeryBook';
import SurgeryProcedures from '../components/SurgeryModule/SurgeryProcedures/SurgeryProcedures';
import TransferSurgery from '../components/SurgeryModule/TransferSurgery/TransferSurgery';
import TableAccountingPeriods from '../components/TableAccountigPeriods/TableAccountingPeriods';
import TableDeductionsAccrualsNew from '../components/TableDeductionsAccruals/TableDeductionsAccrualsNew';
import { BonusSheetAuth } from '../components/payrollRD/accounting/bonusSheet/BonusSheetAuth';
import { RoyaltyPaymentAuth } from '../components/payrollRD/accounting/royaltyPayment/RoyaltyPaymentAuth';
import { BonusSheet } from '../components/payrollRD/payroll/bonusSheet/BonusSheet';
import { RoyaltyPayment } from '../components/payrollRD/payroll/royaltyPayment/RoyaltyPayment';
import DailyConsignment from '../purse/DailyConsignment/DailyConsignment';
import FilingHistoryDetails from '../purse/Invoice/Details/FilingHistoryDetails';
import InvoiceSupports from '../purse/Invoice/InvoiceSupports';
import InvoiceMovements from '../purse/InvoiceMovements/InvoiceMovements';
import PreDownloadDetail from '../purse/preDownload/PreDownloadDetail';

// 404 handling
import AssistanceLots from "../AsistanceModule/AssistanceLots/AssistanceLots";
import SettlementHistory from "../MedicalAccounts/Settlement/History";
import Settlements from "../MedicalAccounts/Settlement/Settlements";
import { CreateNote } from '../billing/invoicing_notes/CreateNote';
import { Layout404 } from "../components/404";
import { NewSheetDetail } from "../components/ApprovedNews/newsSheet/detail";

// TM TREASURY
import { BankNoteTypes, PaymentTypesTreasury, PrioritizeBillsToPay, TypesCostsTc } from "../AdministrationModule/";
import Cie10View from "../AsistanceModule/Cie10View";
import Cie10ViewExtended from "../AsistanceModule/Cie10ViewExtended";
import NotesHistoryAdmissions from "../AdmissionModule/Notes/NotesHistory/NotesHistory";
import NotesHistoryInvoicing from "../billing/invoicing_notes/NotesHistory";
import LinkServices from "../MedicalAccounts/Recruitment/LinkServices";


class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Router>
        <Provider store={store}>
          <Switch>
            <Route exact path='/'>
              <Redirect to='/login' />
            </Route>

            <Route exact path='/login/olvidarcontrasena' history={useHistory}>
              <ForgotPassword />
            </Route>

            <Route exact path='/citas/formularioFacturacionPersonaJuridica' history={useHistory}>
              <BillingForm />
            </Route>
            {/* /* ------------------------------- BLANK PAGE ------------------------------- */}
            <Route exact path='/redirect/:id/:token/:clhId/:appId'>
              <RedirectionPage />
            </Route>
            {/* /* ------------------------------------ - ----------------------------------- */}
            {/* <Route exact path="/login/forgotpassword" component={Login} history={useHistory}/> */}

            <Route exact path='/login' component={Login} history={useHistory} />
            <Route exact path='/receivedAction/' component={PatientReceived} history={useHistory} />

            <Route exact path='/accountselect' history={useHistory}>
              <AccountSelect />
            </Route>
            <PrivateRoute>
              <Switch>
              <Route exact path='/categoryselect' history={useHistory}>
                <CategorySelect />
              </Route>

              <Route exact path='/:category/inicio' history={useHistory}>
                <FullLayout>
                  <Home />
                </FullLayout>
              </Route>

              <Route exact path='/administracion/servicios'>
                <FullLayout>
                  <TableServices />
                </FullLayout>
              </Route>

              <Route exact path='/administracion/franquicias'>
                <FullLayout>
                  <Franchises />
                </FullLayout>
              </Route>

              <Route path='/administracion/diasFestivos'>
                <FullLayout>
                  <Holidays />
                </FullLayout>
              </Route>

              <Route exact path='/asistencial/MotivosCancelacionOrden'>
                <FullLayout>
                  <CancellationOrderReason />
                </FullLayout>
              </Route>

              <Route path='/administracion/sedes'>
                <FullLayout>
                  <TableSites />
                </FullLayout>
              </Route>
              <Route path='/administracion/DevengadosDeducciones'>
                <FullLayout>
                  <TableDeductionsAccrualsNew />
                </FullLayout>
              </Route>

              <Route path='/administracion/puc'>
                <FullLayout>
                  <TablePuc />
                </FullLayout>
              </Route>

              <Route path='/administracion/functional'>
                <FullLayout>
                  <TableFunctional />
                </FullLayout>
              </Route>

              <Route path='/administracion/storage'>
                <FullLayout>
                  <TableStorage />
                </FullLayout>
              </Route>

              <Route path='/administracion/costcenter'>
                <FullLayout>
                  <TableCostCenter />
                </FullLayout>
              </Route>

              <Route path='/administracion/collections'>
                <FullLayout>
                  <TableCollections />
                </FullLayout>
              </Route>

              <Route path='/administracion/impresiones-diagnosticas'>
                <FullLayout>
                  <TableCieten />
                </FullLayout>
              </Route>

              <Route path='/administracion/unidades-medida'>
                <FullLayout>
                  <TableMeasures />
                </FullLayout>
              </Route>

              <Route path='/administracion/articulos-activos'>
                <FullLayout>
                  <TableArticles />
                </FullLayout>
              </Route>

              <Route path='/administracion/familia-inventario'>
                <FullLayout>
                  <TableInventoryFamily />
                </FullLayout>
              </Route>
              <Route path='/administracion/centro-de-consumo'>
                <FullLayout>
                  <TableConsumptionCenter />
                </FullLayout>
              </Route>

              <Route path='/administracion/cups'>
                <FullLayout>
                  <TableCups />
                </FullLayout>
              </Route>

              <Route exact path='/administracion/proveedores/selector'>
                <FullLayout>
                  <ProvidersSelectorForm />
                </FullLayout>
              </Route>

              <Route exact path='/administracion/proveedores'>
                <FullLayout>
                  <TableProviders />
                </FullLayout>
              </Route>

              <Route exact path='/administracion/proveedores/selector/persona-juridica'>
                <FullLayout>
                  <FormLegalPerson store={store} />
                </FullLayout>
              </Route>

              <Route exact path='/administracion/proveedores/selector/persona-natural'>
                <FullLayout>
                  <FormNaturalPerson />
                </FullLayout>
              </Route>

              <Route path='/administracion/corporateClients'>
                <FullLayout>
                  <TableCorporateClients />
                </FullLayout>
              </Route>

              <Route path='/administracion/accountingPeriods'>
                <FullLayout>
                  <TableAccountingPeriods />
                </FullLayout>
              </Route>

              <Route
                path='/compras/subastaindividual'
                render={props => (
                  <FullLayout>
                    <TableIndividualAuction history={props.history} location={props.location} />
                  </FullLayout>
                )}
              />

              <Route path='/compras/subastapendiente'>
                <FullLayout>
                  <TablePendingAuctions />
                </FullLayout>
              </Route>

              <Route path='/compras/subastasvigentes'>
                <FullLayout>
                  <CurrentAuction />
                </FullLayout>
              </Route>

              <Route path='/compras/subastashistorial'>
                <FullLayout>
                  <HistorialSubastas />
                </FullLayout>
              </Route>

              <Route path='/compras/requisicionnueva'>
                <FullLayout>
                  <RequisitionForm />
                </FullLayout>
              </Route>

              <Route path='/compras/requisicionpendiente'>
                <FullLayout>
                  <RequisitionsPending />
                </FullLayout>
              </Route>

              <Route path='/compras/salidaconsumo'>
                <FullLayout>
                  <SalidaConsumo />
                </FullLayout>
              </Route>

              <Route path='/compras/cuentascontables'>
                <FullLayout>
                  <TableAccAccounts />
                </FullLayout>
              </Route>
              <Route path='/compras/authorizeRequisitions'>
                <FullLayout>
                  <TableAuthorizeRequisitions />
                </FullLayout>
              </Route>
              <Route path='/compras/convenios'>
                <FullLayout>
                  <TableAgreement />
                </FullLayout>
              </Route>

              <Route path='/compras/authConv'>
                <FullLayout>
                  <AuthAgreement />
                </FullLayout>
              </Route>

              {/* <Route path="/compras/AOrdenCompra">
                <FullLayout>
                  <ApprovePurchaseOrder />
                </FullLayout>
              </Route> */}

              <Route path='/tesoreria/MovimientosBancarios'>
                <FullLayout>
                  <BankMoveForm />
                </FullLayout>
              </Route>

              <Route path='/tesoreria/RecibosDeCaja'>
                <FullLayout>
                  <RecibosDeCaja />
                </FullLayout>
              </Route>

              <Route path='/configuracion/miperfil'>
                <FullLayout>
                  <UserProfile />
                </FullLayout>
              </Route>

              <Route path='/administracion/cuentas'>
                <FullLayout>
                  <TableCuentas />
                </FullLayout>
              </Route>
              <Route path='/administracion/usuarios'>
                <FullLayout>
                  <TableUsers />
                </FullLayout>
              </Route>

              <Route path='/administracion/gestionpermisos'>
                <FullLayout>
                  <GestionPermisos />
                </FullLayout>
              </Route>
              <Route path='/administracion/gestionroles'>
                <FullLayout>
                  <GestionRoles />
                </FullLayout>
              </Route>
              <Route path='/administracion/configuracionContable'>
                <FullLayout>
                  <AccountingSetup />
                </FullLayout>
              </Route>

              <Route path='/DetalleAOrdenCompra'>
                <FullLayout>
                  <DetailPurchaseOrder />
                </FullLayout>
              </Route>

              <Route path='/especialidades'>
                <FullLayout>
                  <TableSpecialities />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/RecaudoDeCartera'>
                <FullLayout>
                  <RecaudoDeCartera />
                </FullLayout>
              </Route>
              <Route exact path='/tesoreria/RecepcionDeFacturas'>
                <FullLayout>
                  <ListInvoices />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/RecepcionDeFacturas/NuevaFactura'>
                <FullLayout>
                  <CreateInvoice />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/RecepcionDeFacturas/RegistroFactura'>
                <FullLayout>
                  <RegisterInvoice />
                </FullLayout>
              </Route>
              <Route path='/clasificacion'>
                <FullLayout>
                  <TableClass />
                </FullLayout>
              </Route>
              <Route path='/compras/registrarEntradaAlmacen'>
                <FullLayout>
                  <RegisterEntryByWarehouse />
                </FullLayout>
              </Route>
              <Route path='/compras/orden-de-compra'>
                <FullLayout>
                  <PurchaseOrder />
                </FullLayout>
              </Route>
              <Route path='/detalleOrdenDeCompra'>
                <FullLayout>
                  <DetailPurchaseOrderinWarehouse />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/OrdenesDePago'>
                <FullLayout>
                  <PaymentOrders />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/PagoDeFacturaCausadas'>
                <FullLayout>
                  <BillPayment />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/CruceDeCuenta'>
                <FullLayout>
                  <CrossingAccounts />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/Pagos/PagoDeNomina'>
                <FullLayout>
                  <Payroll />
                </FullLayout>
              </Route>
              {/* <Route path="/tesoreria/RegistroDeCheques">
                <FullLayout>
                  <CheckRegister />
                </FullLayout>
              </Route>
              <Route path="/tesoreria/ReconsignacionDeCheques">
                <FullLayout>
                  <ReassignmentChecks />
                </FullLayout>
              </Route>*/}

              <Route path='/compras/AOrdenCompra'>
                <FullLayout>
                  <ApprovePurchaseOrder />
                </FullLayout>
              </Route>

              {/* <Route path="/DetalleAOrdenCompra">
                <FullLayout>
                  <DetailPurchaseOrder />
                </FullLayout>
              </Route> */}

              <Route path='/tesoreria/RegistroNotasDeBanco'>
                <FullLayout>
                  <BankNotes />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/TrasladoDeFondos'>
                <FullLayout>
                  <TransferFunds />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/InventarioDeCheques'>
                <FullLayout>
                  <CheckInventory />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/TipoDeConsulta'>
                <FullLayout>
                  <QueryType />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/Consulta/Documentos'>
                <FullLayout>
                  <ConsultDocuments />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/Consulta/NotaBancaria'>
                <FullLayout>
                  <BankNote />
                </FullLayout>
              </Route>
              {/* <Route path="/tesoreria/Consulta/Reconsignaciones">
                <FullLayout>
                  <Reconsignments />
                </FullLayout>
              </Route> */}
              <Route path='/tesoreria/Consulta/Egresos'>
                <FullLayout>
                  <Egress />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/Consulta/ReciboCaja'>
                <FullLayout>
                  <ReceiptBox />
                </FullLayout>
              </Route>
              <Route exact path='/tesoreria/ConfiMovimiento'>
                <FullLayout>
                  <DefiMovement />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/ConfiMovimiento/RecibosDeCaja'>
                <FullLayout>
                  <MoviReceiptsBox />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/ConfiMovimiento/PagosFacturasCausadas'>
                <FullLayout>
                  <PaymentsInvoicesCaused />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/ConfiMovimiento/TrasladoDeFondos'>
                <FullLayout>
                  <MoviTransferFunds />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/ConfiMovimiento/NotasBancarias'>
                <FullLayout>
                  <MoviBankNotes />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/ConfiMovimiento/ConsignacionesFecha'>
                <FullLayout>
                  <ConsignmentsToDate />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/ConfiMovimiento/DevolucionDeCheques'>
                <FullLayout>
                  <ReturnChecks />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/ConfiMovimiento/ReconsiganacionDeCheques'>
                <FullLayout>
                  <CheckReconsignment />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/ConfiMovimiento/CruceDeCuentas'>
                <FullLayout>
                  <AccountsPayCollect />
                </FullLayout>
              </Route>
              <Route path='/tesoreria/ConfiMovimiento/NotasDeBancosActivas'>
                <FullLayout>
                  <ActiveBankNoteTypes />
                </FullLayout>
              </Route>

              <Route exact path='/compras/autorizar-entradas-almacen'>
                <FullLayout>
                  <AuthEntryWarehouse />
                </FullLayout>
              </Route>
              {/* /* ---------------------------------------------------------------------- */
              /*                           INVENTORY ROUTES --KMR                           */
              /* -------------------------------------------------------------------------- */}
              <Route path='/compras/registrarMovimiento'>
                <FullLayout>
                  <InventoryTable />
                </FullLayout>
              </Route>
              <Route path='/compras/entradaPorCompra'>
                <FullLayout>
                  <EntryByPurchase />
                </FullLayout>
              </Route>
              <Route path='/compras/entradaPorConsignacion'>
                <FullLayout>
                  <EntryByConsign />
                </FullLayout>
              </Route>
              <Route path='/compras/entradaPorDonacion'>
                <FullLayout>
                  <EntryByDonation />
                </FullLayout>
              </Route>
              <Route path='/compras/salidaPorConsumo'>
                <FullLayout>
                  <OutputByConsum />
                </FullLayout>
              </Route>
              <Route path='/compras/informeEntradaPorCompra'>
                <FullLayout>
                  <ReportEntryByPurchase />
                </FullLayout>
              </Route>
              <Route exact path='/compras/informeEntradaPorConsignacion'>
                <FullLayout>
                  <ReportEntryByConsig />
                </FullLayout>
              </Route>
              <Route path='/compras/informeEntradaPorDonacion'>
                <FullLayout>
                  <ReportEntryByDonation />
                </FullLayout>
              </Route>
              <Route path='/compras/ajusteDeEntrada'>
                <FullLayout>
                  <EntryAdjusment />
                </FullLayout>
              </Route>
              <Route path='/compras/ajusteDeSalida'>
                <FullLayout>
                  <OutputAdjusment />
                </FullLayout>
              </Route>
              <Route path='/compras/articulosEnAlmacen'>
                <FullLayout>
                  <ArticlesInStorage />
                </FullLayout>
              </Route>
              <Route path='/compras/trasladoDeAlmacen'>
                <FullLayout>
                  <TransferWarehouse />
                </FullLayout>
              </Route>
              <Route path='/compras/Donacion'>
                <FullLayout>
                  <Donation />
                </FullLayout>
              </Route>
              <Route path='/compras/informeMovimientosInventario'>
                <FullLayout>
                  <ReportInventoryMovement />
                </FullLayout>
              </Route>
              <Route path='/compras/inventarioEnAlmacen'>
                <FullLayout>
                  <InventoryInStorage />
                </FullLayout>
              </Route>
              {/* /* ------------------------- PROVIDERS ROUTES -- KMR ------------------------ */}
              <Route path='/contabilidad/tiposDeNotas'>
                <FullLayout>
                  <NoteTypes />
                </FullLayout>
              </Route>

              <Route path='/compras/registrosDeNotas'>
                <FullLayout>
                  <NoteRecords />
                </FullLayout>
              </Route>
              <Route path='/compras/consultaDeNotas'>
                <FullLayout>
                  <NoteQuery />
                </FullLayout>
              </Route>

              <Route
                exact
                path='/compras/informeEntradaPorConsignacion/:id'
                render={props => (
                  <FullLayout>
                    <Legalization {...props} />
                  </FullLayout>
                )}
              />

              {/* /* ------------------------------------ x ----------------------------------- */}
              <Route path='/cartera/Factura/DetalleDeRecepcionDeFactura'>
                <FullLayout>
                  <DetReceiptInvoices />
                </FullLayout>
              </Route>
              <Route exact path='/cartera/Recaudo/PreDescarga'>
                <FullLayout>
                  <PreDownload />
                </FullLayout>
              </Route>
              <Route exact path='/cartera/Recaudo/PreDescarga/Detalle/:id'>
                <FullLayout>
                  <PreDownloadDetail />
                </FullLayout>
              </Route>

              <Route exact path='/cartera/Factura/HistoricoDeRadicacion'>
                <FullLayout>
                  <InvoiceHistory />
                </FullLayout>
              </Route>
              <Route exact path='/cartera/Factura/HistoricoDeRadicacion/Detalle/:id'>
                <FullLayout>
                  <FilingHistoryDetails />
                </FullLayout>
              </Route>
              <Route path='/cartera/Factura/RadicacionDeFacturas'>
                <FullLayout>
                  <FilingInvoices />
                </FullLayout>
              </Route>
              <Route path='/cartera/Saldo/SaldoDeEmpresa'>
                <FullLayout>
                  <CompanyBalance />
                </FullLayout>
              </Route>
              <Route path='/cartera/Saldo/InformeDeCartera'>
                <FullLayout>
                  <ReportPurse />
                </FullLayout>
              </Route>

              <Route path='/cartera/Saldo/MovimientosFactura'>
                <FullLayout>
                  <InvoiceMovements />
                </FullLayout>
              </Route>

              <Route path='/cartera/Rips'>
                <FullLayout>
                  <GenerateRips />
                </FullLayout>
              </Route>
              <Route exact path='/cartera/Gestion/SituacioDeCartera'>
                <FullLayout>
                  <SituationPurse />
                </FullLayout>
              </Route>
              <Route path='/cartera/Gestion/DeterioroDeCartera'>
                <FullLayout>
                  <ImpairmentPurse />
                </FullLayout>
              </Route>
              <Route path='/cartera/Factura/FirmaDeDocumento'>
                <FullLayout>
                  <DocSignature />
                </FullLayout>
              </Route>
              <Route path='/facturacion/Glosas/RegistroDeGlosas'>
                <FullLayout>
                  <GlossRegister />
                </FullLayout>
              </Route>
              <Route path='/facturacion/Glosas/GlosasVigentes'>
                <FullLayout>
                  <CurrentGlosses />
                </FullLayout>
              </Route>

              <Route path='/administracion/Facturacion/GruposIngreso'>
                <FullLayout>
                  <IncomeGroups />
                </FullLayout>
              </Route>

              <Route path='/facturacion/Glosas/HistorialDeGlosas'>
                <FullLayout>
                  <GlossHistory />
                </FullLayout>
              </Route>

              <Route path='/facturacion/Glosas/DetalleHistorialDeGlosas'>
                <FullLayout>
                  <GlossHistoryDetail />
                </FullLayout>
              </Route>
              <Route path='/facturacion/Glosas/RegistroDeNotas'>
                <FullLayout>
                  <RecordNotes />
                </FullLayout>
              </Route>
              {/* <Route path="/facturacion/Glosas/NotasDeCliente">
                <FullLayout>
                  <CustomerNotes />
                </FullLayout>
              </Route> */}
              <Route path='/facturacion/Factura/FacturaLibre'>
                <FullLayout>
                  <FreeInvoice />
                </FullLayout>
              </Route>
              <Route path='/facturacion/Factura/GenerarFactura'>
                <FullLayout>
                  <CapitationInvoice />
                </FullLayout>
              </Route>
              <Route path='/facturacion/Factura/EnvioDeFacturas'>
                <FullLayout>
                  <SendingInvoices />
                </FullLayout>
              </Route>

              <Route exact path='/facturacion/Factura/HistorialDeFacturas'>
                <FullLayout>
                  <BillingHistory />
                </FullLayout>
              </Route>

              <Route exact path='/facturacion/Factura/ActasDeFacturacion'>
                <FullLayout>
                  <BillingCertificate />
                </FullLayout>
              </Route>

              <Route exact path='/facturacion/NotasContables/CrearNota'>
                <FullLayout>
                  <CreateNote version={'administration'} />
                </FullLayout>
              </Route>

              <Route exact path='/facturacion/NotasContables/Historial'>
                <FullLayout>
                  <NotesHistoryInvoicing />
                </FullLayout>
              </Route>

              <Route exact path='/administracion/Facturacion/parametrizacionSoporte'>
                <FullLayout>
                  <SettingSupport />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/Facturacion/parametrizacionSoporte/:id'>
                <FullLayout>
                  <SettingSupportDetails />
                </FullLayout>
              </Route>

              <Route exact path='/administracion/Facturacion/tmDocumentos'>
                <FullLayout>
                  <Documents />
                </FullLayout>
              </Route>

              {/* /* ------------------------------------ x ----------------------------------- */}

              <Route exact path='/administracion/Facturacion/Servicios'>
                <FullLayout>
                  <Services />
                </FullLayout>
              </Route>

              <Route exact path='/administracion/Facturacion/ContratosDeCliente'>
                <FullLayout>
                  <ClientContracts />
                </FullLayout>
              </Route>

              <Route path='/compras/estado-orden-de-compra/'>
                <FullLayout>
                  <StatusPurchaseOrder />
                </FullLayout>
              </Route>

              <Route exact path='/compras/historial-entradas-almacen'>
                <FullLayout>
                  <TableWarehouseEntry />
                </FullLayout>
              </Route>
              <Route exact path='/cartera/Factura/RecepcionDeFacturas'>
                <FullLayout>
                  <InvoiceReceipt />
                </FullLayout>
              </Route>
              <Route exact path='/cartera/Factura/RecepcionDeFacturas/:id'>
                <FullLayout>
                  <DetReceiptInvoices />
                </FullLayout>
              </Route>
              <Route exact path='/cartera/Factura/SoportesFactura/:id'>
                <FullLayout>
                  <InvoiceSupports />
                </FullLayout>
              </Route>
              <Route path='/crear_usuarios'>
                <FullLayout>
                  <UsersCreation />
                </FullLayout>
              </Route>
              {/* /* -------------------------------------------------------------------------- */
              /*                               Accounting:. Petty Cash                           */
              /* -------------------------------------------------------------------------- */}

              <Route exact path='/contabilidad/CajaMenor/TablaCajaMenor'>
                <FullLayout>
                  <TablePettyCash />
                </FullLayout>
              </Route>
              <Route path='/cartera/Factura/ActasDeRadicación'>
                <FullLayout>
                  <RecordsFiling />
                </FullLayout>
              </Route>
              <Route path='/cartera/Saldo/ConsultaDeSaldos'>
                <FullLayout>
                  <BalanceInquiry />
                </FullLayout>
              </Route>
              <Route path='/cartera/Recaudo/ConsignacionDiaria'>
                <FullLayout>
                  <DailyConsignment />
                </FullLayout>
              </Route>
              <Route path='/cartera/Saldo/CobroPrejuridico'>
                <FullLayout>
                  <PrelegalCollection />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/procesosDisciplinario'>
                <FullLayout>
                  <DisciplinaryProcesses />
                </FullLayout>
              </Route>
              <Route exact path='/contabilidad/CajaMenor/TiposDeGastos'>
                <FullLayout>
                  <CostTypes />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/CajaMenor/Egresos'>
                <FullLayout>
                  <EgressInAccounting />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/CajaMenor/Reembolsos'>
                <FullLayout>
                  <Repayment />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/CajaMenor/HistorialDeReembolso'>
                <FullLayout>
                  <RepaymentHistory />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/CajaMenor/PermisosDeUsuario'>
                <FullLayout>
                  <UserPermissions />
                </FullLayout>
              </Route>

              {/* /* -------------------------------------------------------------------------- */
              /*                               End Accounting Petty Cash                          */
              /* -------------------------------------------------------------------------- */}

              {/* /* -------------------------------------------------------------------------- */
              /*                               Accounting:. Master files                     */
              /* -------------------------------------------------------------------------- */}

              <Route exact path='/contabilidad/parametrizacionReteICA'>
                <FullLayout>
                  <ParameterRETEICA />
                </FullLayout>
              </Route>

              {/* /* -------------------------------------------------------------------------- */
              /*                               End Accounting:. Master files                     */
              /* -------------------------------------------------------------------------- */}

              <Route exact path='/nomina/CartaLaboral'>
                <FullLayout>
                  <WorkCertificate />
                </FullLayout>
              </Route>
              <Route exact path='/miNomina/miNomina/SolCartaLaboral'>
                <FullLayout>
                  <WorkCertificateRequest />
                </FullLayout>
              </Route>

              {/* <Route exact path="/compras/historial-entradas-almacen"> */}

              <Route exact path='/contabilidad/causacion'>
                <FullLayout>
                  <Causation />
                </FullLayout>
              </Route>
              <Route exact path='/contabilidad/Movimientos/autorizacionNomina'>
                <FullLayout>
                  <PayrollAuthorization />
                </FullLayout>
              </Route>
              <Route exact path='/contabilidad/Movimientos/autorizacionCesantias'>
                <FullLayout>
                  <SeveranceAuthorization />
                </FullLayout>
              </Route>
              <Route exact path='/contabilidad/Movimientos/autorizacionPrimas'>
                <FullLayout>
                  <PremiumAuthorization />
                </FullLayout>
              </Route>
              <Route exact path='/contabilidad/Movimientos/autorizacionBonificacion'>
                <FullLayout>
                  <BonusSheetAuth />
                </FullLayout>
              </Route>
              <Route exact path='/contabilidad/Movimientos/autorizacionRegaliaPascual'>
                <FullLayout>
                  <RoyaltyPaymentAuth />
                </FullLayout>
              </Route>
              <Route exact path='/contabilidad/Movimientos/autorizacion/detalle'>
                <FullLayout>
                  <EmployeeDetailAuth />
                </FullLayout>
              </Route>
              <Route exact path='/contabilidad/autorizacionNomina/detalle'>
                <FullLayout>
                  <DetaliPayrollAuthorization />
                </FullLayout>
              </Route>
              <Route exact path='/contabilidad/Movimientos/revisionVacaciones'>
                <FullLayout>
                  <VacationsAuthorizations />
                </FullLayout>
              </Route>
              <Route exact path='/contabilidad/provisionNomina'>
                <FullLayout>
                  <PayrollProvision />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/provisionNomina/detalle'>
                <FullLayout>
                  <DetailPayrollProvision />
                </FullLayout>
              </Route>
              <Route exact path='/contabilidad/Movimientos/pagosConTC'>
                <FullLayout>
                  <PaymentsTC />
                </FullLayout>
              </Route>
              <Route exact path='/contabilidad/Movimientos/AutorizarPagosConTc'>
                <FullLayout>
                  <AuthorizePaymentsTC />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/tiposDeContratos'>
                <FullLayout>
                  <ContracTypes />
                </FullLayout>
              </Route>

              {/* /* -------------------------------------------------------------------------- */
              /*                               End Accounting:. Master files                     */
              /* -------------------------------------------------------------------------- */}

              <Route exact path='/nomina/dotaciones'>
                <FullLayout>
                  <Endowments />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/contratos'>
                <FullLayout>
                  <ListContracts />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/contratos/historialOtrosi'>
                <FullLayout>
                  <OtrosiHistory />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/dependencias'>
                <FullLayout>
                  <Dependencies />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/CrearContrato'>
                <FullLayout>
                  <CreateContract />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/tmPersonal'>
                <FullLayout>
                  <PeopleMasterTable />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/planillaDePrimas'>
                <FullLayout>
                  <PremiumSchedule />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/planillaRegaliasPascual'>
                <FullLayout>
                  <RoyaltyPayment />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/planillaDeBonificacion'>
                <FullLayout>
                  <BonusSheet />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/autorizacionPrimas'>
                <FullLayout>
                  <PremiumAuthorization />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/autorizacionCesantias'>
                <FullLayout>
                  <SeveranceAuthorization />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/planillaDeSeguridadSocial'>
                <FullLayout>
                  <SocialSecuritySchedule />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/planillaDeNomina'>
                <FullLayout>
                  <GeneratePayrollList />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/provisionesDeNomina'>
                <FullLayout>
                  <Provisions />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/planillaDeCesantias'>
                <FullLayout>
                  <SeverancePayroll />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/planillaDeCesantias/planilla'>
                <FullLayout>
                  <PayrollSeveranceGenerate />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/colaborador/nuevo'>
                <FullLayout>
                  <NewPartner />
                </FullLayout>
              </Route>

              {/* <Route exact path="/nomina/generarPlanillaDeNomina">
                <FullLayout>
                  <DetailPayrollTemplate />
                </FullLayout>
              </Route> */}

              <Route exact path='/nomina/colaborador/curriculo'>
                <FullLayout>
                  <AccordionForm />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/colaborador/detalleCurriculo'>
                <FullLayout>
                  <CurriculumDetail />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/cargos'>
                <FullLayout>
                  <PositionTable />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/nuevoCargo'>
                <FullLayout>
                  <NewPosition />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/planillaDePago'>
                <FullLayout>
                  <PaymentsTemplate />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/EvaluacionDeDesempeño'>
                <FullLayout>
                  <PerformanceAppraisal />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/EvaluacionDeDesempeño/CrearEvaluacion'>
                <FullLayout>
                  <SurveyPerf />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/EvaluacionDeDesempeño/Evaluacion'>
                <FullLayout>
                  <DetailSurveyPerf />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/EvaluacionDeDesempeño/Evaluacion/DetalleRespuesta'>
                <FullLayout>
                  <DetailAnswerSurvey />
                </FullLayout>
              </Route>
              {/* /* ------------------------- CONSULTS SECTION IN ACCOUNTING ------------------------ */}

              <Route exact path='/contabilidad/Consultas/CuentasPorPagar'>
                <FullLayout>
                  <AccountsPayable />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/Consultas/HistorialComprobantes'>
                <FullLayout>
                  <VoucherHistory />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/Consultas/FlujoDeCaja'>
                <FullLayout>
                  <CashFlow />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/Consultas/HistorialDeDiferidos'>
                <FullLayout>
                  <DeferralHistory />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/Consultas/CalendarioTributario'>
                <FullLayout>
                  <TaxCalendar />
                </FullLayout>
              </Route>
              {/* /* -------------------------------------------------------------------------- */
              /*                               Accounting:. Balance                          */
              /* -------------------------------------------------------------------------- */}

              <Route exact path='/contabilidad/Informes/DiarioGeneral'>
                <FullLayout>
                  <GeneralJournal />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/Informes/BalanceGeneral'>
                <FullLayout>
                  <GeneralBalance />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/Informes/MayorYBalance'>
                <FullLayout>
                  <MajorYBalance />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/Informes/LibroAuxiliar'>
                <FullLayout>
                  <SubsidiaryLedger />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/Informes/EstadoDeResultados '>
                <FullLayout>
                  <IncomeStatement />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/Informes/InformeDeSaldos'>
                <FullLayout>
                  <BalanceReport />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/crearPagosConTC'>
                <FullLayout>
                  <CreatePaymentsTC />
                </FullLayout>
              </Route>
              <Route exact path='/contabilidad/Movimientos/Comprobantes'>
                <FullLayout>
                  <Vauchers />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/seguridadYSalud/accidentesLaborales'>
                <FullLayout>
                  <WorkAccidents />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/seguridadYSalud/accidentesLaborales/detalle/:id'>
                <FullLayout>
                  <AccidentDetails />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/seguridadYSalud/accidentesLaborales/registrar'>
                <FullLayout>
                  <RegisterAccident />
                </FullLayout>
              </Route>

              {/* /* -------------------------------------------------------------------------- */
              /*                               End Accounting Petty Cash                          */
              /* -------------------------------------------------------------------------- */}

              <Route exact path='/contabilidad/Movimientos/RegistroDeComprobantes'>
                <FullLayout>
                  <VoucherRegistration />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/Movimientos/ComprobanteDeCierre'>
                <FullLayout>
                  <ClosingVoucher />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/Movimientos/ReemplazoDeCodigos'>
                <FullLayout>
                  <CodeReplace />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/Movimientos/HistorialDeReemplazos'>
                <FullLayout>
                  <ReplacementHistory />
                </FullLayout>
              </Route>

              <Route exact path='/contabilidad/Movimientos/ConfiguracionReemplazoDeCodigos'>
                <FullLayout>
                  <ConfigurationCodeReplace />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/seguridadYsalud/climaLaboral'>
                <FullLayout>
                  <WorkEnv />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/seguridadYsalud/registrarEncuesta'>
                <FullLayout>
                  <NewSurvey />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/seguridadYsalud/crearEncuesta'>
                <FullLayout>
                  <CreateSurvey />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/ReporteQuejas/'>
                <FullLayout>
                  <Complaint />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/examenesMedicos/'>
                <FullLayout>
                  <MedicalExam />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/examenesMedicosDetalles/'>
                <FullLayout>
                  <MedicalExamDetail />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/examenesMedicos/planificacionExamenes'>
                <FullLayout>
                  <PlanningMedicalExams />
                </FullLayout>
              </Route>
              <Route exact path='/contabilidad/Consultas/VerCalendarioTributario'>
                <FullLayout>
                  <ViewCalendar />
                </FullLayout>
              </Route>
              {/* administration receivable */}
              <Route exact path='/administracion/TMDeCartera/CartasCobro'>
                <FullLayout>
                  <CollectionLetter />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/TMDeCartera/Parametrizacion'>
                <FullLayout>
                  <CompanyParams />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/TMDeCartera/interes-mora'>
                <FullLayout>
                  <InterestPayment />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/TMDeCartera/deterioro-cartera'>
                <FullLayout>
                  <PortfolioDeterioration />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/TMDeCartera/provision-cartera'>
                <FullLayout>
                  <PortfolioProvision />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/TMDeCartera/rips'>
                <FullLayout>
                  <Rips />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/TMDeCartera/rips/detalle/:id'>
                <FullLayout>
                  <RipsDetail />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/TMDeCartera/tipos-cobro'>
                <FullLayout>
                  <PaymentTypes />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/TMDeCartera/rips/crear'>
                <FullLayout>
                  <CreateRips />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/TMDeCartera/datos-rips'>
                <FullLayout>
                  <RipsData />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/TMDeCartera/Parametrizacion/Detalle/:id'>
                <FullLayout>
                  <CompanyParamDetail />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/TMDeCartera/Parametrizacion/Crear'>
                <FullLayout>
                  <CreateCompanyParams />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/TMDeCartera/CrearCarta'>
                <FullLayout>
                  <CollectionCreate />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/TMDeCartera/DetalleCarta/:id'>
                <FullLayout>
                  <CollectDetails />
                </FullLayout>
              </Route>
              {/* end administration collection letters */}

              <Route exact path='/miNomina/miNomina/hojaDeVida'>
                <FullLayout>
                  <MyCv />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/ReporteQuejas/Detalle'>
                <FullLayout>
                  <ReportComplaintDetail />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/nuevoProcesoDisciplinario'>
                <FullLayout>
                  <NewDisciplinaryProcess />
                </FullLayout>
              </Route>

              <Route exact path='/miNomina/miNomina/registros/'>
                <FullLayout>
                  <MiNomina />
                </FullLayout>
              </Route>

              <Route exact path='/miNomina/miNomina/registros/detalleDeEvaluacion'>
                <FullLayout>
                  <SurveyDetail />
                </FullLayout>
              </Route>
              <Route
                exact
                path='/miNomina/miNomina/registros/detalleDeEvaluacion/responderEvaluacion'
              >
                <FullLayout>
                  <AnswerSurvey />
                </FullLayout>
              </Route>

              {/* ---------------------------CONFIGURACIÓN------------------------------ */}

              <Route exact path='/administracion/GestionDePermisos'>
                <FullLayout>
                  <PermissionManagement />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/GestionDePermisos/NuevoRol'>
                <FullLayout>
                  <NewRol />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/GestionDePermisos/NuevoRol/configuracion'>
                <FullLayout>
                  <RolSettings />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/GestionDePermisos/Detalle/'>
                <FullLayout>
                  <RolDetail />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/NovedadesAprobadas/horasExtra'>
                <FullLayout>
                  <OverTimeList />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/NovedadesAprobadas/permisos'>
                <FullLayout>
                  <Permissions />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/NovedadesAprobadas/PlanillaDeDevengados'>
                <FullLayout>
                  <NewsSheet />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/NovedadesAprobadas/PlanillaDeDevengados/:year/:month'>
                <FullLayout>
                  <NewSheetDetail />
                </FullLayout>
              </Route>

              <Route exact path='/miNomina/aprobarHorasExtra'>
                <FullLayout>
                  <MyPayrollOverTimeList />
                </FullLayout>
              </Route>
              <Route exact path='/miNomina/autorizarPermisos'>
                <FullLayout>
                  <AuthPermissions />
                </FullLayout>
              </Route>

              <Route exact path='/miNomina/aprobarIncapacidades'>
                <FullLayout>
                  <AproveInhabilities />
                </FullLayout>
              </Route>

              <Route exact path='/nomina/NovedadesAprobadas/vacaciones'>
                <FullLayout>
                  <Vacations />
                </FullLayout>
              </Route>
              <Route exact path='/miNomina/autorizarVacaciones'>
                <FullLayout>
                  <VacationsMyPayRoll />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/NovedadesAprobadas/Incapacidades'>
                <FullLayout>
                  <Inhabilities />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/ProcesosDisciplinarios/Detalle'>
                <FullLayout>
                  <ProcessDetail />
                </FullLayout>
              </Route>
              <Route exact path='/miNomina/miNomina/solicitudes'>
                <FullLayout>
                  <Requests />
                </FullLayout>
              </Route>
              {/* /* -------------------------------------------------------------------------- */
              /*                              MANAGEMENT ROUTES                             */
              /* -------------------------------------------------------------------------- */}
              <Route exact path='/gerencia/dashboard/indicadoresGlobales'>
                <FullLayout>
                  <Dashboard />
                </FullLayout>
              </Route>
              <Route exact path='/gerencia/indicadores/compras'>
                <FullLayout>
                  <Purchases />
                </FullLayout>
              </Route>
              <Route exact path='/gerencia/indicadores/cartera'>
                <FullLayout>
                  <Wallet />
                </FullLayout>
              </Route>
              <Route exact path='/gerencia/indicadores/contabilidad'>
                <FullLayout>
                  <Accounting />
                </FullLayout>
              </Route>
              <Route exact path='/gerencia/indicadores/facturacion'>
                <FullLayout>
                  <Billing />
                </FullLayout>
              </Route>
              <Route exact path='/gerencia/indicadores/nomina'>
                <FullLayout>
                  <PayrollReport />
                </FullLayout>
              </Route>
              <Route exact path='/gerencia/indicadores/tesoreria'>
                <FullLayout>
                  <TreasuryReport />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/poblacion'>
                <FullLayout>
                  <TablePopulation />
                </FullLayout>
              </Route>
              <Route exact path='/contabilidad/terceros'>
                <FullLayout>
                  <ThirdPartyAccounting />
                </FullLayout>
              </Route>
              <Route
                exact
                path='/contabilidad/terceros/proveedores'
                history={useHistory}
                render={props => (
                  <FullLayout>
                    <AccountingProviders {...props} />
                  </FullLayout>
                )}
              ></Route>
              <Route exact path='/contabilidad/terceros/proveedores/persona-juridica'>
                <FullLayout>
                  <AccountingProviderLegal />
                </FullLayout>
              </Route>
              <Route exact path='/contabilidad/terceros/proveedores/persona-natural'>
                <FullLayout>
                  <AccountingProviderDetail />
                </FullLayout>
              </Route>
              <Route exact path='/nomina/liquidacion'>
                <FullLayout>
                  <Settlement />
                </FullLayout>
              </Route>
              <Route exact path='/contabilidad/terceros/empleados'>
                <FullLayout>
                  <PeopleMasterTable />
                </FullLayout>
              </Route>
              <Route
                exact
                path='/contabilidad/terceros/clientesEmpresas'
                render={props => (
                  <FullLayout>
                    <TableCorporateClients {...props} />
                  </FullLayout>
                )}
              ></Route>

              <Route exact path='/administracion/definicionDeMovimientos' history={useHistory}>
                <FullLayout>
                  <ConfigMovesNew />
                </FullLayout>
              </Route>
              <Route exact path='/contabilidad/terceros/empleados/detalleCurriculo'>
                <FullLayout>
                  <CurriculumDetail />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/Facturacion/ResolucionesFacturacion'>
                <FullLayout>
                  <BillingResolution />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/Facturacion/ResolucionesFacturacion/nueva'>
                <FullLayout>
                  <NewResolution />
                </FullLayout>
              </Route>

              <Route exact path='/administracion/configuracionContable-nueva'>
                <FullLayout>
                  <NewProvision />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/TMDeCartera/EdadesPorCobrar'>
                <FullLayout>
                  <ReceivableAges />
                </FullLayout>
              </Route>
              <Route exact path='/administracion/TMDeCartera/EdadesPorCobrar/nueva'>
                <FullLayout>
                  <NewReceivAge />
                </FullLayout>
              </Route>
              <Route exact path='/ordenamientos/ordenes'>
                <FullLayout>
                  <Order />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/especialidades'>
                <FullLayout>
                  <Specialties />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/sintomas'>
                <FullLayout>
                  <Symptom />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/medicamentos'>
                <FullLayout>
                  <Medicines />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/medicamentos/nuevo'>
                <FullLayout>
                  <NewMedicine />
                </FullLayout>
              </Route>

              <Route exact path='/asistencial/CombosDeCirugia'>
                <FullLayout>
                  <SurgeryCombos />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/gruposDeAtencion'>
                <FullLayout>
                  <CareGroups />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/configuracionObservaciones'>
                <FullLayout>
                  <SettingComments />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/configuracionAgenda'>
                <FullLayout>
                  <ScheduleSettings />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/parametrizacionObservaciones'>
                <FullLayout>
                  <ParameterCommets />
                </FullLayout>
              </Route>

              <Route exact path='/asistencial/Justificaciones'>
                <FullLayout>
                  <NonPosDrugsJust />
                </FullLayout>
              </Route>

              <Route exact path='/asistencial/tmAdmisiones/motivosDeAnulacion'>
                <FullLayout>
                  <AnulationReasons />
                </FullLayout>
              </Route>

              <Route exact path='/asistencial/tmAdmisiones/asignacionDeSedes'>
                <FullLayout>
                  <AssignmentLocations />
                </FullLayout>
              </Route>

              <Route exact path='/asistencial/tmAdmisiones/configuracion'>
                <FullLayout>
                  <TmAdmissionSettings />
                </FullLayout>
              </Route>

              <Route exact path='/asistencial/tmAdmisiones/consentimientos'>
                <FullLayout>
                  <Consents />
                </FullLayout>
              </Route>

              <Route exact path='/asistencial/tmAdmisiones/consentimientos/:id'>
                <FullLayout>
                  <ConsentsActions />
                </FullLayout>
              </Route>

              <Route exact path='/admision/abonoCirugia'>
                <FullLayout>
                  <SurgeryPayment />
                </FullLayout>
              </Route>

              <Route exact path='/admision/anulacionDeAbono'>
                <FullLayout>
                  <SurgeryPaymentCancellation />
                </FullLayout>
              </Route>

              <Route exact path='/admision/listadoDePacientes'>
                <FullLayout>
                  <PatientList />
                </FullLayout>
              </Route>

              <Route exact path='/admision/autorizarSolicitudes'>
                <FullLayout>
                  <GeneralsAuthorizedRequests />
                </FullLayout>
              </Route>

              <Route exact path='/admision/cierreDiario'>
                <FullLayout>
                  <DailyClosing />
                </FullLayout>
              </Route>

              <Route exact path='/admision/consultaOportunidad'>
                <FullLayout>
                  <QueriesOpportunity />
                </FullLayout>
              </Route>

              <Route exact path='/admision/detalleCierreDiario'>
                <FullLayout>
                  <DailyClosingDetails />
                </FullLayout>
              </Route>

              <Route exact path='/admision/consultaOrdenes'>
                <FullLayout>
                  <QueriesOrder />
                </FullLayout>
              </Route>

              <Route exact path='/admision/consultaOcupacion'>
                <FullLayout>
                  <QueriesOccupation />
                </FullLayout>
              </Route>

              <Route exact path='/admision/ordenesAnuladas'>
                <FullLayout>
                  <GeneralsOrdersCanceled />
                </FullLayout>
              </Route>

              <Route exact path='/admision/historialCierres'>
                <FullLayout>
                  <HistoryClosing />
                </FullLayout>
              </Route>

              <Route exact path='/admision/autorizarAnulaciones'>
                <FullLayout>
                  <Cancellations />
                </FullLayout>
              </Route>

              <Route exact path='/admision/reporteCaja'>
                <FullLayout>
                  <DailyActivity />
                </FullLayout>
              </Route>

              <Route exact path='/admision/ordenesProveedores'>
                <FullLayout>
                  <SupplierOrders />
                </FullLayout>
              </Route>

              <Route exact path='/admision/ordenesProveedores/newOrderProvider'>
                <FullLayout>
                  <NewOrderProvider />
                </FullLayout>
              </Route>

              <Route exact path='/admision/ordenLenteContacto'>
                <FullLayout>
                  <LensOrder />
                </FullLayout>
              </Route>

              <Route exact path='/admision/ordenAbono'>
                <FullLayout>
                  <PaymentOrderAdmision />
                </FullLayout>
              </Route>

              <Route exact path='/ordenamientos/Consultas'>
                <FullLayout>
                  <Consult />
                </FullLayout>
              </Route>

              <Route exact path='/ordenamientos/Consultas/Medicamentos'>
                <FullLayout>
                  <MedicationHistory />
                </FullLayout>
              </Route>

              <Route exact path='/ordenamientos/Consultas/Servicios'>
                <FullLayout>
                  <ServiceHistory />
                </FullLayout>
              </Route>

              <Route exact path='/ordenamientos/Consultas/Referencias'>
                <FullLayout>
                  <ReferenceHistory />
                </FullLayout>
              </Route>

              <Route exact path='/ordenamientos/Consultas/Incapacidades'>
                <FullLayout>
                  <DisabilityHistory />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/CombosDeCirugia/nuevo'>
                <FullLayout>
                  <NewCombo />
                </FullLayout>
              </Route>
              <Route exact path='/ordenamientos/autorizarMedicamento'>
                <FullLayout>
                  <AuthMedicine />
                </FullLayout>
              </Route>
              <Route exact path='/ordenamientos/autorizarServicio'>
                <FullLayout>
                  <AuthServices />
                </FullLayout>
              </Route>
              <Route exact path='/ordenamientos/autorizarIncapacidad'>
                <FullLayout>
                  <AuthInhabilities />
                </FullLayout>
              </Route>
              {/* <Route exact path="/ordenamientos/formulas">
                <FullLayout>
                  <RenderMedicalFormulasPdf />
                </FullLayout>
              </Route>*/}
              <Route exact path='/asistencial/tmHistoriaClinica/serviciosMedicos'>
                <FullLayout>
                  <MedicalServices />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/tmHistoriaClinica/cie10'>
                <FullLayout>
                  <Cie10View />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/tmHistoriaClinica/cie10Extendida'>
                <FullLayout>
                  <Cie10ViewExtended />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/tmHistoriaClinica/situacionesInesperadas'>
                <FullLayout>
                  <UnexpectedSituations />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/tmHistoriaClinica/reasignacionLectura'>
                <FullLayout>
                  <ReassignmentReading />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/tmHistoriaClinica/ayudasDiagnosticas'>
                <FullLayout>
                  <DiagnosticAids />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/tmHistoriaClinica/lotesAsistenciales'>
                <FullLayout>
                  <AssistanceLots />
                </FullLayout>
              </Route>
              <Route
                exact
                path='/asistencial/tmHistoriaClinica/ayudasDiagnosticas/plantillaInforme'
              >
                <FullLayout>
                  <ServiceReport />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/tmHistoriaClinica/seguridadPaciente'>
                <FullLayout>
                  <PatientSafety />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/tmHistoriaClinica/serviciosMedicos/parametrizacion'>
                <FullLayout>
                  <ServicesParam />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/tmHistoriaClinica/parametrizacionTiempos'>
                <FullLayout>
                  <TimeParam />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/tmHistoriaClinica/requerimientosDilatacion'>
                <FullLayout>
                  <DilatationRequirements />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/tmHistoriaClinica/conceptosMedicos'>
                <FullLayout>
                  <MedicalConcept />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/tmHistoriaClinica/textosPredefinidos'>
                <FullLayout>
                  <PredefinedText />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/tmHistoriaClinica/textosPredefinidosNoIns'>
                <FullLayout>
                  <PredefinedTextNoIns />
                </FullLayout>
              </Route>
              {/* -------------- SURGERY ---------------------------- */}
              <Route exact path='/asistencial/tmCirugia/tiposInsumos'>
                <FullLayout>
                  <SuppliesTypes />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/tmCirugia/libroCirugia'>
                <FullLayout>
                  <SurgeryBooks />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/tmCirugia/trasladosQuirofano'>
                <FullLayout>
                  <TransferSurgery />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/tmCirugia/procedimientosQuirurgicos'>
                <FullLayout>
                  <SurgeryProcedures />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/tmCirugia/parametrosGenerales'>
                <FullLayout>
                  <GeneralParameters />
                </FullLayout>
              </Route>
              <Route exact path='/ordenamientos/informeDeReferencia'>
                <FullLayout>
                  <ReferencesReports />
                </FullLayout>
              </Route>
              <Route exact path='/ordenamientos/medicamentosOrdenados'>
                <FullLayout>
                  <FormulatedMedicationReports />
                </FullLayout>
              </Route>
              <Route exact path='/ordenamientos/serviciosOrdenados'>
                <FullLayout>
                  <FormulatedServiceReports />
                </FullLayout>
              </Route>
              {/* /* -------------------------------------------------------------------------- */
              /*                                           Telemedicine                        */
              /* -------------------------------------------------------------------------- */}

              <Route exact path='/telemedicina/ConsultarCitas/'>
                <FullLayout>
                  <ConsultAppointments />
                </FullLayout>
              </Route>
              <Route exact path='/telemedicina/historialPagos/'>
                <FullLayout>
                  <PayHistory />
                </FullLayout>
              </Route>
              <Route exact path='/telemedicina/configuracion/'>
                <FullLayout>
                  <TelemedicineSetup />
                </FullLayout>
              </Route>
              <Route exact path='/telemedicina/medicosAgendas/'>
                <FullLayout>
                  <DoctorsDiaries />
                </FullLayout>
              </Route>
              <Route exact path='/telemedicina/detalleHorario/'>
                <FullLayout>
                  <ScheduleDetail />
                </FullLayout>
              </Route>
              <Route exact path='/telemedicina/informeCitas/'>
                <FullLayout>
                  <CitationReport />
                </FullLayout>
              </Route>
              <Route exact path='/telemedicina/detalleCita/'>
                <FullLayout>
                  <DetailAppointment />
                </FullLayout>
              </Route>
              {/* /* -------------------------------------------------------------------------- */
              /*                                           Appointment                       */
              /* -------------------------------------------------------------------------- */}
              <Route exact path='/asistencial/ListadoPacientes'>
                <FullLayout>
                  <ListPatient />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/DetallePacientes/:id'>
                <FullLayout>
                  <DetailPatient />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/ImportanciaPacientes'>
                <FullLayout>
                  <ImportancePatient />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/CombosServicios'>
                <FullLayout>
                  <ServicesCombos />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/MotivoAnulacion'>
                <FullLayout>
                  <AnulationReason />
                </FullLayout>
              </Route>
              <Route exact path='/asistencial/AsignacionRecursos'>
                <FullLayout>
                  <ResourceAssignment />
                </FullLayout>
              </Route>
              <Route exact path='/citas/ListadoDeCupos'>
                <FullLayout>
                  <AdditionalQuota />
                </FullLayout>
              </Route>
              <Route exact path='/citas/pdf'>
                <FullLayout>
                  <RenderAppPdf />
                </FullLayout>
              </Route>
              <Route exact path='/citas/AutorizacionDeDescuentos'>
                <FullLayout>
                  <DiscountAuthorisation />
                </FullLayout>
              </Route>
              <Route exact path='/citas/SolicitarDescuentos'>
                <FullLayout>
                  <RequestDiscount />
                </FullLayout>
              </Route>
              {/* <Route path='*' component={PageNotFound} /> */}
              {/* /* -------------------------------------------------------------------------- */
              /*                                Appointments                                   */
              /* -------------------------------------------------------------------------- */}
              <Route exact path='/citas/RegistroCita/'>
                <FullLayout>
                  <RegisterAppointment />
                </FullLayout>
              </Route>
              <Route exact path='/citas/ListadoDeCitas'>
                <FullLayout>
                  <ConsultApp />
                </FullLayout>
              </Route>
              <Route exact path='/cuentasMedicas/Contratos'>
                <FullLayout>
                  <MedicalContracts />
                </FullLayout>
              </Route>
              <Route exact path='/cuentasMedicas/Contratos/:contractId'>
                <FullLayout>
                  <LinkServices />
                </FullLayout>
              </Route>
              <Route exact path='/cuentasMedicas/Liquidacion/Liquidaciones'>
                <FullLayout>
                  <Settlements />
                </FullLayout>
              </Route>
              <Route exact path='/cuentasMedicas/Liquidacion/Historial'>
                <FullLayout>
                  <SettlementHistory />
                </FullLayout>
              </Route>
              <Route exact path='/cuentasMedicas/Contratos/asignar-honorarios/:idContract/:idDoctor'>
                <FullLayout>
                  <AssignFees />
                </FullLayout>
              </Route>
              <Route exact path='/citas/InformeDeRendimiento'>
                <FullLayout>
                  <ReportMenuTabs />
                </FullLayout>
              </Route>
              {/* --------------------------------Admissions Module------------------------------------ */}
              <Route exact path='/admision/admitirPacientes'>
                <FullLayout>
                  <PatientAdmission />
                </FullLayout>
              </Route>

              <Route exact path='/admision/NotasContables/CrearNota'>
                <FullLayout>
                  <CreateNote version={'admission'} />
                </FullLayout>
              </Route>

              <Route exact path='/admision/NotasContables/Historial'>
                <FullLayout>
                  <NotesHistoryAdmissions />
                </FullLayout>
              </Route>

              {/* //================ */}

              <Route exact path='/admision/admitirPacientes/informacionBasica'>
                <ValidationRouteAdminPatient>
                  <FullLayout>
                    <PatientBasicInfo />
                  </FullLayout>
                </ValidationRouteAdminPatient>
              </Route>

              <Route exact path='/admision/admitirPacientes/informacionAdicional'>
                <ValidationRouteAdminPatient>
                  <FullLayout>
                    <PatientInfoAdditional />
                  </FullLayout>
                </ValidationRouteAdminPatient>
              </Route>

              <Route exact path='/admision/admitirPacientes/recaudos'>
                <ValidationRouteAdminPatient>
                  <FullLayout>
                    <PatientInfoCollected />
                  </FullLayout>
                </ValidationRouteAdminPatient>
              </Route>

              <Route exact path='/admision/admitirPacientes/recaudo'>
                <FullLayout>
                  <PatientAdmission />
                </FullLayout>
              </Route>
              {/* //================ */}

              <Route exact path='/admision/ordenesServicios'>
                <FullLayout>
                  <ServicesOrders />
                </FullLayout>
              </Route>

              <Route exact path='/admision/ordenesServicios/:id'>
                <FullLayout>
                  <ServiceOrderDetail />
                </FullLayout>
              </Route>

              <Route exact path='/admision/DetallePacientes'>
                <FullLayout>
                  <DetailPatientAdmision />
                </FullLayout>
              </Route>

              <Route exact path='/admision/vueltos'>
                <FullLayout>
                  <AdmissionGeneralBalance />
                </FullLayout>
              </Route>

              <Route exact path='/admision/vueltos/:id'>
                <FullLayout>
                  <AdmissionGeneralBalanceDetail />
                </FullLayout>
              </Route>

              <Route exact path='/cuentasMedicas/parametrizacionDeTiempos'>
                <FullLayout>
                  <ParameterizationOfTimes />
                </FullLayout>
              </Route>

              <Route exact path='/administracion/tm-treasury/payment-types'>
                <FullLayout>
                  <PaymentTypesTreasury />
                </FullLayout>
              </Route>

              <Route exact path='/administracion/tm-treasury/types-costs-tc'>
                <FullLayout>
                  <TypesCostsTc />
                </FullLayout>
              </Route>

              <Route exact path='/administracion/tm-treasury/bank-note-types'>
                <FullLayout>
                  <BankNoteTypes/>
                </FullLayout>
              </Route>

              <Route exact path='/administracion/tm-treasury/prioritize-bills-to-pay'>
                <FullLayout>
                  <PrioritizeBillsToPay/>
                </FullLayout>
              </Route>

              <Route exact path='/logout/:id'>
                <Logout />
              </Route>

              <Route path='/https://' />

              <Route exact path="/*">
                <Layout404 />
              </Route>
              </Switch>
            </PrivateRoute>
          </Switch>
        </Provider>
      </Router>
    );
  }
}

export default Routes;
