import { AddCircleOutline, Delete, KeyboardArrowDown } from '@material-ui/icons';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import ReactSelect from 'react-select';

import { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useGetMethod, usePostMethod } from '../../../Hooks';

import { customSwaltAlertAsistencial, loader } from '../../../helpers';

import OrdTable from '../../../OrderingModule/OrderingComponents/OrdTable';
import AddService from '../AddService/AddService';

import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';

const SERVICES_HEADER = [
  { title: '', className: 'text-start' },
  { title: 'Tipo de servicio', className: 'text-start' },
  { title: 'Servicios', className: 'col-3 text-center' },
];

const INITIAL_SCHEDULE_PAYLOAD = {
  startTime: '',
  endTime: '',
  site: null,
  type: null,
  room: null,
  helper: null,
  allowExtraQuota: false,
  extraQuotaQuantity: '',
};

export default function DayForm({
  schedule = {},
  editPayload = null,
  onCancelForm = () => null,
  onSuccess = () => null,
  onTouchField = () => null,
  isFieldTouched,
  doctor,
  day,
}) {
  const accountId = useSelector(store => store.loginReducer.currentAccount.id);
  const userId = useSelector(store => store.loginReducer.user_data.id);
  const token = useSelector(store => store.loginReducer.Authorization);

  const [serviceTypes, setServiceTypes] = useState(null);
  const [groupsPayload, setGroupsPayload] = useState([]);
  const [isOpenAddService, setIsOpenAddService] = useState(false);
  const [schedulePayload, setSchedulePayload] = useState(INITIAL_SCHEDULE_PAYLOAD);

  const { load: createScheduleLoader, trigger: createScheduleMethod } = usePostMethod();

  const {
    results: siteOptionsResults,
    load: siteOptionsLoader,
    trigger: getSiteOptions,
  } = useGetMethod();

  const {
    results: typeOptionsResults,
    load: typeOptionsLoader,
    trigger: getTypeOptions,
  } = useGetMethod();

  const {
    results: locationOptionsResults,
    load: locationOptionsLoader,
    trigger: getLocationOptions,
  } = useGetMethod();

  const {
    load: doctorsOptionsLoader,
    trigger: getDoctorOptions,
    results: getDoctorResults,
  } = useGetMethod();

  const { load: careGroupOptionsLoader, trigger: getCareGroupOptions } = useGetMethod();

  const siteSelectOptions = useMemo(() => {
    if (siteOptionsResults?.results) {
      return [
        { label: 'Seleccionar...', value: null },
        ...siteOptionsResults.results.map(site => ({
          label: site.site,
          value: site.siteId,
        })),
      ];
    }
    return [];
  }, [siteOptionsResults?.results]);

  const locationSelectOptions = useMemo(() => {
    if (locationOptionsResults?.results) {
      return [
        { label: 'Seleccionar...', value: null },
        ...locationOptionsResults.results.map(site => ({
          label: site.name,
          value: site.id,
        })),
      ];
    }
    return [];
  }, [locationOptionsResults?.results]);

  const roomsTypeOptions = useMemo(() => {
    if (typeOptionsResults?.results) {
      return [
        { label: 'Seleccionar...', value: null },
        ...typeOptionsResults.results.map(site => ({
          label: site.name,
          value: site.id,
        })),
      ];
    }
    return [];
  }, [typeOptionsResults?.results]);

  const doctorSelectOptions = useMemo(() => {
    if (getDoctorResults?.results) {
      return [
        { label: 'Seleccionar...', value: null },
        ...getDoctorResults.results.map(site => ({
          label: site.mdName,
          value: site.mdId,
        })),
      ];
    }
    return [];
  }, [getDoctorResults?.results]);

  useEffect(() => {
    if (editPayload) {
      setSchedulePayload({
        startTime: editPayload.startTime.slice(0, -3),
        endTime: editPayload.endTime.slice(0, -3),
        allowExtraQuota: editPayload.allowExtraQuota,
        extraQuotaQuantity: editPayload.extraQuotaQuantity,
        helper: editPayload.helper,
        room: editPayload.room,
        site: editPayload.site,
        type: editPayload.type,
      });
      setServiceTypes(editPayload.services);
      setGroupsPayload(editPayload.attGroups);
    }
  }, [editPayload]);

  useEffect(() => {
    void getSiteOptions({
      url: '/medical/generals/site/',
      objFilters: { eaccount: accountId, modality: schedule.modality, status: 'enabled' },
      token: token,
    });

    void getTypeOptions({
      url: '/medical/schedules/consultingRoomType/',
      objFilters: { eaccount: accountId, status: 'enabled' },
      token: token,
    });

    void getDoctorOptions({
      token,
      url: '/medical/generals/medical/',
      objFilters: { eaccount: accountId },
    });
  }, [getSiteOptions, getTypeOptions, getDoctorOptions, accountId, token, schedule.modality]);

  useEffect(() => {
    if (serviceTypes) {
      const services = Object.values(serviceTypes).flat();
      const typeIdList = services.map(serv => serv.ctypeId);

      if (typeIdList.length) {
        void getCareGroupOptions({
          url: '/medical/attentionGroup/',
          objFilters: { eaccount: accountId, status: 'enabled', ctypeId: typeIdList },
          token: token,
          succesAction: response => {
            if (response?.results) {
              const formatted = response.results.map(({ id, name }) => ({
                id,
                name,
                value: '',
              }));
              setGroupsPayload(state => _.unionBy(state, formatted, 'id'));
            }
          },
        });
      } else {
        setGroupsPayload([]);
      }
    }
  }, [getCareGroupOptions, accountId, token, serviceTypes]);

  useEffect(() => {
    if (schedulePayload.site && schedulePayload.type) {
      void getLocationOptions({
        url: '/medical/schedules/consultingRooms/',
        objFilters: { crtyId: schedulePayload.type, siteId: schedulePayload.site, enabled: 1 },
        token: token,
      });
    }
  }, [schedulePayload.site, schedulePayload.type, token, getLocationOptions]);

  const onClearState = () => {
    setServiceTypes(null);
    setGroupsPayload([]);
    setSchedulePayload(INITIAL_SCHEDULE_PAYLOAD);
  };

  const onSaveServices = (services = []) => {
    onTouchField(true);

    const serviceTypesResult = _.groupBy(services, service => service.serviceType);
    const keys = Object.keys(serviceTypesResult);

    let updatedServiceTypes = { ...serviceTypes };

    for (const key of keys) {
      if (_.has(updatedServiceTypes, key)) {
        const services = updatedServiceTypes[key];
        const newServices = _.differenceBy(serviceTypesResult[key], services, 'id');
        updatedServiceTypes[key] = [...services, ...newServices];
      } else {
        updatedServiceTypes[key] = serviceTypesResult[key];
      }
    }
    setServiceTypes(updatedServiceTypes);
  };

  const onRemoveService = (key, serviceId, typeId) => {
    onTouchField(true);

    const newState = {
      ...serviceTypes,
      [key]: serviceTypes[key].filter(
        serv => serv.ctypeId !== typeId || serv.serviceId !== serviceId,
      ),
    };

    if (!newState[key].length) {
      delete newState[key];
    }

    setServiceTypes(newState);
  };

  const onSaveSchedule = () => {
    if (!schedule.schName || !schedule.schDescription || !schedule.schColor) {
      void customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe rellenar todos los campos obligatorios',
        showCancelButton: false,
      });
      return;
    }

    if (
      !schedulePayload.startTime ||
      !schedulePayload.endTime ||
      !schedulePayload.site ||
      !schedulePayload.type ||
      !schedulePayload.room
    ) {
      void customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe rellenar todos los campos obligatorios',
        showCancelButton: false,
      });
      return;
    }

    const services = Object.values(serviceTypes ?? {})
      .flat()
      .map(service => ({
        srvId: service.serviceId,
        groupId: service.groupId,
        ctypeId: service.ctypeId,
        maxDate: '',
      }));

    if (!services.length) {
      void customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe agregar por lo menos un servicio',
        showCancelButton: false,
      });
      return;
    }

    const groups = groupsPayload.map(group => ({
      percentage: group.value,
      attGroupId: group.id,
      attGroupName: group.name,
      services: services.filter(serv => serv.groupId === group.id).map(serv => serv.srvId),
    }));

    if (!groups.some(group => group.percentage)) {
      void customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe agregar los porcentajes en los grupos de atención',
        showCancelButton: false,
      });
      return;
    }

    const totalPercentage = groups.reduce((acc, current) => {
      return acc + current.percentage;
    }, 0);

    if (totalPercentage !== 100) {
      void customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Verifique porcentajes de grupos de atención, la sumatoria de los porcentajes debe ser igual a 100%',
        showCancelButton: false,
      });
      return;
    }

    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Está seguro?',
      text: `Se ${!schedule?.schId ? 'creará' : 'actualizará'} la agenda: ${schedule.schName}`,
      confirmButtonText: 'Sí, Continuar',
    }).then(response => {
      if (response.isConfirmed) {
        const payload = {
          ...schedule,
          schId: !editPayload || editPayload?.isCreating ? undefined : schedule?.schId,
          eaccount: accountId,
          siteId: schedulePayload.site,
          allowExtraQuota: schedulePayload.allowExtraQuota,
          schExtraQuota: schedulePayload.extraQuotaQuantity || undefined,
          typeConRoomId: schedulePayload.type,
          conRoomId: schedulePayload.room,
          beginsAt: schedulePayload.startTime,
          endsAt: schedulePayload.endTime,
          dow: [day],
          doctorId: doctor,
          assistantId: schedulePayload.helper ?? undefined,
          userId: userId,
          services: services,
          attGroup: groups,
          validate_sch: true,
        };

        void createScheduleMethod({
          url: '/medical/schedules/',
          method: !editPayload || editPayload?.isCreating ? 'POST' : 'PUT',
          body: payload,
          token: token,
          succesAction: results => {
            customSwaltAlertAsistencial({
              icon: 'success',
              title: 'Creado exitosamente',
              text: results.message,
              showCancelButton: false,
            });
          },
          doAfterSuccess: () => {
            onTouchField(false);
            onClearState();
            onSuccess();
          },
          doAfterException: error => {
            customSwaltAlertAsistencial({
              icon: 'warning',
              title: 'Intenta de nuevo',
              text: error.message,
              showCancelButton: false,
            });
          },
        });
      }
    });
  };

  const renderComponent = () => {
    const selectedServiceIds = Object.values(serviceTypes ?? {}).flat().map(service => service.serviceId);
    return (
      <>
        {(siteOptionsLoader ||
          typeOptionsLoader ||
          locationOptionsLoader ||
          doctorsOptionsLoader ||
          careGroupOptionsLoader ||
          createScheduleLoader) &&
          loader}
        <div
          className={`card ${tableStyles.app_card_custom} my-3 p-3`}
          style={{ cursor: 'default' }}
        >
          <b className={tableStyles.tlnTextPrimary}>Horario</b>
          <hr />
          <div className='row align-items-end gy-3'>
            <div className='col-lg-3'>
              <b className={tableStyles.ordDarkBlueText}>
                Hora desde
                <span className='text-danger'>*</span>
              </b>
              <input
                type='time'
                className='ord-roundInput w-100'
                value={schedulePayload.startTime}
                onChange={({ target }) => {
                  onTouchField(true);
                  const payload = { ...schedulePayload, startTime: target.value };
                  setSchedulePayload(payload);
                }}
              />
            </div>
            <div className='col-lg-3'>
              <b className={tableStyles.ordDarkBlueText}>
                Hora hasta
                <span className='text-danger'>*</span>
              </b>
              <input
                type='time'
                className='ord-roundInput w-100'
                value={schedulePayload.endTime}
                onChange={({ target }) => {
                  onTouchField(true);
                  const payload = { ...schedulePayload, endTime: target.value };
                  setSchedulePayload(payload);
                }}
              />
            </div>
            <div className='col-lg-3'>
              <b className={tableStyles.ordDarkBlueText}>
                Sede
                <span className='text-danger'>*</span>
              </b>
              <ReactSelect
                noOptionsMessage={() => 'No hay datos'}
                options={siteSelectOptions}
                className='text-secondary '
                placeholder='Seleccionar... '
                styles={ordCustomSelect}
                value={siteSelectOptions.find(site => site.value === schedulePayload.site)}
                onChange={option => {
                  onTouchField(true);
                  const payload = {
                    ...schedulePayload,
                    site: option.value,
                    type: null,
                    room: null,
                    helper: null,
                  };
                  setSchedulePayload(payload);
                }}
              />
            </div>
            <div className='col-lg-3'>
              <b className={tableStyles.ordDarkBlueText}>
                Tipo
                <span className='text-danger'>*</span>
              </b>
              <ReactSelect
                noOptionsMessage={() => 'No hay datos'}
                options={roomsTypeOptions}
                className='text-secondary '
                placeholder='Seleccionar... '
                styles={ordCustomSelect}
                value={roomsTypeOptions.find(site => site.value === schedulePayload.type)}
                onChange={option => {
                  onTouchField(true);
                  const payload = { ...schedulePayload, type: option.value };
                  setSchedulePayload(payload);
                }}
              />
            </div>
            <div className='col-lg-3'>
              <b className={tableStyles.ordDarkBlueText}>
                Lugar
                <span className='text-danger'>*</span>
              </b>
              <ReactSelect
                noOptionsMessage={() => 'No hay datos'}
                options={locationSelectOptions}
                className='text-secondary '
                placeholder='Seleccionar... '
                value={locationSelectOptions.find(loc => loc.value === schedulePayload.room)}
                styles={ordCustomSelect}
                onChange={option => {
                  onTouchField(true);
                  const payload = {
                    ...schedulePayload,
                    room: option.value,
                  };
                  setSchedulePayload(payload);
                }}
              />
            </div>
            <div className='col-lg-3'>
              <b className={tableStyles.ordDarkBlueText}>Ayudante</b>
              <ReactSelect
                noOptionsMessage={() => 'No hay datos'}
                options={doctorSelectOptions}
                className='text-secondary '
                placeholder='Seleccionar... '
                styles={ordCustomSelect}
                value={doctorSelectOptions.find(doctor => doctor.value === schedulePayload.helper)}
                onChange={option => {
                  onTouchField(true);
                  const payload = {
                    ...schedulePayload,
                    helper: option.value,
                  };
                  setSchedulePayload(payload);
                }}
              />
            </div>
            <div className='col-lg-3'>
              <label className='py-2'>
                <input
                  type='checkbox'
                  className={`${tableStyles.checkClearBlue} ${tableStyles.appCheckInput}  ${tableStyles.appCheckClear}  form-check-input p1 `}
                  checked={schedulePayload.allowExtraQuota}
                  onChange={({ target }) => {
                    onTouchField(true);
                    const payload = {
                      ...schedulePayload,
                      allowExtraQuota: target.checked,
                      extraQuotaQuantity: '',
                    };
                    setSchedulePayload(payload);
                  }}
                />
                <span className='ms-2'>Cupos adicionales</span>
              </label>
            </div>
            <div className='col-lg-3'>
              <b className={tableStyles.ordDarkBlueText}>
                Cantidad de cupos
                {schedulePayload.allowExtraQuota && <span className='text-danger'>*</span>}
              </b>
              <NumberFormat
                className='ord-roundInput w-100'
                allowNegative={false}
                decimalScale={0}
                disabled={!schedulePayload.allowExtraQuota}
                value={schedulePayload.extraQuotaQuantity}
                onChange={() => {
                  onTouchField(true);
                }}
                onValueChange={values => {
                  const payload = { ...schedulePayload, extraQuotaQuantity: values.floatValue };
                  setSchedulePayload(payload);
                }}
              />
            </div>
          </div>
        </div>

        <div
          className={`card ${tableStyles.app_card_custom} my-3 p-3`}
          style={{ cursor: 'default' }}
        >
          <div className='d-flex align-items-center justify-content-between'>
            <b className={tableStyles.tlnTextPrimary}>Servicios relacionados</b>
            <button
              className={`d-flex align-items-center ordGroupAddButton fw-bold text-muted ${tableStyles.hoverOrdAquaMarine}`}
              onClick={() => setIsOpenAddService(true)}
              disabled={!schedulePayload.site}
            >
              <span className='me-2'>Agregar servicios</span>
              <AddCircleOutline />
            </button>
          </div>

          <OrdTable
            headers={SERVICES_HEADER}
            hasChildren={true}
            style={{ borderRadius: '10px', overflow: 'hidden' }}
          >
            {serviceTypes && Object.keys(serviceTypes).length ? (
              Object.entries(serviceTypes).map(([key, value], i) => (
                <Fragment key={key}>
                  <tr>
                    <td width={40}>
                      <button
                        data-bs-toggle='collapse'
                        data-bs-target={`#collapse_${i}`}
                        type='button'
                      >
                        <KeyboardArrowDown className={`${tableStyles.tlnTextPrimary}`} />
                      </button>
                    </td>
                    <td className='text-start'>{key}</td>
                    <td>{value.length}</td>
                  </tr>
                  <tr>
                    <td colSpan={3} className='p-0'>
                      <div className='collapse' id={`collapse_${i}`}>
                        <table style={{ fontFamily: 'inherit' }} className='w-100'>
                          <thead>
                            <tr>
                              <th className={`${tableStyles.tlnTextPrimary} px-3`}>Tipo</th>
                              <th className={`${tableStyles.tlnTextPrimary} px-3`}>Servicio</th>
                              <th className={`${tableStyles.tlnTextPrimary} px-3`}>Grupo</th>
                              <th className={`${tableStyles.tlnTextPrimary} px-3`}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {value.map((service, index) => (
                              <tr key={`${schedule.schId}_${service.serviceId}_${index}`}>
                                <td className='text-start px-3'>{service.ctypeName}</td>
                                <td className='text-start px-3'>{service.name}</td>
                                <td className='text-start px-3' width={200}>
                                  {service.groupName}
                                </td>
                                <td width={60}>
                                  <Delete
                                    fontSize='small'
                                    className={`${tableStyles.tlnTextPrimary} pointer`}
                                    onClick={() =>
                                      onRemoveService(key, service.serviceId, service.ctypeId)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={3}>No hay servicios seleccionados para listar</td>
              </tr>
            )}
          </OrdTable>
        </div>

        <div
          className={`card ${tableStyles.app_card_custom} my-3 p-3`}
          style={{ cursor: 'default' }}
        >
          <b className={tableStyles.tlnTextPrimary}>Grupos de atención</b>
          {groupsPayload.length ? (
            groupsPayload.map(group => (
              <div className='row mt-3' key={group.id}>
                <div className='col-lg-3'>{group.name}</div>
                <div className='col-lg-3'>
                  <NumberFormat
                    className='ord-roundInput w-100 text-end pe-2'
                    suffix='%'
                    placeholder='%'
                    allowNegative={false}
                    value={group.value}
                    onValueChange={({ floatValue }) => {
                      onTouchField(true);
                      setGroupsPayload(state =>
                        state.map(grp => {
                          if (grp.id === group.id) {
                            return { ...grp, value: floatValue };
                          }
                          return grp;
                        }),
                      );
                    }}
                    decimalScale={null}
                    allowLeadingZeros={false}
                    isAllowed={({ floatValue }) => floatValue === undefined || floatValue <= 100}
                  />
                </div>
              </div>
            ))
          ) : (
            <h6 className='text-muted mt-4'>No hay información para mostrar</h6>
          )}
        </div>

        <div className='d-flex w-100 justify-content-end align-items-center'>
          {(!editPayload || editPayload?.isCreating) && (
            <button
              className={`btn ordBtnSecondaryModuleAssistance me-3`}
              style={{ paddingTop: 6, paddingBottom: 6 }}
              onClick={() => onCancelForm()}
            >
              Cancelar
            </button>
          )}
          <button
            disabled={!isFieldTouched}
            className={`btn ${tableStyles.ordBtnPrimary}`}
            onClick={onSaveSchedule}
          >
            Guardar
          </button>
        </div>
        {doctor && schedule.modality && schedulePayload.site && (
          <AddService
            open={isOpenAddService}
            onClose={() => setIsOpenAddService(false)}
            onSaveServices={onSaveServices}
            doctor={doctor}
            modality={schedule.modality}
            site={schedulePayload.site}
            selectedServiceIds={selectedServiceIds}
          />
        )}
      </>
    );
  };

  return renderComponent();
}
