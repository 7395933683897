import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetMethod, usePostMethod } from '../../../Hooks';

import NumberFormat from 'react-number-format';
import ReactSelect from 'react-select';

import {
  formatNumberWithoutDecimals,
  getLocaleSeparators,
} from '../../../helpers/numberFormatting';

import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';

import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import { customSwaltAlertAsistencial, loader } from '../../../helpers';

export default function CreditPaymentModal({
  isOpen = false,
  onClose = () => null,
  onSuccess = () => null,
  appId,
  maxValue
}) {
  const token = useSelector(store => store.loginReducer.Authorization);

  const [payload, setPayload] = useState({
    value: '',
    reason: null,
    observations: '',
  });

  const {
    results: listCancellationReason,
    load: loaderListCancellationReason,
    trigger: getListCancellationReason,
  } = useGetMethod();

  const {
    trigger: getListCancellationsTypes,
    load: loadListCancellationsTypes
  } = useGetMethod();

  const {
    load: loaderMarkAsCreditPayment,
    trigger: markAsCreditPayment,
  } = usePostMethod();

  const cancellationReasonOptions = useMemo(() => {
    if (listCancellationReason?.results) {
      return [
        { label: 'Seleccionar...', value: null },
        ...listCancellationReason.results.map(reason => ({
          label: reason.canDescription,
          value: reason.canId,
        })),
      ];
    }
    return [];
  }, [listCancellationReason?.results]);

  useEffect(() => {
    if (isOpen) {
      void getListCancellationsTypes({
        url: '/medical/generals/cancellation-reasons/types',
        token: token
      }).then(({ results }) => {
        const canReasonTypeId = results?.find(
          canReasonType => canReasonType.cancellationReasonTag === 'credit_invoice',
        )?.cancellationReasonId;

        if (canReasonTypeId) {
          void getListCancellationReason({
            url: '/medical/generals/cancellationReason/',
            objFilters: {
              cancellationReasonId: canReasonTypeId
            },
            token: token
          });
        }
      });
    }
  }, [getListCancellationReason, getListCancellationsTypes, isOpen, token]);

  const onCloseModal = () => {
    setPayload({});
    onClose();
  };

  const onSubmit = () => {
    if (!payload.observations || !payload.reason || !payload.value) {
      void customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe rellenar todos los campos obligatorios',
        showCancelButton: false,
      });
      return;
    }
    if (appId) markAsCreditPayment({
      token: token,
      body: {},
      method: 'PATCH',
      url: `/medical/appointment/credit-payment/${appId}`,
      succesAction: () => {
        customSwaltAlertAsistencial({
          icon: 'success',
          title: 'Cita marcada exitosamente',
          text: `Se ha marcado la cita como pago a crédito.`,
          confirmButtonText: 'Aceptar',
          showCancelButton: false,
        });
        onSuccess(payload);
      }
    });
    setPayload({});
  };

  const renderComponent = () => {
    return (
      <OrdModal title='Pago a crédito' show={isOpen} onHide={onCloseModal} btnYesEvent={onSubmit}>
        {(loaderListCancellationReason || loaderMarkAsCreditPayment || loadListCancellationsTypes) && loader}
        <div className='mb-3'>
          <b className={tableStyles.ordDarkBlueText}>
            Valor
            <span className='text-danger'>*</span>
          </b>
          <NumberFormat
            allowNegative={false}
            className='ord-roundInput w-100'
            placeholder='Ingresa el valor...'
            thousandSeparator={getLocaleSeparators().groupSeparator}
            decimalSeparator={getLocaleSeparators().decimalSeparator}
            isNumericString={true}
            format={valueStr => {
              if (!valueStr) return '';
              return formatNumberWithoutDecimals(valueStr);
            }}
            allowLeadingZeros={false}
            isAllowed={({ floatValue }) => {
              return floatValue === undefined || (floatValue <= maxValue)
            }}
            value={payload.value}
            onValueChange={values => {
              setPayload(state => ({ ...state, value: values.floatValue }));
            }}
          />
        </div>
        <div className='mb-3'>
          <b className={tableStyles.ordDarkBlueText}>
            Motivo
            <span className='text-danger'>*</span>
          </b>
          <ReactSelect
            noOptionsMessage={() => 'No hay datos'}
            options={cancellationReasonOptions}
            className='text-secondary '
            placeholder='Seleccionar... '
            styles={ordCustomSelect}
            value={payload.reason}
            onChange={option => setPayload(state => ({ ...state, reason: option }))}
          />
        </div>
        <div className='mb-3'>
          <b className={tableStyles.ordDarkBlueText}>
            Observaciones
            <span className='text-danger'>*</span>
          </b>
          <textarea
            className='ord-roundInput w-100'
            style={{ minHeight: 150 }}
            placeholder='Escribe...'
            value={payload.observations}
            onChange={({ target }) =>
              setPayload(state => ({ ...state, observations: target.value }))
            }
          />
        </div>
      </OrdModal>
    );
  };

  return renderComponent();
}
