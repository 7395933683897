import OrdModal from '../../../OrderingModule/OrderingComponents/OrdModal';
import Select from 'react-select';
import { Text } from '../../../components/UI/atoms';
import { useTheme } from 'styled-components';
import { ordCustomSelect3 } from '../../../components/Layouts/react-select-custom';
import { Box } from '@mui/material';
import {
  ConditionalMessage,
  SearchAppointmentCarousel,
  SearchCardDoctor,
} from '../../../AppointmentModule/RegisterAppointment/DetailPatient/components';
import { customSwaltAlertAsistencial } from '../../../helpers';
import moment from 'moment';
import { useState } from 'react';

export const PatientModalSchedule = ({
  modalSchedule,
  onClose,
  events,
  setModalSchedule,
  setEvents,
  onChangeSelectOfDoctor,
}) => {
  const { colors } = useTheme();
  const [listHoursSelectedActive, setListHoursSelectedActive] = useState([]);
  const [listHoursSelected, setListHoursSelected] = useState([]);
  const onToggleHourSchedule = ({ unique, date, idSchedule, hourStart, hourEnd }) => {
    const hasHours = listHoursSelected?.filter(
      el => el?.date === date && el?.hourStart === hourStart,
    )?.length;

    const formatHours = hasHours
      ? listHoursSelected
      : listHoursSelected?.length
        ? [...listHoursSelected, { date, hourStart, hourEnd, idSchedule, unique }]
        : [{ date, hourStart, hourEnd, idSchedule, unique, active: true }];

    const hasHoursActives = listHoursSelectedActive?.filter(el => el?.unique === unique)?.length;

    let newActive = [];

    if (!hasHoursActives) {
      newActive = listHoursSelectedActive?.length
        ? [
          ...listHoursSelectedActive,
          { date, hourStart, hourEnd, idSchedule, unique, active: true },
        ]
        : [{ date, hourStart, hourEnd, idSchedule, unique, active: true }];

      setListHoursSelectedActive(newActive);
    }

    if (hasHoursActives) {
      newActive = listHoursSelectedActive?.map(el => {
        if (el?.unique === unique) {
          return {
            date,
            hourStart,
            hourEnd,
            idSchedule,
            unique,
            active: true,
          };
        }
        return el;
      });

      setListHoursSelectedActive(newActive);
    }

    const formatData = [modalSchedule]?.map(el => {
      if (el?.unique === unique) {
        return {
          ...el,
          listSchedulesHours: el?.listSchedulesHours?.map(sch => {
            return {
              ...sch,
              hours: sch?.hours?.map(hour => {
                if (
                  newActive?.filter(
                    item =>
                      item?.hourStart === hour?.hourStart &&
                      item?.date === date &&
                      item?.unique === unique,
                  )?.length
                ) {
                  return {
                    ...hour,
                    appointment: true,
                    selected: true,
                  };
                }

                if (
                  newActive?.filter(
                    item =>
                      item?.hourStart === hour?.hourStart &&
                      item?.date === hour?.date &&
                      item?.unique !== unique,
                  )?.length
                ) {
                  return {
                    ...hour,
                    appointment: true,
                    selected: false,
                  };
                }

                if (
                  newActive?.filter(
                    item => item?.hourStart !== hour?.hourStart && item?.date === date,
                  )?.length
                ) {
                  return {
                    ...hour,
                    systemAppointment: hour?.appointment,
                    appointment: hour?.virtualAppointment ? true : false,
                    selected: false,
                    blockByUser: false,
                  };
                }

                return {
                  ...hour,
                  selected: false,
                };
              }),
            };
          }),
        };
      }

      if (moment(el?.date)?.format('YYYY-MM-DD') === date && el?.unique !== unique) {
        return {
          ...el,
          listSchedulesHours: el?.listSchedulesHours?.map(sch => {
            return {
              ...sch,
              hours: sch?.hours?.map(hour => {
                if (
                  newActive?.filter(
                    item =>
                      item?.hourStart === hour?.hourStart &&
                      item?.date === date &&
                      item?.unique === unique,
                  )?.length
                ) {
                  return {
                    ...hour,
                    appointment: true,
                    selected: false,
                  };
                }

                if (
                  newActive?.filter(
                    item =>
                      item?.date === date &&
                      item?.hourStart === hour?.hourStart &&
                      item?.unique === el?.unique,
                  )?.length
                ) {
                  return {
                    ...hour,
                    appointment: true,
                    selected: true,
                  };
                }

                if (
                  newActive?.filter(
                    item => item?.date === date && item?.hourStart === hour?.hourStart,
                  )?.length
                ) {
                  return {
                    ...hour,
                    appointment: true,
                    selected: false,
                  };
                }

                if (
                  newActive?.filter(
                    item => item?.hourStart !== hour?.hourStart && item?.date === date,
                  )?.length
                ) {
                  return {
                    ...hour,
                    systemAppointment: hour?.appointment,
                    appointment: hour?.virtualAppointment ? true : false,
                    selected: false,
                    blockByUser: false,
                  };
                }

                return hour;
              }),
            };
          }),
        };
      }

      return el;
    });

    setModalSchedule(...formatData);
    setListHoursSelected(formatHours);
  };

  const validateModalSchedule = () => {
    const data = listHoursSelectedActive?.find((obj) => obj.unique === modalSchedule?.unique)
    const schedule = modalSchedule?.listSchedules?.find((obj) => obj.idSchedule === data?.idSchedule)
    if (modalSchedule && !modalSchedule?.doctor) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe seleccionar un médico',
        showCancelButton: false,
      });
    }
    if (data && !data?.idSchedule) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe seleccionar una agenda',
        showCancelButton: false,
      });
    }
    if (data && !data?.idSchedule) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe seleccionar una agenda',
        showCancelButton: false,
      });
    }
    if ((data && !data?.hourEnd || !data?.hourStart)) {
      return customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe seleccionar una hora de atención',
        showCancelButton: false,
      });
    }
    const filter = events?.map((event, index) => {
      if (index === modalSchedule.eventsIndex) {
        return {
          ...event,
          service: {
            ...event.service,
            children: event?.service?.children?.map((service, serIndex) => {
              if (serIndex === modalSchedule.index) {
                return {
                  ...service,
                  doctorId: modalSchedule.doctor.value,
                  doctorName: modalSchedule.doctor.label,
                  scheduleStatus: data?.active,
                  appDatetime: `${data?.date} ${data?.hourStart}`,
                  appDatetimeEnd: `${data?.date} ${data?.hourEnd}`,
                  schId: data?.idSchedule,
                  scheduleName: schedule?.scheduleName
                }
              }
              return service;
            }
            ),
          },
        };
      }
      return event;
    });
    setEvents(filter);
    onClose()
  };

  const onToggleDoctor = ({ unique, active, idSchedule, schedules, doctorName, doctorId }) => {
    if (!active) {
      const formatListHoursSelectedActive = listHoursSelectedActive?.map(el => {
        if (el?.unique === unique) {
          return {
            ...el,
            active: false,
          };
        }
        return el;
      });

      const formatData = [modalSchedule]?.map(el => {
        const newListSchedulesHours = el?.listSchedulesHours?.map?.(sch => {
          return {
            ...sch,
            hours: sch?.hours?.map(hour => {
              if (
                formatListHoursSelectedActive?.filter(
                  el => el?.hourStart === hour?.hourStart && hour?.date === el?.date && !el?.active,
                )?.length
              ) {
                return {
                  ...hour,
                  appointment: false,
                  selected: false,
                };
              }

              return {
                ...hour,
                locked: hour?.appointment,
              };
            }),
          };
        });

        return {
          ...el,
          doctor: null,
          listSchedules:
            el?.unique === unique
              ? el?.listSchedules?.map(sch =>
                sch?.idSchedule === idSchedule ? { ...sch, active } : sch,
              )
              : el?.listSchedules,
          listSchedulesHours: newListSchedulesHours,
        };
      });
      setModalSchedule({ ...formatData[0] });
      setListHoursSelectedActive(formatListHoursSelectedActive);
    } else {
      const formatListHoursSelectedActive = listHoursSelectedActive?.filter(
        el => el?.unique !== unique,
      );
      const formatData = [modalSchedule]?.map(el => {
        const newListSchedulesHours = [];
        el?.listSchedules?.forEach?.(item => {
          item?.schedule?.forEach(sch => {
            if (sch?.idSchedule === idSchedule) {
              newListSchedulesHours.push({
                ...sch,
                hours: sch?.hours?.map(hour => {
                  if (
                    formatListHoursSelectedActive?.filter(
                      el =>
                        el?.hourStart === hour?.hourStart && hour?.date === el?.date && el?.active,
                    )?.length
                  ) {
                    return {
                      ...hour,
                      selected: Boolean(
                        formatListHoursSelectedActive?.filter(
                          element => element?.unique === el?.unique,
                        )?.length,
                      ),
                      appointment: true,
                    };
                  }

                  return {
                    ...hour,
                    locked: hour?.appointment,
                  };
                }),
              });
            }
          });
        });

        return {
          ...el,
          doctor: {
            label: doctorName,
            value: doctorId
          },
          listSchedules:
            el?.unique === unique
              ? el?.listSchedules?.map(sch =>
                sch?.idSchedule === idSchedule ? { ...sch, active } : { ...sch, active: false },
              )
              : el?.listSchedules,

          listSchedulesHours:
            el?.unique === unique
              ? newListSchedulesHours?.length
                ? newListSchedulesHours
                : schedules
              : el?.listSchedulesHours,
        };
      });
      setModalSchedule({ ...formatData[0] });
      setListHoursSelectedActive(formatListHoursSelectedActive);
    }
  };

  return (
    <OrdModal
      hideCancelButton
      show={modalSchedule.active}
      btnYesName='Guardar'
      btnYesEvent={() => validateModalSchedule()}
      title={'Seleccionar agenda'}
      onHide={() => onClose()}
      size='lg'
    >
      <div>
        <div className='d-flex flex-column w-50 mb-4'>
          <Text title={'Médico'} color={colors.gray} fontSize='13px' />
          <Select
            noOptionsMessage={() => 'No hay datos'}
            options={modalSchedule.doctorOptions}
            className='text-secondary'
            placeholder={'Seleccionar...'}
            styles={ordCustomSelect3}
            value={modalSchedule.doctor}
            onChange={e => {
              onChangeSelectOfDoctor({
                doctor: e,
                serviceId: modalSchedule?.service?.id,
                popId: events[modalSchedule?.eventsIndex]?.appContract?.popId,
                cityId: events[modalSchedule?.eventsIndex]?.cityId,
                contractId: events[modalSchedule?.eventsIndex]?.appContract?.contractId,
                clientId: events[modalSchedule?.eventsIndex]?.appContract?.cclientId,
                modality: 'on_site',
                siteId: events[modalSchedule?.eventsIndex]?.siteId,
                date: new Date(),
              });
            }}
          ></Select>
        </div>
        {modalSchedule?.showCardSchedule ? (
          <>
            <Box
              mt={2}
              display={'grid'}
              gridTemplateColumns={'1.5fr 1fr'}
              alignItems={'flex-start'}
              columnGap={3}
            >
              <div
                style={{
                  maxWidth: '300px',
                }}
              >
                {modalSchedule?.listSchedules?.filter(doctor => doctor?.active)?.length ? (
                  <SearchAppointmentCarousel
                    currentDate={modalSchedule.date}
                    schedules={modalSchedule?.listSchedulesHours}
                    onToggleHourSchedule={({ idSchedule, hourStart, hourEnd, date, time }) => {
                      onToggleHourSchedule({
                        date,
                        time,
                        hourStart,
                        hourEnd,
                        idSchedule,
                        unique: modalSchedule.unique,
                      });
                    }}
                  />
                ) : (
                  <ConditionalMessage key={2} hasSchedules={modalSchedule?.listSchedules?.length} />
                )}
              </div>

              <div style={{ height: '250px', overflowY: 'auto' }}>
                {modalSchedule?.listSchedules?.map(schedule => (
                  <SearchCardDoctor
                    active={schedule?.active}
                    key={schedule?.idSchedule}
                    scheduleName={schedule?.scheduleName}
                    doctorName={schedule?.doctor}
                    doctorId={schedule?.idDoctor}
                    doctorImage={schedule?.image}
                    onToggleDoctor={({ doctorName, doctorId }) => {
                      onToggleDoctor({
                        unique: modalSchedule.unique,
                        active: !schedule?.active,
                        idSchedule: schedule?.idSchedule,
                        schedules: schedule?.schedule,
                        doctorName: doctorName,
                        doctorId: doctorId,
                      });
                    }}
                  />
                ))}
              </div>
            </Box>
          </>
        ) : (
          ''
        )}
      </div>
    </OrdModal>
  );
};
