import Pagination from 'react-js-pagination';
import ReactSelect from 'react-select';

import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useGetMethod, usePostMethod } from '../../Hooks';

import IconBack from '../../assets/img/icons/ordBackArrow.svg';
import ordBlueSearch from '../../assets/img/icons/ordBlueSearch.svg';

import { chunkPagination, customSwaltAlertAsistencial, loader } from '../../helpers';

import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';

import { ordCustomSelect } from '../../components/Layouts/react-select-custom';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import stylePagination from '../../OrderingModule/OrderingComponents/ordComponentStyles.module.scss';

export default function LinkServices() {
  const history = useHistory();
  const location = useLocation();
  const { contractId } = useParams();

  const accountId = useSelector(store => store.loginReducer.currentAccount.id);
  const token = useSelector(store => store.loginReducer.Authorization);

  const [payload, setPayload] = useState({
    contract: null,
    populations: [],
    contractServices: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [serviceType, setServiceType] = useState(null);
  const [showUnchecked, setShowUnchecked] = useState(false);

  const {
    results: accountContractsResult,
    trigger: getAccountContracts,
    load: accountContractsLoader,
  } = useGetMethod();
  const { results: populationsResult, trigger: getPopulations } = useGetMethod();
  const { results: servicesResult, trigger: getServices, load: servicesLoader } = useGetMethod();
  const { trigger: getServiceTypes, results: serviceTypesResults } = useGetMethod();
  const { trigger: getContractDetail, load: detailLoader } = useGetMethod();
  const { trigger: createLinkServices, load: createLinkServicesLoader } = usePostMethod();

  const offeredServicesOptions = useMemo(() => {
    if (servicesResult?.results) {
      return chunkPagination(
        servicesResult.results
          .filter(opt =>
            showUnchecked ? !payload.contractServices?.includes(opt.idContractService) : opt,
          )
          .filter(opt =>
            serviceType?.value
              ? opt.serviceType.trim() === serviceType.label.trim().toUpperCase()
              : opt,
          )
          .filter(opt =>
            searchValue.length
              ? opt.nameService?.trim().toLowerCase().includes(searchValue.trim().toLowerCase())
              : opt,
          )
          .map(opt => ({
            id: opt.idContractService,
            serviceId: opt.idService,
            serviceType: opt.serviceType,
            name: opt.nameService,
          })),
        10,
      );
    }
    return [];
  }, [servicesResult.results, serviceType, searchValue, payload.contractServices, showUnchecked]);

  const accountContractsOptions = useMemo(() => {
    const options = [{ label: 'Seleccionar...', value: null }];
    if (accountContractsResult?.results) {
      for (const item of accountContractsResult.results) {
        const option = { value: item.idContract, label: item.contractName };
        options.push(option);
      }
    }
    return options;
  }, [accountContractsResult.results]);

  const populationsOptions = useMemo(() => {
    const options = [{ label: 'Seleccionar...', value: null }];
    if (populationsResult?.results) {
      for (const item of populationsResult.results) {
        const option = { value: item.idClientPopulation, label: item.nameClientPopulation };
        options.push(option);
      }
    }
    return options;
  }, [populationsResult.results]);

  const serviceTypesOptions = useMemo(() => {
    if (serviceTypesResults?.results) {
      const validServiceTypes = serviceTypesResults?.results.map(opt => ({
        label: opt.serviceType,
        value: opt.serviceTypeId,
      }));
      return [{ label: 'Seleccionar...', value: null }, ...validServiceTypes];
    }
    return [];
  }, [serviceTypesResults?.results]);

  useEffect(() => {
    if (contractId && payload.contract && payload.contract.value) {
      getContractDetail({
        url: '/medical/doctor/medicalContractDetail/',
        objFilters: {
          idContractMd: contractId,
          idContract: payload.contract.value,
        },
        token: token,
        succesAction: response => {
          if (response?.results) {
            const options = populationsOptions.filter(pop =>
              response.results.idPop?.includes(pop.value),
            );
            setPayload(state => ({
              ...state,
              contractServices: response.results.idContractServices || [],
              populations: options,
            }));
          }
        },
      });
    } else {
      setPayload({contract: null, populations: [], contractServices: []});
    }
  }, [contractId, getContractDetail, payload.contract, populationsOptions, token]);

  useEffect(() => {
    void getAccountContracts({
      url: '/medical/generals/accountContracts/',
      objFilters: { idAccount: accountId, status: 'enabled' },
      token: token,
    });
  }, [accountId, getAccountContracts, token]);

  useEffect(() => {
    if (payload.contract) {
      void getPopulations({
        url: '/medical/generals/populationsByContract/',
        objFilters: { idContract: payload.contract.value },
        token: token,
      });
    }
  }, [getPopulations, payload.contract, token]);

  useEffect(() => {
    if (payload.contract && payload.populations.length) {
      const populations = payload.populations.map(pop => pop.value);
      void getServices({
        url: '/medical/generals/servicesByRelCP/',
        objFilters: {
          idPopulations: `[${populations.join(',')}]`,
          idContract: payload.contract.value,
        },
        token: token,
      });
      void getServiceTypes({
        url: '/medical/generals/serviceType/',
        objFilters: { listAll: 1 },
        token: token,
      });
    }
  }, [getServiceTypes, getServices, payload.contract, payload.populations, token]);

  const validateCheck = () =>
    !payload.contractServices.length
    ? false
    : offeredServicesOptions
      .flat()
      .map(opt => opt.id)
      .every(id => payload.contractServices?.some(serv => serv === id));

  const onCheckAll = () => {
    const services = offeredServicesOptions.flat().map(opt => opt.id);
    const allSelected = services.every(id => payload.contractServices?.some(serv => serv === id));

    if (allSelected) {
      setPayload(state => ({
        ...state,
        contractServices: state.contractServices.filter(service => !services.includes(service)),
      }));
    } else {
      setPayload(state => ({
        ...state,
        contractServices: [
          ...state.contractServices,
          ...services
            .filter(contractServiceId => !state.contractServices.includes(contractServiceId)),
        ],
      }));
    }
  };

  const onCheckService = contractServiceId => {
    const serviceExists = payload.contractServices.includes(contractServiceId);

    if (!serviceExists) {
      setPayload(state => ({
        ...state,
        contractServices: [...state.contractServices, contractServiceId],
      }));
    } else {
      setPayload(state => ({
        ...state,
        contractServices: state.contractServices.filter(serv => serv !== contractServiceId),
      }));
    }
  };

  const onSubmit = () => {
    if (!payload.contract) {
      void customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe seleccionar un contrato',
        showCancelButton: false,
      });
      return;
    }
    if (!payload.populations.length) {
      void customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe seleccionar al menos una población',
        showCancelButton: false,
      });
      return;
    }
    if (!payload.contractServices.length) {
      void customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Debe seleccionar al menos un servicio',
        showCancelButton: false,
      });
      return;
    }

    createLinkServices({
      url: '/medical/doctor/linkServicesToMedicalContract/',
      token: token,
      method: 'POST',
      body: {
        idContractMd: contractId,
        idContractServices: payload.contractServices,
        idPopulations: payload.populations.map(pop => pop.value),
      },
      succesAction: () => {
        customSwaltAlertAsistencial({
          showCancelButton: false,
          icon: 'success',
          title: 'Servicios asociados exitosamente',
          text: `Se han asociado los servicios al médico: ${location.state.doctorName}`,
          confirmButtonText: 'Aceptar',
        });
        history.goBack();
      },
      doAfterException: results => {
        customSwaltAlertAsistencial({
          showCancelButton: false,
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: results.message,
          confirmButtonText: 'Aceptar',
        });
      },
    });
  };

  const tableHeaders = [
    {
      title: (
        <input
          type='checkbox'
          className={`
            ${tableStyles.checkClearBlue}
            ${tableStyles.appCheckInput}
            ${tableStyles.appCheckClear}
            form-check-input p1
            ms-1
          `}
          checked={validateCheck()}
          onChange={() => onCheckAll()}
        />
      ),
      className: 'px-2 text-start',
    },
    { title: 'Tipo de servicio', className: 'px-2 text-start' },
    { title: 'Servicio', className: 'px-2 text-start' },
  ];

  const renderSelects = () => {
    return (
      <div className='row mt-3'>
        <div className='col-lg-3'>
          <b className={`ms-1 ${tableStyles.ordDarkBlueText}`}>
            Contrato<span className='text-danger'>*</span>
          </b>
          <ReactSelect
            noOptionsMessage={() => 'No hay datos'}
            options={accountContractsOptions}
            className='text-secondary'
            placeholder='Seleccionar...'
            styles={ordCustomSelect}
            onChange={option => {
              setPayload(state => ({ ...state, contract: option }));
            }}
          />
        </div>
        <div className='col-lg-6'>
          <b className={`ms-1 ${tableStyles.ordDarkBlueText}`}>
            Población<span className='text-danger'>*</span>
          </b>
          <ReactSelect
            isMulti={true}
            noOptionsMessage={() => 'No hay datos'}
            options={populationsOptions}
            className='text-secondary'
            placeholder='Seleccionar...'
            value={payload.populations}
            onChange={options => setPayload(state => ({ ...state, populations: options }))}
            styles={ordCustomSelect}
          />
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <>
        <OrdTable
          headers={tableHeaders}
          className={`${tableStyles.shade}`}
          hasChildren
          style={{ borderRadius: '10px', overflow: 'hidden' }}
        >
          {(offeredServicesOptions[currentPage - 1]?.length && payload.contract) ? (
            offeredServicesOptions[currentPage - 1]?.map(service => (
              <tr key={service.id}>
                <td width={40} className='px-2'>
                  <input
                    type='checkbox'
                    className={`
                      ${tableStyles.checkClearBlue}
                      ${tableStyles.appCheckInput}
                      ${tableStyles.appCheckClear}
                      form-check-input p1
                    `}
                    checked={payload.contractServices?.includes(service.id)}
                    onChange={() => onCheckService(service.id)}
                  />
                </td>
                <td className='text-start px-2'>{service.serviceType}</td>
                <td className='text-start px-2'>
                  <div title={service.name} className='text-truncate' style={{ width: 350 }}>
                    {service.name}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className='text-center' colSpan={4}>
                No hay información para mostrar
              </td>
            </tr>
          )}
        </OrdTable>
        <div className='d-flex justify-content-between align-items-start'>
          <label>
            <input
              type='checkbox'
              className={`
                ${tableStyles.checkClearBlue}
                ${tableStyles.appCheckInput}
                ${tableStyles.appCheckClear}
                form-check-input p1
                ms-1
              `}
              checked={showUnchecked}
              onChange={({ target }) => {
                setShowUnchecked(target.checked);
                setCurrentPage(1);
              }}
            />
            <small className='ms-2 text-muted'>Mostrar servicios sin marcar</small>
          </label>
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={10}
            totalItemsCount={payload.contract ? offeredServicesOptions?.flatMap(list => list).length : 0}
            pageRangeDisplayed={3}
            onChange={page => {
              setCurrentPage(page);
            }}
            itemClassPrev={stylePagination.itemClassPrev}
            itemClassNext={stylePagination.itemClassNext}
            itemClassFirst={stylePagination.itemClassFirst}
            itemClassLast={stylePagination.itemClassLast}
            itemClass={stylePagination.itemClass}
          />
        </div>
      </>
    );
  };

  const renderComponent = () => {
    return (
      <div className='container-fluid py-3 px-5'>
        {(accountContractsLoader || servicesLoader || detailLoader || createLinkServicesLoader) &&
          loader}
        <div className='d-flex align-items-center mb-4'>
          <img
            onClick={() => {
              history.goBack();
            }}
            src={IconBack}
            className='pointer me-3'
            alt=''
          />
          <h2 className={`m-0 ${tableStyles.ordDarkBlueText} fw-bold`}>Asociar servicios</h2>
        </div>
        {renderSelects()}
        <hr className='mt-4 mb-4' />
        <div className='row align-items-end'>
          <div className='col-lg-3'>
            <label className={`ms-1 ${tableStyles.ordDarkBlueText} fw-bold`}>
              Tipo de servicio
            </label>
            <ReactSelect
              noOptionsMessage={() => 'No hay datos'}
              options={serviceTypesOptions}
              className='text-secondary '
              placeholder='Seleccionar...'
              styles={ordCustomSelect}
              onChange={option => {
                setServiceType(option);
                setCurrentPage(1);
              }}
            />
          </div>
          <div className='col-lg-6'>
            <div className='position-relative'>
              <input
                type='text'
                className={`ord-roundInput ${tableStyles.appInputDate} border-0 w-100`}
                placeholder='Buscar...'
                style={{ fontSize: 14 }}
                value={searchValue}
                onChange={({ target }) => void setSearchValue(target.value)}
              />
              <img
                src={ordBlueSearch}
                className='pointer position-absolute'
                style={{ right: 12, top: 10, pointerEvents: 'none' }}
                alt='buscar'
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-9'>{renderTable()}</div>
        </div>

        <div className='row pt-3 pb-5'>
          <div className='col-lg-9 d-flex justify-content-end'>
            <button
              className={`btn ordBtnSecondaryModuleAssistance me-3`}
              style={{ paddingTop: 6, paddingBottom: 6 }}
              onClick={() => history.goBack()}
            >
              Cancelar
            </button>
            <button className={`btn ${tableStyles.ordBtnPrimary}`} onClick={onSubmit}>
              Guardar
            </button>
          </div>
        </div>
      </div>
    );
  };

  return renderComponent();
}
