import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
//
import { getDetailRecord, receiveInvoices } from '../../actions/receiptOfInvoicesActions';
import Lupa2 from '../../assets/img/icons/auctionDetailLupa2.svg';
import IconBack from '../../assets/img/icons/iconBack.svg';
import GenericTable from '../../components/Layouts/GenericTableNew';
import { loader } from '../../helpers';
//
import paginationStyles from '../../components/Layouts/pagination.module.scss';
import tableStyles from '../../components/Layouts/tableStyle.module.scss';
import IndividualStyles from '../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';

function DetReceiptInvoices() {
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const isLoading = store?.InvoiceReceiptReducer?.loadingInvoicesReceipt;

  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
  });
  const [selectedList, setselectedList] = useState([]);

  const currentDetail = store.invoiceReducer.detail;
  const rowCount = store.invoiceReducer.rowTotal;

  useEffect(() => {
    if (params.id) {
      dispatch(
        getDetailRecord({
          id: params.id,
          eaccount: store.loginReducer.currentAccount.id,
        }),
      );
    }
  }, [params.id, dispatch, store.loginReducer.currentAccount.id, store.invoiceReducer.send_inv]);

  const isBoxSelected = id => {
    if (Array.isArray(selectedList)) {
      let target = selectedList.find(x => x.id === id);
      return !!target;
    }
  };
  const changeBoxSelected = id => {
    let tempSelected = selectedList;
    if (Array.isArray(tempSelected)) {
      let target = tempSelected.find(x => x.id === id);
      if (target) {
        tempSelected = tempSelected.filter(x => x.id !== id);
      } else {
        let newChange = {
          id,
        };
        tempSelected.push(newChange);
      }
      setselectedList(tempSelected);
    }
  };
  const isBoxAllSelected = () => {
    let res = true;
    if (Array.isArray(store.invoiceReducer.detail.invoices) && Array.isArray(selectedList)) {
      store.invoiceReducer.detail.invoices.forEach(item => {
        if (selectedList.some(itemb => item.id === itemb.id)) {
          //f
        } else {
          res = false;
        }
      });
    }
    return res;
  };
  const changeBoxAllSelected = checkEmAll => {
    //first what do we need to do?
    if (checkEmAll) {
      //if we need to check all positive, then insert all the ones we dont have
      let tempSelected = [];
      if (Array.isArray(store.invoiceReducer.detail.invoices) && Array.isArray(tempSelected)) {
        store.invoiceReducer.detail.invoices.forEach(x => {
          let id = x.id;
          let newChange = {
            id,
          };
          tempSelected.push(newChange);
        });
        setselectedList(tempSelected);
      }
    } else {
      //if we need to check all positive, then just set the array as empty
      setselectedList([]);
    }
  };

  const numberWithCommas = x => {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  };

  const saveAction = () => {
    dispatch(
      receiveInvoices(
        {
          id: params.id,
          eaccount: store.loginReducer.currentAccount.id,
          invoices: selectedList,
          totalInvoices: store.invoiceReducer.detail.invoices.length,
        },
        () => {
          history.goBack();
        },
      ),
    );
  };

  const buttonReceive = () => {
    if (isBoxAllSelected()) {
      saveAction();
    }

    // customSwaltAlert({
    //   title: '¿Está seguro?',
    //   text:
    //     selectedList?.length > 0
    //       ? `Se rechazarán ${rowCount - selectedList?.length} facturas en este acta.`
    //       : 'Se rechazará el acta.',
    //   confirmButtonText: 'Sí, continuar',
    // }).then(res => {
    //   if (res.isConfirmed) {
    //     saveAction();
    //   }
    // });
  };

  const header = [
    currentDetail?.status === 'Pendiente' ? (
      <th className='text-center'>
        <input
          type='checkbox'
          name={'selectedAll'}
          value='selectedAll'
          checked={isBoxAllSelected()}
          onChange={() => changeBoxAllSelected(!isBoxAllSelected())}
        />
      </th>
    ) : null,
    <th key={1} className='text-center'>
      No. Factura{' '}
    </th>,
    <th key={2} className='text-center'>
      Fecha factura
    </th>,
    <th key={3} className='text-center'>
      Empresa
    </th>,
    <th key={4} className='text-center'>
      Contrato
    </th>,
    <th key={5} className='text-center'>
      Sede
    </th>,
    <th key={6} className='text-center'>
      Valor
    </th>,
    <th key={7} className='text-center px-1'></th>,
  ];

  const renderList = () => {
    let table = [];

    if (Array.isArray(store.invoiceReducer.detail.invoices)) {
      table = store.invoiceReducer.detail.invoices.map((x, index) => {
        return (
          <tr
            key={'renderList' + index}
            className={`hover-table-row ${x.darkColor === 'True' && tableStyles.muted}`}
          >
            {currentDetail?.status === 'Pendiente' ? (
              <td className='text-center' width={40}>
                <input
                  type='checkbox'
                  name=''
                  id=''
                  checked={isBoxSelected(x.id)}
                  onChange={() => changeBoxSelected(x.id)}
                />
              </td>
            ) : null}
            <td className='text-uppercase'>{x.invoice}</td>
            <td>{x.date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')}</td>
            <td>{x.company}</td>
            <td>{currentDetail.contract}</td>
            <td>{currentDetail.site}</td>
            <td>{'$' + numberWithCommas(x.amount)}</td>
            <td className='text-center' width={50}>
              <img
                alt='img'
                className={`text-start w-40 pointer`}
                src={Lupa2}
                onClick={() => history.push(`/cartera/Factura/SoportesFactura/${x.id}`)}
              />
            </td>
          </tr>
        );
      });
    }
    return table;
  };

  var today = new Date();

  return (
    <>
      {store.invoiceReducer.loading_det_receipt_invoice && loader}
      {isLoading && loader}
      <div className={` ml-5 ${tableStyles.container}`} style={{ marginRight: '3rem' }}>
        <div className='d-flex align-items-center mt-5 mb-3'>
          <img onClick={() => history.goBack()} src={IconBack} className='pointer me-3' alt='' />
          <h1 className='m-0'>Acta de facturación</h1>
        </div>

        <div className='row align-items-center'>
          <div className='col-lg-10'>
            <p>
              Por medio del presente documento se da constancia del envío de las facturas anexadas
              al departamento de cartera, el día {String(today.getDate()).padStart(2, '0')}, del mes{' '}
              {String(today.getMonth() + 1).padStart(2, '0')} del {today.getFullYear()}.
            </p>
          </div>
          <div className='col-lg-2 text-end'>
            <p className='text-primary'>{store.invoiceReducer.detail.sequence}</p>
          </div>
        </div>

        <GenericTable className='mb-6' headers={header} dark={true}>
          {renderList()}
        </GenericTable>
        <div className={paginationStyles.wrapper}>
          <p className={paginationStyles.paginationText}>
            Pag. {filters.page}
            {' de '}
            {Math.ceil(rowCount / filters.perpage) ? Math.ceil(rowCount / filters.perpage) : '1'} (
            {rowCount} encontrados)
          </p>
          <Pagination
            activePage={filters.page}
            itemsCountPerPage={filters.perpage}
            totalItemsCount={rowCount}
            pageRangeDisplayed={5}
            onChange={page => setFilters({ ...filters, page: page })}
            itemClassPrev={paginationStyles.itemClassPrev}
            itemClassNext={paginationStyles.itemClassNext}
            itemClassFirst={paginationStyles.itemClassFirst}
            itemClassLast={paginationStyles.itemClassLast}
            itemClass={paginationStyles.itemClass}
          />
        </div>

        <div className='row mt-4'>
          <div className='col-4 mt-4'>
            <p className={`${IndividualStyles.labelInfo} mb-0`}>
              {store.invoiceReducer.detail.generatorFullName}
            </p>
            <p className={`${IndividualStyles.labelInfo} mb-0`}>Asistente de facturación</p>
            <p className={`${IndividualStyles.labelInfo} mb-0`}>
              C.C:{store.invoiceReducer.detail.generatorDocNum}
            </p>
          </div>
          <div className='col-8'>
            <div className='display-grid'>
              <label className={`${tableStyles.crudModalLabel} m-0`}>Descripción</label>
              <textarea
                className='register-inputs'
                style={{ minHeight: 60 }}
                name='textarea'
                value={store.invoiceReducer.detail.summary}
                disabled
              />
            </div>
          </div>
        </div>
        {currentDetail?.status !== 'Pendiente' && (
          <div style={{ width: '100%', padding: 20 }}></div>
        )}
        {currentDetail?.status === 'Pendiente' && (
          <div className={IndividualStyles.bottomRow}>
            <Button
              className={`${tableStyles.buttonExtra3} d-block`}
              onClick={() => buttonReceive()}
              // disabled={store.invoiceReducer.detail?.invoices?.length > 0 ? false : true}
              disabled={
                selectedList.length > 0 && store.invoiceReducer.detail?.invoices?.length > 0
                  ? false
                  : true
              }
            >
              Recibir
            </Button>
            <Button className={tableStyles.btnSecondary} onClick={() => history.goBack()}>
              Cancelar
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
export default DetReceiptInvoices;
