import { Col, Form, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { useTheme } from 'styled-components';
import cardCredit from '../../../assets/img/icons/cardCredit.svg';
import close from '../../../assets/img/icons/close-blue.svg';
import moneySymbol from '../../../assets/img/icons/moneySymbol.svg';
import transfer from '../../../assets/img/icons/transfer.svg';
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import tableStyles from '../../../components/Layouts/tableStyle.module.scss';
import { Text } from '../../../components/UI/atoms';

import { formatNumberWithoutDecimals, getLocaleSeparators } from '../../../helpers/numberFormatting';

const localeSeparators = getLocaleSeparators()

export const CardCollectionDynamic = ({
  disabledFields,
  data,
  activeEvent,
  setActiveEvent,
  handleRemoveDeposit,
  totalAmount,
  listFranchise,
  listBank,
  onChangeValue,
  selectedSurgery,
  currency
}) => {
  const { colors } = useTheme();

  const img =
    data?.image === 'cash' ? moneySymbol : data?.image === 'cardCredit' ? cardCredit : transfer;

  return (
    <Row className='mt-2' style={{ maxWidth: '1100px' }}>
      <Col lg={12} className='position-relative'>
        <div className={`${tableStyles.addCardWhite} w-100 row py-3 flex-nowrap`}>
          <Col xs={2} className='align-self-center'>
            <div className='d-flex align-self-center justify-content-center'>
              <div
                className={`${tableStyles.addCardWhite} d-flex justify-content-center align-self-center m-0 my-0`}
                style={{ width: '100px', height: '72px', alignItems: 'center', display: 'flex' }}
              >
                <img alt='arrowIcon' height='50px' src={img} />
              </div>
            </div>
          </Col>

          <Col xs={5}>
            <Form.Group className='mb-2 text-start' controlId=''>
              <Text
                title={'Forma de pago'}
                color={colors.ordDarkBlueText}
                fontSize={'14px'}
                required
              />

              <Form.Control
                className={`ord-roundInput `}
                type='text'
                placeholder={data?.depoTypeName}
                disabled
              />
            </Form.Group>

            {data?.haveChange ? (
              <div className='row g-3'>
                <Col xs={!data?.pendingAmount ? 12 : 6}>
                  <Form.Group className=' text-start mr-2' controlId=''>
                    <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                      <span> &nbsp;Devuelta total </span>
                    </Form.Label>
                    <NumberFormat
                      disabled
                      allowNegative={false}
                      className={`register-inputs`}
                      placeholder='Escribe...'
                      style={{
                        maxHeight: '32px',
                        height: '32px',
                        fontSize: '14px',
                        borderColor: '#a3bad1',
                      }}
                      isNumericString={true}
                      value={data?.pendingAmount > 0 ? data.pendingAmount : 0}
                      thousandSeparator={localeSeparators.groupSeparator}
                      decimalSeparator={localeSeparators.decimalSeparator}
                      format={(valueStr) => {
                        if (!valueStr) return ''

                        return formatNumberWithoutDecimals(valueStr, currency)
                      }}
                      allowLeadingZeros={false}
                    />
                  </Form.Group>
                </Col>
                {data?.pendingAmount > 0 ? (
                  <Col xs={6}>
                    <Form.Group className=' text-start ml-2' controlId=''>
                      <Form.Label
                        className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}
                      >
                        <span> &nbsp;¿Donar la devuelta?</span>
                      </Form.Label>
                      <Select
                        noOptionsMessage={() => 'No hay datos'}
                        options={[
                          { label: 'Si', value: 1 },
                          { label: 'No', value: 0 },
                        ]}
                        value={
                          data?.donateChangeName
                            ? { label: data?.donateChangeName, value: data?.donateChangeName }
                            : ''
                        }
                        className='text-secondary '
                        placeholder={'Seleccionar...'}
                        styles={ordCustomSelect}
                        onChange={({ value, label }) => {
                          if (setActiveEvent) {
                            setActiveEvent({
                              ...activeEvent,
                              listDeposits: activeEvent?.listDeposits?.map(el =>
                                el?.depoTypeId === data?.depoTypeId
                                  ? { ...el, donateChange: value, donateChangeName: label }
                                  : el,
                              ),
                            });
                          }

                          if (onChangeValue)
                            onChangeValue({ donateChange: value, donateChangeName: label });
                        }}
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}

            {data?.haveFranchise ? (
              <div className='row'>
                <Col xs={12}>
                  <Form.Group className=' text-start ml-2' controlId=''>
                    <Text
                      title={'Franquicia'}
                      color={colors.ordDarkBlueText}
                      fontSize={'14px'}
                      required
                    />

                    <Select
                      noOptionsMessage={() => 'No hay datos'}
                      isDisabled={disabledFields?.franchise}
                      options={listFranchise?.results?.map(e => ({
                        value: e.fraId,
                        label: e.fraName,
                      }))}
                      className='text-secondary '
                      placeholder={'Seleccionar...'}
                      styles={ordCustomSelect}
                      value={
                        data?.franchise
                          ? { label: data?.franchiseName, value: data?.franchise }
                          : ''
                      }
                      onChange={({ value, label }) => {
                        if (!disabledFields?.franchise) {
                          if (setActiveEvent) {
                            setActiveEvent({
                              ...activeEvent,
                              listDeposits: activeEvent?.listDeposits?.map(el =>
                                el?.depoTypeId === data?.depoTypeId
                                  ? { ...el, franchise: value, franchiseName: label }
                                  : el,
                              ),
                            });
                          }

                          if (onChangeValue)
                            onChangeValue({ franchise: value, franchiseName: label });
                        }
                      }}
                    ></Select>
                  </Form.Group>
                </Col>
              </div>
            ) : (
              ''
            )}

            {data?.haveBank ? (
              <div className='row'>
                <Col xs={12}>
                  <Form.Group className=' text-start' controlId=''>
                    <Text
                      title={'Banco'}
                      color={colors.ordDarkBlueText}
                      fontSize={'14px'}
                      required
                    />
                    <Select
                      isDisabled={disabledFields?.bank}
                      noOptionsMessage={() => 'No hay datos'}
                      options={
                        listBank?.results?.map(el => ({ label: el?.description, value: el?.id })) ||
                        []
                      }
                      className='text-secondary '
                      placeholder={'Seleccionar...'}
                      styles={ordCustomSelect}
                      value={data?.bank ? { label: data?.bankName, value: data?.bank } : ''}
                      onChange={({ value, label }) => {
                        if (!disabledFields?.bank) {
                          if (setActiveEvent) {
                            setActiveEvent({
                              ...activeEvent,
                              listDeposits: activeEvent?.listDeposits?.map(el =>
                                el?.depoTypeId === data?.depoTypeId
                                  ? { ...el, bank: value, bankName: label }
                                  : el,
                              ),
                            });
                          }

                          if (onChangeValue) onChangeValue({ bank: value, bankName: label });
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
              </div>
            ) : (
              ''
            )}
          </Col>

          <Col xs={5}>
            <Form.Group className='mb-2 text-start me-3' controlId=''>
              <Text
                title={'Monto pagado'}
                color={colors.ordDarkBlueText}
                fontSize={'14px'}
                required
              />

              <NumberFormat
                disabled={disabledFields?.amount}
                allowNegative={false}
                className={`register-inputs`}
                placeholder='Escribe...'
                style={{
                  maxHeight: '32px',
                  height: '32px',
                  fontSize: '14px',
                  borderColor: '#a3bad1',
                }}
                isNumericString={true}
                value={data?.amount || ''}
                thousandSeparator={localeSeparators.groupSeparator}
                decimalSeparator={localeSeparators.decimalSeparator}
                format={(valueStr) => {
                  if (!valueStr) return ''

                  return formatNumberWithoutDecimals(valueStr, currency)
                }}
                isAllowed={({ floatValue }) => {
                  const value = floatValue ?? 0;
                  if(data.depoTypeLabel === "transfer" || data.depoTypeLabel === "card"){
                    if (selectedSurgery?.value){
                      return value <= Number(selectedSurgery?.value);
                    }
                    return value >= 0 && value <= Number(totalAmount)
                  }else{
                    return value >= 0;
                  }
                }}
                allowLeadingZeros={false}
                onValueChange={({ value }) => {
                    if (!disabledFields?.amount) {
                      const pendingAmount =
                        value && Number(totalAmount) >= Number(value)
                          ? 0
                          : Number && Number(value) > Number(totalAmount)
                            ? Number(value) - Number(totalAmount)
                            : 0;
                      const totalChange =
                        value && Number(totalAmount) > Number(value)
                          ? Number(totalAmount) - Number(value)
                          : 0;

                      let amountTotal = 0;
                      if (activeEvent) {
                        activeEvent?.listDeposits?.forEach(el => {
                          if (el?.depoTypeId === data?.depoTypeId) amountTotal += Number(value);
                          else amountTotal += Number(el?.amount);
                        });
                      }
                      if (setActiveEvent) {
                        setActiveEvent({
                          ...activeEvent,
                          amountTotal,
                          listDeposits: activeEvent?.listDeposits?.map(el =>
                            el?.depoTypeId === data?.depoTypeId
                              ? { ...el, amount: value, pendingAmount, totalChange, donation: 0 }
                              : el,
                          ),
                        });
                      }

                      if (onChangeValue) onChangeValue({ amount: value });
                    }
                }}
              />
            </Form.Group>

            {data?.haveChange ? (
              <div className='row'>
                {data?.donateChange ? (
                  <Col xs={6}>
                    <Form.Group className='text-start' controlId=''>
                      <Form.Label className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}>
                        <span> &nbsp;¿Cuánto? </span>
                        {activeEvent?.listDeposits?.find(el => el?.depoTypeId === data?.depoTypeId)?.donateChange ? (
                          <span className={`${tableStyles.ordOrangeText}`}>*</span>
                        ) : (
                          ''
                        )}
                      </Form.Label>
                      <NumberFormat
                        allowNegative={false}
                        className={`register-inputs`}
                        placeholder='Escribe...'
                        style={{
                          maxHeight: '32px',
                          height: '32px',
                          fontSize: '14px',
                          borderColor: '#a3bad1',
                        }}
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        isNumericString={true}
                        isAllowed={({ floatValue }) => (floatValue || 0) <= data?.pendingAmount}
                        prefix={'$'}
                        value={data?.donation || ''}
                        onValueChange={({ value }) => {
                          if (setActiveEvent) {
                            setActiveEvent({
                              ...activeEvent,
                              listDeposits: activeEvent?.listDeposits?.map(el =>
                                el?.depoTypeId === data?.depoTypeId
                                  ? { ...el, donation: value }
                                  : el,
                              ),
                            });
                          }

                          if (onChangeValue) onChangeValue({ donation: value });
                        }}
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  ''
                )}

                {(data?.pendingAmount > 0) && (Number(data.donation) !==  data?.pendingAmount) ? (
                  <Col xs={6}>
                    <Form.Group className='text-start me-3' controlId=''>
                      <Form.Label
                        className={`m-0 ${tableStyles.ordDarkBlueText} ${tableStyles.f14}`}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        <span> &nbsp;¿Devuelta entregada?</span>
                      </Form.Label>
                      <Select
                        noOptionsMessage={() => 'No hay datos'}
                        options={[
                          { label: 'Si', value: 1 },
                          { label: 'No', value: 0 },
                        ]}
                        className='text-secondary '
                        placeholder={'Seleccionar...'}
                        styles={ordCustomSelect}
                        value={
                          data?.turnedDeliveredName
                            ? { label: data?.turnedDeliveredName, value: data?.turnedDelivered }
                            : ''
                        }
                        onChange={({ value, label }) => {
                          if (setActiveEvent) {
                            setActiveEvent({
                              ...activeEvent,
                              listDeposits: activeEvent?.listDeposits?.map(el =>
                                el?.depoTypeId === data?.depoTypeId
                                  ? { ...el, turnedDelivered: value, turnedDeliveredName: label }
                                  : el,
                              ),
                            });
                          }

                          if (onChangeValue)
                            onChangeValue({ turnedDelivered: value, turnedDeliveredName: label });
                        }}
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  ''
                )}
              </div>
            ) : (
              ''
            )}

            {data?.haveReference ? (
              <Col xs={12}>
                <Form.Group className=' text-start me-3' controlId=''>
                  <Text
                    title={'No. referencia'}
                    color={colors.ordDarkBlueText}
                    fontSize={'14px'}
                    required
                  />

                  <input
                    disabled={disabledFields?.referenceNumber}
                    className={`register-inputs`}
                    style={{
                      maxHeight: '32px',
                      height: '32px',
                      fontSize: '14px',
                      borderColor: '#a3bad1',
                    }}
                    type='number'
                    placeholder='Escribe...'
                    value={data?.referenceNumber || ''}
                    onChange={({ target }) => {
                      if (!disabledFields?.referenceNumber) {
                        if (setActiveEvent) {
                          setActiveEvent({
                            ...activeEvent,
                            listDeposits: activeEvent?.listDeposits?.map(el =>
                              el?.depoTypeId === data?.depoTypeId
                                ? { ...el, referenceNumber: target?.value }
                                : el,
                            ),
                          });
                        }

                        if (onChangeValue) onChangeValue({ referenceNumber: target?.value });
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            ) : (
              ''
            )}
          </Col>
        </div>

        {handleRemoveDeposit ? (
          <div
            className={`${tableStyles.hoverClose}`}
            onClick={() => handleRemoveDeposit(data?.depoTypeId)}
            style={{
              position: 'absolute',
              right: -15,
              top: '45%'
            }}
          >
            <img
              src={close}
              alt='cerrar'
              style={{
                padding: '5px',
                boxShadow: '0px 0px 2px #ccc',
                background: 'white',
                borderRadius: '100%',
                cursor: 'pointer',
              }}
            />
          </div>
        ) : (
          ''
        )}
      </Col>
    </Row>
  );
};
